export const themeColors = {
    primary: '#22bca8',
    secondary: '#777777',
    error: '#F7345E',
    warning: '#d58210',
};
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_PRESENTATION = 'presentation';
export const MEDIA_TYPE_IDEA_TEMPLATE = 'template';

export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AZURE_TENANT_URL =
    'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_TENANT_ID;
export const S3_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const S3_URL_PREFIX =
    'https://' +
    process.env.REACT_APP_S3_BUCKET_NAME +
    '.s3.' +
    process.env.REACT_APP_S3_BUCKET_REGION +
    '.amazonaws.com/';
export const S3_BUCKET_FOLDER_NAME = process.env.REACT_APP_S3_FOLDER_NAME;
export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
export const S3_PLATFORM = 'dracathon';

export const IDEA_STATUS = [
    { id: 'SUB', title: 'Submitted' },
    { id: 'DRF', title: 'Drafted' },
    { id: 'REJ', title: 'Rejected' },
    { id: 'SHO', title: 'Short-Listed' },
    { id: 'PRK', title: 'Parked' },
];

export const getIdeaStatusById = (id) => {
    let IDEA = IDEA_STATUS.find((x) => x.id === id);
    return IDEA ? IDEA.title : '--';
};
