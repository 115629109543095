import React from 'react';
import { trophyImg } from 'app/util/images/images';

const PrizeItem = ({ className = '', prize = '', currency = 'INR', title = '' }) => {
    return (
        <div className={`item ${className}`}>
            <img src={trophyImg} alt="Trophy" />{' '}
            <b>
                {prize} <br />
                <i>{`${currency} /TEAM`}</i>
            </b>{' '}
            <span>{title}</span>
        </div>
    );
};

export default PrizeItem;
