// const BASE_URL = __BASE_URL;

import { S3_MEDIA_URL } from 'app/util/Constants';
import DracathonApiUrlConstants from 'app/util/DracathonApiUrlConstants';

const API_URL = { ...DracathonApiUrlConstants };
const MEDIA_URL = S3_MEDIA_URL;

const replaceParams = (url, params) => {
    const regex = /\{:[A-Za-z0-9\\-]+\}/g;
    let index = 0;
    const path = url.replace(regex, (match, offset) => params[index++]);
    return path;
};

const ApiUrlConstant = {
    getApiUrl: (key, params = []) => {
        const [apiModule, apiKey] = key.split('.');
        const url = API_URL[apiModule][apiKey];
        const path = replaceParams(url, params);
        return path;
    },

    // getApiOrigin: () => {
    // 	return BASE_URL + API_VERSION;
    // },

    getApiFullUrl: (key, params = []) => {
        const [apiModule, apiKey] = key.split('.');
        const url = ApiUrlConstant.getApiOrigin() + API_URL[apiModule][apiKey];
        const path = replaceParams(url, params);
        return path;
    },
    getMediaFullUrl: (key, params = []) => {
        const [mediaModule, mediaKey] = key.split('.');
        const url = MEDIA_URL + API_URL[mediaModule][mediaKey];
        const path = replaceParams(url, params);
        return path;
    },
};

export default ApiUrlConstant;
