import { useLoader } from 'app/api/RootLoader';
import DracathonServices from 'app/Components/Dracathon.services';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
//import jwt_decode from 'jwt-decode';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { clearAuthTokens, getApiToken } from './Persistent.service';
import useAppContext from './useAppContext';

const ValidateToken = () => {
    const [loading, setloading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [validToken, setvalidToken] = useState(false);
    const { setStore, store } = useAppContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const clearTokens = (clear) => {
        clear && clearAuthTokens();
        setloading(false);
    };

    const platformInfo = store?.platformInfo;

    const getUserData = useCallback(() => {
        if (platformInfo) {
            return;
        }
        DracathonServices.getPlatformInfo()
            .then((res) => {
                if (res?.data?.data) {
                    setStore('platformInfo', res?.data?.data);
                    setvalidToken(true);
                    setloading(false);
                    // if (!res?.data?.data?.user?.ipSigned) {
                    //     navigate('/accept-ipr');
                    // }
                } else {
                    pathname !== '/login' &&
                        enqueueSnackbar('Session Expired!', { variant: 'error' });
                    clearTokens();
                }
            })
            .catch((err) => {
                let expired = false;
                if (err?.response?.data?.error === 'invalid_token') {
                    expired = true;
                    clearTokens(true);
                } else {
                    clearTokens();
                }
                pathname !== '/login' &&
                    enqueueSnackbar(
                        expired ? 'Session expired. Please login !' : 'Something went wrong',
                        { variant: 'error' }
                    );
            });
    }, [enqueueSnackbar, setStore, pathname, platformInfo, navigate]);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    useLoader(loading, 'Loading user...');

    if (!validToken && !loading && pathname !== '/login') {
        return <Navigate to={`/login?redirect_to=${pathname}`} />;
    }

    if (pathname === '/login' && validToken) {
        return <Navigate to="/home" />;
    }

    // if (pathname !== '/accept-ipr' && platformInfo?.user && !platformInfo?.user?.ipSigned) {
    //     return <Navigate to="/accept-ipr" />;
    // }
    return <Outlet />;
};
const PrivateRoute = () => {
    let token = getApiToken();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const url = searchParams.get('url');

    if (!token && pathname === '/download' && url) {
        return <Navigate to={`/login?redirect_to=${pathname}?url=${url}`} />;
    }
    if (!token && pathname !== '/login') {
        return <Navigate to={`/login?redirect_to=${pathname}`} />;
    }

    if (!token && pathname === '/login') {
        return <Outlet />;
    }

    if (pathname === '/') {
        return <Navigate to="/home" />;
    }

    return <ValidateToken />;
};

export default PrivateRoute;
