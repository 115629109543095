import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, IconButton, styled, ThemeProvider } from '@mui/material';

import { SnackbarProvider, useSnackbar } from 'notistack';
import AppContent from './AppContent';
import MainRouter from 'app/util/Routes';
//import ApiService from 'app/api/ApiService';
import './App.scss';
import './FastSelect.scss';
import { Close } from '@mui/icons-material';
import RootLoader from 'app/api/RootLoader';
import ApiService from 'app/api/ApiService';

export const AppContext = createContext();

const toastStyle = createTheme({
    success: { fontSize: '1.2rem' },
    error: { fontSize: '1.2rem' },
    warning: { fontSize: '1.2rem', color: 'blue' },
    info: { fontSize: '1.2rem' },
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#22bca8',
            contrastText: '#fff',
            disabledBackground: '#31BAA6',
        },
        secondary: {
            main: '#777777',
        },
        default: {
            main: '#333333',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        primary: {
            color: '#31baa6',
        },
        secondary: {
            color: '#777777',
        },
        default: {
            color: '#777777',
        },
    },
});
const CustomButton = styled(IconButton)`
    svg {
        color: white;
    }
`;

const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <CustomButton size="small" onClick={() => closeSnackbar(snackbarKey)}>
            <Close fontSize="small" />
        </CustomButton>
    );
};

export const initStore = {
    loader: {
        loading: false,
        msg: '',
        path: '',
    },
    platformInfo: null,
    pageLoader: '',
};

const App = () => {
    const [store, setStore] = useState(initStore);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename="/">
                <SnackbarProvider
                    className="snackbar-main"
                    maxSnack={3}
                    action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{
                        variantSuccess: toastStyle.success,
                        variantError: toastStyle.error,
                        variantWarning: toastStyle.warning,
                        variantInfo: toastStyle.info,
                    }}
                >
                    <AppContext.Provider
                        value={{
                            store,
                            setStore,
                        }}
                    >
                        <RootLoader />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box className="drackathon-app" style={{ overflowX: 'hidden' }}>
                                <AppContent>
                                    <ApiService>
                                        <MainRouter />
                                    </ApiService>
                                </AppContent>
                            </Box>
                        </LocalizationProvider>
                    </AppContext.Provider>
                </SnackbarProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};
export default App;
