const Utils = {
    getFromLocalStorage: (key) => {
        let value = '';
        try {
            value = localStorage.getItem(key);
        } catch (error) {
            value = '';
        }
        return value;
    },
    setToLocalStorage: (key, value) => {
        try {
            localStorage.setItem(key, value);
        } catch (error) {
            throw error;
        }
    },
    changeDateToUtcFormat: (date) => {
        let convertDate = new Date(date);
        return convertDate
            .setTime(convertDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000)
            .toString();
    },
    changeMonthsToYrsMths: (month) => {
        let m = 0,
            y = 0;
        try {
            if (month !== 0) {
                m = month % 12;
                y = Math.floor(month / 12);
            }
        } catch {
            m = 0;
            y = 0;
        }
        return y + 'yrs ' + m + 'mths';
    },
    changeMonthsToYears: (month) => {
        let y = 0;
        try {
            if (month !== 0) {
                y = Math.floor(month / 12);
            }
        } catch {
            y = 0;
        }
        return y + '+ yrs ';
    },
    truncate: (str, n = 86) => {
        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    },

    getCurrency: (currency) => {
        let value = { icon: '', short: '' };
        switch (currency) {
            case 'CANADIAN DOLLAR':
                value = { icon: 'CA$', short: 'CAD' };
                break;

            default:
                break;
        }
        return value;
    },
    getTruncatedText: (text, maxLen = 15) => {
        let val =
            text.length <= maxLen
                ? text.substring(0, maxLen)
                : text.substring(0, maxLen - 3) + '...';
        return val;
    },
    getMinuteSecondsSplit: (val) => {
        const minutes = parseInt(val / 60, 10);
        const seconds = parseInt(val % 60);
        return { minutes, seconds };
    },
    getYearMonthSplit: (val, yr = 'years', mn = 'months') => {
        const years = parseInt(val / 12, 10);
        const months = parseInt(val % 12, 10);
        return `${years !== 0 ? years + ' ' + yr : ''}${years !== 0 && months !== 0 ? ', ' : ''}${
            months !== 0 ? months + ' ' + mn : ''
        }`;
    },
    isEmptyOrSpaces: (str = '') => str === null || str.match(/^ *$/) !== null,
    //  getImageURL: (str = '') => (str ? S3_URL + str : ''),
    //getResignDocURL: (str = '') => (str ? RESIGN_DOC_S3_URL + str : ''),
    getResource: (fileName, path = '') => {
        let baseUrl = '/Util/';
        let resource = path ? path + '/' + fileName : fileName;
        return baseUrl + resource;
    },
    numberWithCommas: (number) => {
        number = Math.round(number * 100) / 100;
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    numberWithAbbrivation: (number) => {
        let num = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 1,
            notation: 'compact',
            compactDisplay: 'short',
        }).format(number);
        return num;
    },
    getRandomColor: (name) => {
        name = !name ? 'default' : name.replace(/ /g, '');
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();
        return '#' + ('00000'.substring(0, 6 - c.length) + c);
    },
    handleErrors: (errors, snackbarFunction) => {
        let returnError = '';
        const handleObjError = (errobj) => {
            for (let error in errobj) {
                const errorArray = errobj[error];
                if (errorArray?.length > 0) {
                    if (!snackbarFunction) {
                        returnError = errorArray[0];
                        break;
                    }
                    errorArray?.forEach((err) => {
                        snackbarFunction(err, { variant: 'error' });
                    });
                }
            }
        };
        if (Array.isArray(errors)) {
            for (let err of errors) {
                if (returnError) {
                    break;
                }
                handleObjError(err);
            }
        } else if (typeof errors === 'object') {
            handleObjError(errors);
        } else {
            returnError = 'Error !';
        }
        return returnError;
    },
    capitalize: (string) => string.charAt(0).toUpperCase() + string.slice(1),
    isValidUrl: (urlString) => {
        if (!urlString) {
            return false;
        }
        var inputElement = document.createElement('input');
        inputElement.type = 'url';
        inputElement.value = urlString;

        if (!inputElement.checkValidity()) {
            return false;
        } else {
            return true;
        }
    },
    // genericGetUrl: (string = '') => {
    //     return Utils.isValidUrl(string) ? string : Utils.getFullBucketUrl(string);
    // },

    // getFullBucketUrl: (url) => S3_URL + '/' + url,
};

export default Utils;
