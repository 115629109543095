import { styled } from '@mui/material';
import { themeColors } from 'app/util/Constants';
import { docwImg, imagewImg, videowImg } from 'app/util/images/images';
import { createRef, useEffect, useRef, useState } from 'react';
import './FileUpload.scss';

const Main = styled('div')`
    .filedrop.ready {
        border: 2px solid ${themeColors.primary};
    }
    .filespan {
        padding-left: 30px;
        display: flex;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
        overflow: hidden;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .main-box {
        display: flex;
        align-items: center;
    }
    .flexbox {
        justify-content: space-between !important;
        .remove-media {
            margin-right: 1rem;
        }
    }
    .media-text {
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
    .upload-title {
        padding-left: 30px;
    }
`;

const typeMap = {
    ppt: {
        accept: '.ppt, .pptx',
        title: 'Upload Document',
        downloadText: 'Open Uploaded Document',
        image: docwImg,
    },
    pptIdea: {
        accept: '.ppt, .pptx',
        title: 'Upload Idea Document',
        downloadText: 'Open Uploaded Idea Document',
        image: docwImg,
    },
    pptHack: {
        accept: '.ppt, .pptx',
        title: 'Upload Hack Document',
        downloadText: 'Open Uploaded Hack Document',
        image: docwImg,
    },
    image: {
        accept: 'image/*',
        title: 'Upload Image',
        downloadText: 'Open Uploaded Image',
        image: imagewImg,
    },
    video: {
        accept: 'video/*, .mkv',
        title: 'Upload Video',
        downloadText: 'Open Uploaded Video',
        image: videowImg,
    },
};

const FileUploader = ({
    handleFileChange = () => {},
    mediaUrl = '',
    type = 'ppt',
    reset = null,
    disabled = false,
}) => {
    const [file, setFile] = useState('');
    const ref = useRef();

    const handleClick = () => ref.current.click();

    const handleChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            handleFileChange(e.target.files[0]);
        }
    };
    const clearFile = () => {
        setFile('');
        handleFileChange('');
    };

    useEffect(() => {
        if (reset !== null) {
            clearFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    let dropRef = createRef();

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        if (mediaUrl || disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFile(e.dataTransfer.files[0]);
            handleFileChange(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    useEffect(() => {
        let div = dropRef.current;

        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);
        return function cleanup() {
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
        };
    });
    return (
        <Main ref={dropRef} className="file-upload-main-div">
            <div
                onClick={() => !disabled && !file && !mediaUrl && handleClick()}
                className="upload-area"
            >
                <label htmlFor="file-presentation">
                    <input
                        id="file-presentation file-input"
                        name="ideaTemplate"
                        type="file"
                        style={{ display: 'none' }}
                        accept={typeMap[type].accept}
                        onChange={handleChange}
                        ref={ref}
                        onClick={(e) => (e.target.value = null)}
                        disabled={disabled}
                    />

                    <div className={`uploaded-item main-box flexbox`} style={{ width: 'inherit' }}>
                        {!mediaUrl && (
                            <>
                                {file.name ? (
                                    <div className="filespan">
                                        <img src={typeMap[type].image} alt="docw" />
                                        <span>{file.name}</span>
                                    </div>
                                ) : (
                                    <p className="upload-title"> {typeMap[type].title}</p>
                                )}
                            </>
                        )}
                        {mediaUrl && (
                            <div className="media-text">
                                <span>
                                    <img src={typeMap[type].image} alt="docw" />
                                </span>
                                <span className="media-url" style={{ marginLeft: '0.5rem' }}>
                                    <a href={mediaUrl} target="_blank" rel="noreferrer">
                                        {typeMap[type].downloadText}
                                    </a>
                                </span>
                            </div>
                        )}
                        {(file?.name || (mediaUrl && !disabled)) && (
                            <span className="remove-media">
                                <button
                                    onClick={() => clearFile()}
                                    type="button"
                                    style={{ cursor: 'pointer' }}
                                    className="close"
                                >
                                    x
                                </button>
                            </span>
                        )}
                    </div>
                </label>
            </div>
        </Main>
    );
};

export default FileUploader;
