import AdminMain from 'app/Components/AdminMain/AdminMain';
import AdminSettings from 'app/Components/AdminSettings/AdminSettings';
import AllTeams from 'app/Components/AllTeams/AllTeams';
import GuideLines from 'app/Components/GuideLines/GuideLines';
import HackList from 'app/Components/HackList/HackList';
import HomePage from 'app/Components/HomePage/HomePage';
import Idealist from 'app/Components/Idealist/Idealist';
import Login from 'app/Components/Login/Login';
import NotFound from './NotFound';
import Prizes from 'app/Components/Prizes/Prizes';
import Register from 'app/Components/Register/Register';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import HasPrivilege from './HasPrivilege';
import DracathonError from 'app/Components/DracathonError/DracathonError';
import ViewHack from 'app/Components/ViewHack/ViewHack';
import Results from 'app/Components/Results/Results';
import Winner from 'app/Components/Winner/Winner';
import Rules from 'app/Components/Rules/Rules';
import AdminStats from 'app/Components/AdminStats/AdminStats';
import DownloadPage from 'app/Components/DownloadPage/DownloadPage';
// import AcceptIPR from 'app/Components/AcceptIPR/AcceptIPR';
import PreviewComponent from 'app/Components/Common/PreviewComponent/PreviewComponent';

const MainRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/home/guidelines" element={<GuideLines />} />
                <Route path="/home/rules" element={<Rules />} />
                <Route path="/home/prize" element={<Prizes />} />
                <Route path="/ideation/detail" element={<Register />} />
                <Route path="/hacks" element={<HackList />} />
                <Route path="/hacks/:hackId" element={<ViewHack />} />
                <Route
                    path="/admin-main/stats"
                    element={<HasPrivilege component={<AdminMain />} />}
                />
                <Route
                    path="/admin/settings"
                    element={<HasPrivilege adminOnly={true} component={<AdminSettings />} />}
                />
                <Route
                    path="/admin/all-ideas"
                    element={<HasPrivilege component={<Idealist />} />}
                />
                <Route
                    path="/admin/all-hacks"
                    element={<HasPrivilege component={<HackList isAdmin={true} />} />}
                />
                <Route
                    path="/admin/hacks/:hackId"
                    element={<HasPrivilege component={<ViewHack isAdmin={true} />} />}
                />
                <Route path="/admin/teams" element={<HasPrivilege component={<AllTeams />} />} />
                <Route path="/admin-stats" element={<HasPrivilege component={<AdminStats />} />} />
                <Route path="/winner" element={<Results />} />
                <Route
                    path="/winner-demo"
                    element={
                        <HasPrivilege adminOnly={true} component={<Results isAdmin={true} />} />
                    }
                />
                <Route path="/results" element={<Winner />} />
                <Route path="/tribathon-error" element={<DracathonError />} />
                <Route path="/download" element={<DownloadPage />} />
                {/* <Route path="/accept-ipr" element={<AcceptIPR />} /> */}
                <Route path="/preview" element={<PreviewComponent />} />
            </Route>
            <Route path="/page-not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
    );
};

export default MainRouter;
