import { Box, Grid, styled } from '@mui/material';
import React from 'react';
import { notFoundImg } from './images/images';

const Main = styled(Box)`
    height: 100vh;
    display: flex;
    .page-not-found-grid-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        .page-not-found-img {
            width: 100%;
            object-fit: cover;
            max-height: 95vh;
        }
    }
`;
const PageNotFound = () => {
    return (
        <Main>
            <Grid container justify="center" className="page-not-found-grid-container">
                <Grid item xs={12}>
                    <img alt="page-not-found" className="page-not-found-img" src={notFoundImg} />
                </Grid>
            </Grid>
        </Main>
    );
};

export default PageNotFound;
