import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, styled } from '@mui/material';
import FileUploader from '../Common/FileUploader';
import Utils from 'app/util/Utils';
import { Close } from '@mui/icons-material';

const Main = styled(Dialog)`
    .edition-content .upload-area {
        display: flex;
    }

    .edition-content .upload-area .upload-item {
        height: 50px;
        width: 300px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-image: url('/images/folder.png');
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .edition-content .upload-area .uploaded-item {
        height: 50px;
        width: 300px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .edition-content .upload-area .uploaded-item .media-url a {
        text-decoration: none;
        color: #787878;
        margin-left: 10px;
    }

    .edition-content .input {
        width: 332px;
        height: 60px;
        margin-bottom: 15px;
        margin-right: 15px;
        border: 1px solid #e8e8e8;
        padding: 20px;
        padding-left: 30px;
        font-size: 14px;
    }

    .edition-content h3 {
        padding: 30px 0px 1px 0px;
    }

    .edition-content .submit-btn {
        font-size: 14px;
        color: #383838;
        width: 170px;
        height: 50px;
        color: black;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        margin-left: 251px;
    }
    .upload-area label {
        width: 50%;
    }
    .error {
        color: red;
        font-size: 11px;
        margin: 5px;
        margin-top: 0px;
    }
    .MuiPaper-root {
        position: relative;
        .close-button {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
        }
    }
`;

const initForm = {
    editionName: '',
    editionDescription: '',
    file: {},
};

const EditionPopup = ({ handleClose = () => {}, open = false, handleSubmit = () => {} }) => {
    const [form, setform] = useState(initForm);
    const [error, seterror] = useState({});

    const handlePost = (e) => {
        e.preventDefault();
        let valid = true;
        let errorObj = {};
        if (Utils.isEmptyOrSpaces(form.editionDescription)) {
            valid = false;
            errorObj = { editionDescription: true };
        }
        if (Utils.isEmptyOrSpaces(form.editionName)) {
            valid = false;
            errorObj = { ...errorObj, editionName: true };
        }
        if (Object.keys(form.file).length === 0) {
            valid = false;
            errorObj = { ...errorObj, file: true };
        }
        seterror(errorObj);
        if (!valid) {
            return;
        }
        handleSubmit();
    };
    const handleChange = (key, value) => setform((state) => ({ ...state, [key]: value }));
    const fileUpload = (file) => {
        console.log(file);
    };

    return (
        <Main
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <IconButton onClick={handleClose} className="close-button">
                <Close />
            </IconButton>
            <DialogTitle id="alert-dialog-title">Edition Wizard</DialogTitle>
            <DialogContent>
                <form onSubmit={handlePost} encType="multipart/form-data">
                    <div className="edition-content">
                        <input
                            className="input"
                            type="text"
                            name="editionName"
                            placeholder="Edition Name"
                            required
                            value={form.editionName}
                            onChange={(e) => handleChange('editionName', e.target.value)}
                        />
                        {error.editionName && <p className="error">Name required</p>}
                        <input
                            value={form.editionDescription}
                            className="input"
                            type="text"
                            name="editionDescription"
                            placeholder="Edition Description"
                            required
                            onChange={(e) => handleChange('editionDescription', e.target.value)}
                        />
                        {error.editionDescription && <p className="error">Description required</p>}
                        <FileUploader
                            handleFileChange={fileUpload}
                            type="ppt"
                            //   mediaUrl={isReady.ideation && ideationForm.ideaDocument}
                        />
                        {error.file && <p className="error">File required</p>}
                        <div>
                            <button
                                className="submit-btn"
                                type="submit"
                                //onclick="return validateEdition(this);"
                            >
                                Create Edition
                            </button>
                        </div>
                    </div>
                </form>
            </DialogContent>
        </Main>
    );
};

export default EditionPopup;
