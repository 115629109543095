import { Box, styled } from '@mui/material';
import { usePageLoader } from 'app/api/RootLoader';
import useAppContext from 'app/util/useAppContext';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import SelectEdition from '../Common/SelectEdition';
import DracathonServices from '../Dracathon.services';
import FileUploader from '../Common/FileUploader';
import EditionPopup from './EditionPopup';
import DemoPopup from './DemoPopup';
import { useNavigate } from 'react-router';

const Main = styled(Box)`
    margin: 5%;

    .panel-members {
        margin: 30px 0px;
        padding: 30px;
        display: flex;
        background: #f5f5f5;
        width: 100%;
        position: relative;
        top: 30px;
        flex-wrap: wrap;
    }

    .panel-members h3 {
        position: absolute;
        top: -35px;
        left: 0px;
    }

    .panel-members .emailId {
        font-size: 17px;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 5px;
        background: white;
        box-shadow: 0 0 20px #e9e9e9;
    }

    .admin-members {
        margin: 30px 0px;
        padding: 30px;
        display: flex;
        background: #f5f5f5;
        width: 100%;
        position: relative;
        top: 30px;
        flex-wrap: wrap;
    }

    .admin-members h3 {
        position: absolute;
        top: -35px;
        left: 0px;
    }

    .admin-members .emailId {
        font-size: 17px;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 5px;
        background: white;
        box-shadow: 0 0 20px #e9e9e9;
    }

    .config {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        margin-bottom: 20px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #efefef;
        width: 350px;
        height: 100px;
        float: left;
        margin-right: 30px;
    }

    .config .text {
        font-size: 20px;
        padding-right: 20px;
    }

    .config .text a {
        text-decoration: none;
        color: #383838;
    }

    .config .toggle {
        font-family: 'Playfair Display', serif;
        color: #20e090;
    }

    .description {
        color: #989898;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 34px;
        margin: 10px 0px;
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ca2222;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    .dropdown {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 65px;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: #2ab934;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(55px);
    }

    .slider:after {
        content: 'OFF';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
    }

    input:checked + .slider:after {
        content: 'ON';
    }

    .edition-content .upload-area {
        display: flex;
    }

    .edition-content .upload-area .upload-item {
        height: 50px;
        width: 300px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-image: url('/images/folder.png');
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .edition-content .upload-area .uploaded-item {
        height: 50px;
        width: 300px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .edition-content .upload-area .uploaded-item .media-url a {
        text-decoration: none;
        color: #787878;
        margin-left: 10px;
    }

    .edition-content .input {
        width: 332px;
        height: 60px;
        margin-bottom: 15px;
        margin-right: 15px;
        border: 1px solid #e8e8e8;
        padding: 20px;
        padding-left: 30px;
        font-size: 14px;
    }

    .edition-content h3 {
        padding: 30px 0px 1px 0px;
    }

    .edition-content .submit-btn {
        font-size: 14px;
        color: #383838;
        width: 170px;
        height: 50px;
        color: black;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        margin-left: 251px;
    }
    .upload-area label {
        width: 50%;
    }
    .phase-text {
        height: fit-content;
        width: 100%;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0px 0.5rem;
        padding-top: 0.5rem;
    }
    .slider {
        &.disabled {
            background-color: gray !important;
        }
    }
`;
const initSettings = {
    //registration phase
    openRegistration: false, //
    allowTeamMemberUpdate: false,
    allowTeamDetailUpdate: false,

    //ideation phase
    openIdeaSubmission: false,
    announceShortlistedIdea: false,

    //hack phase
    openHackSubmission: false,
    allowHackUpdate: false,

    //voting phase
    openVoting: false,

    //final Phase
    confirmWinnerPage: false,
    announceWinner: false,
    displayHacksToAll: false,
};
const AdminSettings = () => {
    document.title = 'Settings - Tribathon';
    const [settings, setSettings] = useState(initSettings);
    const { enqueueSnackbar } = useSnackbar();
    const { setStore } = useAppContext();
    const [loading, setloading] = useState(true);
    const [open, setopen] = useState(false);
    const [demoOpen, setdemoOpen] = useState(false);
    const navigate = useNavigate();
    const getAllsettings = useCallback(() => {
        setloading(true);
        DracathonServices.getSettingsPage()
            .then((res) => {
                if (res?.data?.data) {
                    setSettings((state) => ({
                        ...state,
                        ...res?.data?.data,
                    }));
                } else {
                    enqueueSnackbar('Error !', { variant: 'error' });
                }
                setloading(false);
            })
            .catch((err) => {
                setloading(false);
                enqueueSnackbar(err?.response?.data?.error || 'Token Expired!', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);
    useEffect(() => {
        getAllsettings();
    }, [getAllsettings]);
    const getPlatformData = useCallback(() => {
        DracathonServices.getPlatformInfo()
            .then((res) => {
                if (res?.data?.data) {
                    setStore('platformInfo', res?.data?.data);
                } else {
                    enqueueSnackbar('Error !', { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Token Expired!', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar, setStore]);

    const putSettings = (key) => {
        if (key === 'confirmWinnerPage') {
            setdemoOpen(true);
            return;
        }
        const value = !settings?.[key];
        setSettings((state) => ({ ...state, [key]: value }));
        DracathonServices.changeConfig(key, !settings?.[key] ? 1 : 0)
            .then((res) => {
                if (res?.data?.data) {
                    setSettings({ ...res?.data?.data, [key]: value });
                    getPlatformData();
                }
            })
            .catch((err) => {
                getPlatformData();
                enqueueSnackbar(err?.response?.data?.error || 'Error in updating !', {
                    variant: 'error',
                });
            });
    };
    usePageLoader(loading);

    return (
        <Main
        //th:if="${isAdmin}"
        >
            <h3>Admin Controls for managing drackathon event flow</h3>
            <p className="phase-text config">Registration Phase</p>
            <div className="config">
                <span className="text">Open Registration</span>{' '}
                <span className="toggle">
                    <label className="switch">
                        <input
                            //th:checked="${DROF}"
                            name="openRegistration"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('openRegistration')}
                            checked={settings?.openRegistration}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to register teams, add team members.
                </div>
            </div>
            <div className="config">
                <span className="text">Allow Team Member Update</span>{' '}
                <span className="toggle">
                    <label className="switch">
                        <input
                            //th:checked="${DROF}"
                            name="allowTeamMemberUpdate"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('allowTeamMemberUpdate')}
                            checked={settings?.allowTeamMemberUpdate}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to update team members.
                </div>
            </div>
            <div className="config">
                <span className="text">Allow Team Details Update</span>{' '}
                <span className="toggle">
                    <label className="switch">
                        <input
                            //th:checked="${DROF}"
                            name="allowTeamDetailUpdate"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('allowTeamDetailUpdate')}
                            checked={settings?.allowTeamDetailUpdate}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to update team details.
                </div>
            </div>
            <br />
            <p className="phase-text config">Ideation Phase</p>
            <br />
            <div className="config">
                <span className="text">Open Idea Submission</span>{' '}
                <span className="toggle">
                    <label className="switch">
                        <input
                            //  th:checked="${IDEA}"
                            name="openIdeaSubmission"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('openIdeaSubmission')}
                            checked={settings?.openIdeaSubmission}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to submit Idea
                </div>
            </div>

            <div className="config">
                <span className="text">Announce Shortlisted Ideas</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            //   th:checked="${IDERES}"
                            // th:disabled="${IDEPEN}"
                            name="announceShortlistedIdea"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('announceShortlistedIdea')}
                            checked={settings?.announceShortlistedIdea}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    After deciding and marking shortlisted teams , enabling this option will allow
                    users to participate in the hack
                </div>
            </div>
            <p className="phase-text config">Hack Phase</p>

            <div className="config">
                <span className="text">Open Hack Submission</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            // th:checked="${DHSOF}"
                            name="OpenHackSubmission"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('openHackSubmission')}
                            checked={settings?.openHackSubmission}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow team to add their Hackathon details like videos,
                    images, git repo and documents.
                </div>
            </div>

            <div className="config">
                <span className="text">Allow Hack Update</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            // th:checked="${DVOF}"
                            name="allowHackUpdate"
                            type="checkbox"
                            id="togBtn"
                            onChange={() => putSettings('allowHackUpdate')}
                            checked={settings?.allowHackUpdate}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to update hack.
                </div>
            </div>
            <p className="phase-text config">Voting Phase</p>

            <div className="config">
                <span className="text">
                    <a href="/winner-demo">Open Voting</a>
                </span>
                <span className="toggle">
                    <input
                        type="hidden"
                        id="confirmResultPage" //th:value="${CRP}"
                    />
                    <label className="switch">
                        <input
                            // th:checked="${DWAF}"
                            name="openVoting"
                            type="checkbox"
                            id="winnerBtn"
                            onChange={() => putSettings('openVoting')}
                            checked={settings?.openVoting}
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">
                    Enabling this option will allow users to vote for different hacks.
                </div>
            </div>
            <p className="phase-text config">Final Phase</p>

            <div className="config">
                <span className="text">Confirm Winner</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            onChange={() => putSettings('confirmWinnerPage')}
                            checked={settings.confirmWinnerPage}
                            name="confirmWinnerPage"
                            type="checkbox"
                            id="togBtn"
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">Enable it to confirm the winner page</div>
            </div>

            <div className="config">
                <span className="text">Announce Winner</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            disabled={!settings?.confirmWinnerPage}
                            onChange={() => putSettings('announceWinner')}
                            checked={settings.announceWinner}
                            name="announceWinner"
                            type="checkbox"
                            id="togBtn"
                        />
                        <div
                            className={`slider round ${
                                !settings?.confirmWinnerPage ? 'disabled' : ''
                            }`}
                        ></div>
                    </label>
                </span>
                <div className="description">Enable it to announce the winner</div>
            </div>

            <div className="config">
                <span className="text">Display Hacks To All</span>
                <span className="toggle">
                    <label className="switch">
                        <input
                            onChange={() => putSettings('displayHacksToAll')}
                            checked={settings.displayHacksToAll}
                            name="announceWinner"
                            type="checkbox"
                            id="togBtn"
                        />
                        <div className="slider round"></div>
                    </label>
                </span>
                <div className="description">Enable it to display all hacks</div>
            </div>

            <div className="config">
                <span className="text">Select Active Edition</span>
                <div className="switch">
                    <SelectEdition />
                </div>
                <div className="description">Enable it confirm the winner page</div>
            </div>

            <div className="panel-members">
                <h3>Panel Members</h3>
                {settings?.panelMembers?.map((item) => (
                    <div key={item} className="emailId">
                        {item}
                    </div>
                ))}
            </div>

            <div className="admin-members">
                <h3>Admin Members</h3>
                {settings?.adminMembers?.map((item) => (
                    <div key={item} className="emailId">
                        {item}
                    </div>
                ))}
            </div>

            <form action="/admin/edition" method="post" encType="multipart/form-data">
                <div className="edition-content">
                    <h3>Edition Wizard</h3>
                    <div className="upload-area"></div>
                    <input
                        className="input"
                        type="text"
                        name="editionName"
                        placeholder="Edition Name"
                        required
                    />
                    <input
                        className="input"
                        type="text"
                        name="editionDescription"
                        placeholder="Edition Description"
                        required
                    />

                    <FileUploader />

                    <div>
                        <button
                            className="submit-btn"
                            type="submit"
                            //onclick="return validateEdition(this);"
                        >
                            Create Edition
                        </button>
                    </div>
                </div>
            </form>
            <DemoPopup
                open={demoOpen}
                handleClose={() => setdemoOpen(false)}
                handleConfirm={() => navigate('/winner-demo')}
            />
            <EditionPopup open={open} handleClose={() => setopen(false)} />
        </Main>
    );
};

export default AdminSettings;
