import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import useAppContext from './useAppContext';

const HasPrivilege = ({ component = <></>, adminOnly = false }) => {
    const { store } = useAppContext();
    const adminPrivilege = useMemo(() => store?.platformInfo?.admin, [store]);
    const panelPrivilege = useMemo(() => store?.platformInfo?.panel, [store]);

    if (store?.loader?.loading || !store?.platformInfo) {
        return null;
    }

    if ((adminOnly && adminPrivilege) || (!adminOnly && (panelPrivilege || adminPrivilege))) {
        return component;
    }

    return <Navigate to="/tribathon-error" />;
};

export default HasPrivilege;
