import { styled } from '@mui/material';
import { themeColors } from 'app/util/Constants';
import React from 'react';

const Main = styled('div')`
    .indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        .svg {
            .polyline {
                fill: none;
                stroke-width: 2px;
                stroke-linecap: round;
                stroke-linejoin: round;
                &#back {
                    animation: strokechangeback 8s infinite;
                }
                &#front {
                    stroke-dasharray: 12, 36;
                    stroke-dashoffset: 48;
                    animation: dash 1s linear infinite, strokechangefront 8s infinite;
                }
            }
        }
    }
    @keyframes strokechangeback {
        0% {
            stroke: rgba(${themeColors.primary}, 0.3);
        }

        100% {
            stroke: rgba(${themeColors.primary}, 0.3);
        }
    }
    @keyframes strokechangefront {
        0% {
            stroke: ${themeColors.primary};
        }

        100% {
            stroke: ${themeColors.primary};
        }
    }
    @keyframes dash {
        62.5% {
            opacity: 0;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
`;

const FallbackLoader = () => {
    return (
        <Main className="loader">
            <div className="indicator">
                <svg width="16px" height="12px" className="svg">
                    <polyline
                        className="polyline"
                        id="back"
                        points="1 6 4 6 6 11 10 1 12 6 15 6"
                    ></polyline>
                    <polyline
                        className="polyline"
                        id="front"
                        points="1 6 4 6 6 11 10 1 12 6 15 6"
                    ></polyline>
                </svg>
            </div>
        </Main>
    );
};

export default FallbackLoader;
