import { Box, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { locationImg, categoryImg, likeImg } from 'app/util/images/images';
import { useSnackbar } from 'notistack';
import DracathonServices from '../Dracathon.services';
import SelectEdition from '../Common/SelectEdition';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';

const Main = styled(Box)`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;

    .content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        font-family: 'Playfair Display', Regular;
    }

    .content .top-area {
        display: flex;
        justify-content: space-between;
        margin: 0 140px;
        align-items: center;
    }

    .content .top-area .filters {
        display: flex;
    }

    .content .top-area .title {
        font-size: 30px;
        color: #22bca8;
    }

    .content .top-area .title span {
        color: #383838;
        font-weight: 400;
    }

    .content .top-area .message {
        font-size: 20px;
        color: #ff4646;
        font-weight: 600;
    }

    .content .top-area .message span {
        color: #383838;
        font-size: 20px;
    }

    .content .top-area .location {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 40px;
        background-image: url(${locationImg});
        background-position: left 15px center;
        background-repeat: no-repeat;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-right: 20px;
        border: 1px solid #f5f5f5;
    }

    .content .top-area .category {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 40px;
        background-image: url(${categoryImg});
        background-position: left 15px center;
        background-repeat: no-repeat;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-right: 20px;
        border: 1px solid #f5f5f5;
    }

    .content .top-area .location select {
        margin-left: 18px;
        border: none;
        color: #797979;
    }

    .content .top-area .category select {
        margin-left: 18px;
        border: none;
        color: #797979;
    }

    .content .main-area {
        display: flex;
        flex-wrap: wrap;
        margin: 50px 139px;
        /*justify-content: space-between;*/
        justify-content: flex-start;
    }

    .content .main-area .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        margin: 0 20px 60px 0;
    }

    .content .main-area .item .video {
        /* height: 250px;
  width: 350px; */
        background: #383838;
        display: flex;
        align-items: center;
        margin-right: auto;
    }

    .content .main-area .item .video video {
        height: 230px;
        width: 330px;
    }

    .content .main-area .item .video img {
        height: 230px;
        width: 330px;
    }

    .content .main-area .item .title-like {
        display: flex;
        margin-top: 20px;
        font-size: 16px;
        justify-content: space-between;
        align-items: center;
        width: 330px;
    }

    .content .main-area .item .title-like .titl {
        font-weight: bold;
        color: #22bca8;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .content .main-area .item .title-like .like {
        padding-left: 30px;
        background-image: url(${likeImg});
        background-position: left center;
        background-repeat: no-repeat;
        white-space: nowrap;
    }

    .content .main-area .item .team {
        font-size: 16px;
        margin-top: 10px;
    }

    .content .top-area .message {
        font-size: 20px;
        color: #ff4646;
        font-weight: 600;
    }

    .content .top-area .message span {
        color: #383838;
        font-size: 20px;
    }
`;
const HackList = ({ isAdmin = false }) => {
    document.title = 'Hacks - Tribathon';
    const { enqueueSnackbar } = useSnackbar();
    const [hackList, sethackList] = useState([]);
    const getAllHacksForPanel = useCallback(() => {
        DracathonServices.getAllHacksForPanel()
            .then((res) => {
                sethackList(res.data.data);
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in getting stats !', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        getAllHacksForPanel();
    }, [getAllHacksForPanel]);

    return (
        <Main //th:if="${isPanelMember || isAdmin}"
        >
            <HeaderBanner title="All Hacks" />
            <div className="content">
                <div className="top-area">
                    <div className="title">
                        All
                        <span>Hacks</span>
                    </div>
                    {!isAdmin && <div className="message">You can only vote for three hacks.</div>}
                    <div className="category">
                        Edition
                        <SelectEdition />
                    </div>
                </div>

                <div className="main-area">
                    {hackList?.map((item, idx) => (
                        <div
                            key={idx}
                            className="item" //th:each="hack : ${hacks}"
                        >
                            <div
                                className="video animated pulse"
                                //      th:if="${isPanelMember}"
                            >
                                {item?.videoUrl ? (
                                    <video preload="none" autoPlay={false} controls>
                                        <source src={item?.videoUrl} />
                                    </video>
                                ) : item?.imageUrl ? (
                                    <img alt="hackImg" src={item?.imageUrl} />
                                ) : (
                                    <video controls>
                                        <source />
                                    </video>
                                )}
                            </div>
                            <div className="title-like">
                                <div
                                    className="titl"
                                    //     th:inline="text"
                                    style={{ cursor: 'pointer' }}
                                    // {...(true && {
                                    //     onClick: () => navigate(`/admin/hacks/${idea?.id}`),
                                    // })}
                                    //   th:onclick="${isPanelMember ? 'javascript:goToHackDetail(' + hack.teamId + ')' : null}"
                                >
                                    {item?.shortDescription || '--'}
                                </div>
                                {/* <div
                                    className="like" //th:inline="text"
                                >

                                 0   Votes
                                </div> */}
                            </div>
                            <div className="team">{item?.team?.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Main>
    );
};

export default HackList;
