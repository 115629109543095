import { usePageLoader } from 'app/api/RootLoader';
import useAppContext from 'app/util/useAppContext';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DracathonServices from '../Dracathon.services';

const SelectEdition = () => {
    const [edition, setEdition] = useState({});
    const { store, setStore } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);

    useEffect(() => {
        setEdition(store?.platformInfo?.currentEdition?.editionId);
    }, [store]);

    const getUserData = useCallback(() => {
        DracathonServices.getPlatformInfo()
            .then((res) => {
                if (res?.data?.data) {
                    setStore('platformInfo', res?.data?.data);
                } else {
                    enqueueSnackbar('Error !', { variant: 'error' });
                }
                setloading(false);
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error !', { variant: 'error' });
                setloading(false);
            });
    }, [enqueueSnackbar, setStore]);

    const changeEdition = (item) => {
        setloading(true);
        setEdition(item);
        DracathonServices.changeVersion(item).then((res) => {
            if (res?.data) {
                getUserData();
                navigate('/home');
            }
        });
    };

    usePageLoader(loading);

    return (
        <select
            onChange={(e) => changeEdition(e.target.value)}
            className="dropdown linkbutton"
            name="edition"
            id="edition-filter"
            value={edition || 'editions'}
        >
            <option value={'editions'} disabled>
                Editions
            </option>
            {store?.platformInfo?.availableEditions?.map((item, idx) => (
                <option
                    value={item?.editionId}
                    key={idx}
                    className="dropdown-content"
                    // th:each="edition : ${editions}"
                    // th:value="${edition.id}"
                    // th:selected="${userEdition!=null && edition.id == userEdition.editionId}"
                    // th:text="${edition.edition}"
                >
                    {item?.edition}
                </option>
            ))}
        </select>
    );
};
export default SelectEdition;
