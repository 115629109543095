import { Box, Button, Chip, Grid, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import { getIdeaStatusById } from 'app/util/Constants';

const MainDiv = styled(Box)`
    text-align: -webkit-center;
    padding-top: 60px;
    padding-bottom: 1rem;
    max-width: 60%;
    margin: 0px auto;
    .sample-docu-wrap {
        display: flex;
        justify-content: center;
        margin: 0 0 1rem 0;
        .sample-query {
        }
        .sample-ans {
            padding-left: 0.5rem;
            color: blue;
            cursor: pointer;
            font-weight: 400;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .upload-or-browse-main-div {
        width: 22rem;
    }
    .add-hack-btn {
        width: 10rem;
        height: 2.5rem;
        text-transform: capitalize;
        color: #ffffff;
        margin-bottom: 1rem;
    }
    .ideas-listing-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .idea-card {
            text-align-last: center;
            width: 17rem;
            height: 11rem;
            padding: 0.5rem;
            margin: 0.5rem;
            transition: 0.3s;
            cursor: pointer;
            align-items: center;
            border-radius: 0.4rem;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            &:hover {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                transform: scale3d(1, 1, 1);
            }
            .idea-content {
                text-align: left;
                padding: 0rem 0.5rem;
            }
            .status-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                .status-title {
                    /* padding-right: 1rem; */
                }
                .staus-icon-SUB {
                    color: '#22bca8';
                }
                .status-icon-DRAFT {
                    /* color: blue; */
                }
            }
        }
    }
`;

const SAMPLE_PDF_URL =
    'https://litmus7-dracathon.s3.us-west-2.amazonaws.com/dracathon-submissions/2024/Tribathon-IDEA-Submission-Examples-V3.pdf';

const IdeaListingPage = (props) => {
    const {
        onAddIdeaClick,
        ideasList,
        setIdeaDetail,
        isManager,
        ideationChallengeOpen,
        setIsUpdate,
        setIdeaStatus,
    } = props;

    const handleIdeaDetailClick = (idea) => {
        // setIdeaDetail(ideasList[index]);
        setIsUpdate(true);
        let ideaForm = {
            account: idea.account.id,
            category: idea.category.code,
            ideaName: idea.ideaName,
            problem: idea.problem,
            improvement: idea.improvement,
            solution: idea.solution,
            ideaId: idea.ideaId,
            architectureDiagram: idea.architectureDiagram,
            explanationVideo: idea.explanationVideo,
        };
        let ideaStatus = {
            evaluationStatus: idea.evaluationStatus ? idea.evaluationStatus : '--',
            evaluationNote: idea.evaluationNote ? idea.evaluationNote : '--',
        };
        setIdeaDetail(ideaForm);
        setIdeaStatus(ideaStatus);
        onAddIdeaClick(ideationChallengeOpen && isManager);
    };

    const handleViewDocument = () => {
        let url = `/preview?url=${SAMPLE_PDF_URL}`;
        window.open(url, '_blank').focus();
    };

    return (
        <MainDiv className="ideation-listing-wrapper">
            {ideationChallengeOpen && (
                <div className="sample-docu-wrap">
                    <Typography className="sample-query" variant="subtitle2">
                        Need help submitting your Idea?
                    </Typography>
                    <Typography
                        className="sample-ans"
                        variant="subtitle2"
                        onClick={() => handleViewDocument()}
                    >
                        Please refer our example document
                    </Typography>
                </div>
            )}
            {isManager && ideationChallengeOpen && (
                <Button
                    className="add-hack-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setIsUpdate(false);
                        setIdeaDetail({});
                        onAddIdeaClick(true);
                    }}
                    startIcon={<AddIcon />}
                >
                    Add Idea
                </Button>
            )}
            <Box className="ideas-listing-wrap">
                {ideasList && ideasList.length > 0 ? (
                    ideasList.map((idea, index) => {
                        return (
                            <Paper
                                elevation={0}
                                onClick={() => {
                                    handleIdeaDetailClick(idea);
                                }}
                                key={idea.ideaId}
                            >
                                <Grid container className="idea-card">
                                    <Grid item xs={12} className="idea-content">
                                        <Typography
                                            variant="subtitle2"
                                            noWrap
                                            title={`Name : ${idea?.ideaName || '--'}`}
                                        >
                                            {idea?.ideaName || '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className="idea-content">
                                        <Typography
                                            variant="subtitle2"
                                            noWrap
                                            title={`Hack Category : ${
                                                idea?.category?.category || '--'
                                            }`}
                                        >
                                            {idea?.category?.category || '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className="idea-content">
                                        <Chip
                                            label={idea?.account?.name || '--'}
                                            variant="outlined"
                                            color="primary"
                                            title={`Account : ${idea?.account?.name || '--'}`}
                                            style={{ borderRadius: '0.4rem' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="idea-content status-wrap">
                                        <Typography
                                            noWrap
                                            variant="subtitle2"
                                            className="status-title"
                                            title={`Status : ${getIdeaStatusById(
                                                idea?.evaluationStatus
                                                    ? idea.evaluationStatus
                                                    : idea?.status
                                                    ? idea.status
                                                    : '--'
                                            )}`}
                                        >
                                            {getIdeaStatusById(
                                                idea?.evaluationStatus
                                                    ? idea.evaluationStatus
                                                    : idea?.status
                                                    ? idea.status
                                                    : '--'
                                            )}
                                        </Typography>
                                        {/* <WifiTetheringIcon
                                            className={`staus-icon-${idea.status}`}
                                        /> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        );
                    })
                ) : (
                    <Typography variant="subtitle2">No Ideas Submitted.</Typography>
                )}
            </Box>
        </MainDiv>
    );
};
export default IdeaListingPage;
