import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Utils from 'app/util/Utils';
import UploadOrBrowse from '../Common/UploadOrBrowse';
import { useSnackbar } from 'notistack';
import { uploadMedia } from '../S3BucketServices';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { getIdeaStatusById } from 'app/util/Constants';

const ARCHITECTURE_DOC = 'architecture';
const VIDEO_DOC = 'explanationVideo';

const initIdeaForm = {
    category: '',
    ideaName: '',
    account: '',
    problem: '',
    improvement: '',
    solution: '',
    architectureDiagram: null,
    explanationVideo: null,
};
const initError = {
    category: '',
    ideaName: '',
    account: '',
    problem: '',
    improvement: '',
    solution: '',
    architectureDiagram: '',
    explanationVideo: '',
};
const CustomContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .MuiFormControl-root {
        margin-top: 0.5rem;
    }
    .MuiFormHelperText-root {
        color: red;
    }
    .uploader-wrap {
        display: flex;
        .image-picker {
            margin: 0.5rem;
            height: 6rem;
            width: 18rem;
            background: #f5fefc 0% 0% no-repeat padding-box;
            border: 1px dashed #31baa6;
            border-radius: 4px;
            opacity: 1;
            text-align: center;
            cursor: pointer;
            .files-display-text {
                padding-top: 1rem;
            }
        }
    }
    .error-helper-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        text-align: left;
        margin-top: -14px;
        margin-right: 14px;
        margin-bottom: 0;
        margin-left: 14px;
        color: red;
    }
    .idea-content-wrap {
        margin-top: 1rem;
        .curr-status {
            padding-top: 0.5rem;
            .status {
                background-color: white;
                color: black;
                width: max-content;
                padding: 0.2rem 0.5rem;
                border-radius: 0.4rem;
            }
            .SUB {
                color: #588157;
                background-color: #5881571a;
            }
            .SHO {
                background-color: #22bca81a;
                color: #22bca8;
            }
            .REJ {
                color: #d32f2f;
                background-color: #d32f2f1a;
            }
            .PRK {
                color: #777777;
                background-color: #7777771a;
            }

            .flag-statement-wrap {
                display: flex;
                /* justify-content: center;
                        width: 60rem; */
                padding: 0.5rem 0;
                .flag-quotes {
                    font-size: 1.4rem;
                    transform: rotate(180deg);
                }
                .flag-statement {
                    font-size: 0.8rem;
                    font-style: italic;
                    font-weight: 500;
                    text-align: left;
                    padding: 0.5rem 0;
                }
            }
        }
    }
`;

const IdeaSubmissionPopup = (props) => {
    const {
        open,
        onClose,
        accountsList,
        hackCategories,
        submitIdeation,
        ideaDetail,
        isIdeaEditable,
        activeTeam,
        edition,
        ideationChallengeOpen,
        isManager,
        teamName,
        retrieveData,
        ideaStatus,
        showIdeaStatus,
    } = props;

    const [IdeaForm, setIdeaForm] = useState(initIdeaForm);
    const [error, setError] = useState(initError);
    const { enqueueSnackbar } = useSnackbar();
    const [isUploadFailed, setIsUploadFailed] = useState(false);

    const removeUploads = (type) => {
        if (type === ARCHITECTURE_DOC) {
            setIdeaForm((state) => ({
                ...state,
                architectureDiagram: null,
            }));
        } else {
            setIdeaForm((state) => ({
                ...state,
                explanationVideo: null,
            }));
        }
    };

    const handleUploads = (images, type) => {
        if (type === ARCHITECTURE_DOC) {
            setIdeaForm((state) => ({
                ...state,
                architectureDiagram: images,
            }));
        } else {
            setIdeaForm((state) => ({
                ...state,
                explanationVideo: images,
            }));
        }
    };

    const handleUploadMediaInS3Bucket = async (file) => {
        let albumName = `${edition}/${activeTeam}`;
        let data = await uploadMedia(file, albumName);
        if (!data) {
            enqueueSnackbar('Something went wrong while uploading media', {
                variant: 'error',
            });
            return null;
        } else {
            return data;
        }
    };

    const onFileSelect = (file, type) => {
        let files = [file];
        let fileSize = 0;
        if (files.length < 1) return;
        for (let i = 0; i < files.length; i++) {
            fileSize = 0;
            fileSize = files[i].size / (1024 * 1024);
            if (files[i].type === 'video/mp4') {
                if (fileSize > 400) {
                    enqueueSnackbar('Maximum File size for video is 400MB.', {
                        variant: 'warning',
                    });
                    setIsUploadFailed(true);
                    return;
                }
            } else {
                if (fileSize > 18) {
                    enqueueSnackbar('Maximum File size is 18MB.', {
                        variant: 'warning',
                    });
                    setIsUploadFailed(true);
                    return;
                }
            }
        }
        files.forEach(async (file) => {
            await handleUploadMediaInS3Bucket(file)
                .then((data) => {
                    let location = data?.data?.payload?.Location;
                    handleUploads(location, type);
                })
                .catch((error) => {
                    enqueueSnackbar('Something went wrong while uploading media', {
                        variant: 'error',
                    });
                    setIsUploadFailed(true);
                });
        });
    };

    const validate = () => {
        let isvalid = true;
        let error = { ...initError };
        if (IdeaForm.category === '') {
            error = { ...error, category: 'Hack category required' };
            isvalid = false;
        }
        if (Utils.isEmptyOrSpaces(IdeaForm.ideaName)) {
            error = { ...error, ideaName: 'Idea name required' };
            isvalid = false;
        }
        if (IdeaForm.account === '') {
            error = { ...error, account: 'Account required' };
            isvalid = false;
        }
        if (Utils.isEmptyOrSpaces(IdeaForm.problem)) {
            error = { ...error, problem: 'Problem statement required' };
            isvalid = false;
        }
        if (Utils.isEmptyOrSpaces(IdeaForm.improvement)) {
            error = { ...error, improvement: 'Suggestion statement required' };
            isvalid = false;
        }
        if (Utils.isEmptyOrSpaces(IdeaForm.solution)) {
            error = { ...error, solution: 'Solution statement required' };
            isvalid = false;
        }
        // if (Utils.isEmptyOrSpaces(IdeaForm.architectureDiagram)) {
        //     error = { ...error, architectureDiagram: 'Architecture file required' };
        //     isvalid = false;
        // }
        // if (Utils.isEmptyOrSpaces(IdeaForm.explanationVideo)) {
        //     error = { ...error, explanationVideo: 'Idea Explanation video required' };
        //     isvalid = false;
        // }
        setError(error);
        return isvalid;
    };
    const submitForm = (e) => {
        e.preventDefault();
        if (validate()) {
            submitIdeation(IdeaForm);
            setIdeaForm(initIdeaForm);
        }
    };

    useEffect(() => {
        (ideaDetail.ideaId || retrieveData) && setIdeaForm(ideaDetail);
    }, [ideaDetail, retrieveData]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="md"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{`Idea Submission - ${teamName ? teamName : '--'}`} </DialogTitle>
            <CustomContent>
                {showIdeaStatus && (
                    <div className="idea-content-wrap">
                        <Typography variant="subtitle2" className="idea-content-title">
                            Evaluation Status
                        </Typography>
                        <div className="curr-status">
                            {ideaStatus?.evaluationStatus && (
                                <Typography
                                    variant="subtitle2"
                                    className={`status ${
                                        ideaStatus?.evaluationStatus
                                            ? ideaStatus.evaluationStatus
                                            : '--'
                                    }`}
                                    title={`Status: ${getIdeaStatusById(
                                        ideaStatus?.evaluationStatus
                                            ? ideaStatus.evaluationStatus
                                            : '--'
                                    )}`}
                                >
                                    {getIdeaStatusById(
                                        ideaStatus?.evaluationStatus
                                            ? ideaStatus.evaluationStatus
                                            : '--'
                                    )}
                                </Typography>
                            )}

                            {ideaStatus?.evaluationNote && (
                                <Box className="flag-statement-wrap">
                                    <FormatQuoteIcon
                                        fontSize="medium"
                                        color={
                                            ideaStatus.evaluationStatus === 'SHO'
                                                ? 'primary'
                                                : ideaStatus.evaluationStatus === 'REJ'
                                                ? 'error'
                                                : ideaStatus.evaluationStatus === 'PRK'
                                                ? 'secondary'
                                                : 'success'
                                        }
                                        className="flag-quotes"
                                    />
                                    <Typography className="flag-statement">{`"${ideaStatus.evaluationNote}"`}</Typography>
                                </Box>
                            )}
                        </div>
                    </div>
                )}

                <FormControl className="sel-box item" sx={{ width: '100%' }}>
                    <InputLabel>Hack Type</InputLabel>
                    <Select
                        readOnly={!isIdeaEditable}
                        required
                        value={IdeaForm.category}
                        onChange={(e) =>
                            setIdeaForm((state) => ({
                                ...state,
                                category: e.target.value,
                            }))
                        }
                        input={<OutlinedInput label="Hack Type" />}
                    >
                        {hackCategories &&
                            hackCategories.length > 0 &&
                            hackCategories.map((item, idx) => (
                                <MenuItem key={idx} value={item?.code}>
                                    {item?.description}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <span className="error-helper-text">{error.category}</span>
                <TextField
                    inputProps={{ readOnly: !isIdeaEditable }}
                    helperText={error.ideaName}
                    required
                    value={IdeaForm.ideaName}
                    onChange={(e) =>
                        setIdeaForm((state) => ({
                            ...state,
                            ideaName: e.target.value,
                        }))
                    }
                    label="Idea Name"
                />
                <FormControl className="sel-box item" sx={{ width: '100%' }}>
                    <InputLabel>Idea to which Account</InputLabel>
                    <Select
                        readOnly={!isIdeaEditable}
                        // multiple
                        required
                        value={IdeaForm.account}
                        onChange={(e) =>
                            setIdeaForm((state) => ({
                                ...state,
                                account: e.target.value,
                            }))
                        }
                        input={<OutlinedInput label="Idea to which Account" />}
                    >
                        {accountsList &&
                            accountsList.length > 0 &&
                            accountsList.map((item, idx) => (
                                <MenuItem key={idx} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <span className="error-helper-text">{error.account}</span>
                <TextField
                    inputProps={{ readOnly: !isIdeaEditable }}
                    helperText={error.problem}
                    required
                    minRows={3}
                    maxRows={10}
                    multiline
                    value={IdeaForm.problem}
                    onChange={(e) =>
                        setIdeaForm((state) => ({
                            ...state,
                            problem: e.target.value,
                        }))
                    }
                    // placeholder="Problem Description"
                    label="Problem Description"
                />
                <TextField
                    inputProps={{ readOnly: !isIdeaEditable }}
                    helperText={error.improvement}
                    required
                    minRows={3}
                    maxRows={10}
                    multiline
                    value={IdeaForm.improvement}
                    onChange={(e) =>
                        setIdeaForm((state) => ({
                            ...state,
                            improvement: e.target.value,
                        }))
                    }
                    // placeholder="Suggestion to improve"
                    label="Suggestion to improve"
                />
                <TextField
                    inputProps={{ readOnly: !isIdeaEditable }}
                    helperText={error.solution}
                    required
                    minRows={3}
                    maxRows={10}
                    multiline
                    value={IdeaForm.solution}
                    onChange={(e) =>
                        setIdeaForm((state) => ({
                            ...state,
                            solution: e.target.value,
                        }))
                    }
                    // placeholder="Proposed Idea/Solution"
                    label="Proposed Idea/Solution"
                />
                <UploadOrBrowse
                    mediaUrl={IdeaForm.architectureDiagram}
                    readOnly={!ideationChallengeOpen || !isManager}
                    handleFileChange={(file) => onFileSelect(file, ARCHITECTURE_DOC)}
                    removeFile={() => removeUploads(ARCHITECTURE_DOC)}
                    type={ARCHITECTURE_DOC}
                    isUploadFailed={isUploadFailed}
                />
                <span className="error-helper-text">{error.architectureDiagram}</span>
                <UploadOrBrowse
                    mediaUrl={IdeaForm.explanationVideo}
                    readOnly={!ideationChallengeOpen || !isManager}
                    handleFileChange={(file) => onFileSelect(file, VIDEO_DOC)}
                    removeFile={() => removeUploads(VIDEO_DOC)}
                    type={VIDEO_DOC}
                    isUploadFailed={isUploadFailed}
                />
                <span className="error-helper-text">{error.explanationVideo}</span>
                <span className="error-helper-text">*Prefer .mp4 format video files.</span>
                <span className="error-helper-text">*Maximum file size is 400MB.</span>
            </CustomContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Cancel
                </Button>
                {isIdeaEditable && (
                    <Button type="submit" onClick={submitForm}>
                        Submit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
export default IdeaSubmissionPopup;
