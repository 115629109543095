import { SvgIcon } from '@mui/material';
import React from 'react';

const MicrosoftIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <g id="Microsoft_1_">
                    <g id="Microsoft">
                        <g id="Blue_x5F_Square">
                            <rect height="15" styles="fill:#51AED9;" width="15" y="17" />
                        </g>
                        <g id="Yellow_x5F_Square">
                            <rect height="15" styles="fill:#FEC327;" width="15" x="17" y="17" />
                        </g>
                        <g id="Green_x5F_Square">
                            <rect height="15" styles="fill:#34B67A;" width="15" x="17" />
                        </g>
                        <g id="Orange_x5F_Square">
                            <rect height="15" styles="fill:#F15723;" width="15" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default MicrosoftIcon;
