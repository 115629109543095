import React, { useCallback, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import DracathonServices from '../Dracathon.services';
import { useSnackbar } from 'notistack';
import TeamBox from './TeamBox';
import { usePageLoader } from 'app/api/RootLoader';
import { themeColors } from 'app/util/Constants';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';
import AddmemberPopup from '../Common/AddmemberPopup';

const Main = styled(Box)`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;

    .content {
        margin-top: 60px;
        margin-bottom: 150px;
    }

    .content .team-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 80%;
        margin: 50px auto;
    }

    .content .team-container .team-card {
        border: 1px solid #ffffff;
        padding: 1rem;
        margin: 0 1rem 1rem 0;
        width: 250px;
        min-width: 250px;
        box-shadow: 0px 0px 20px #e0e0e0;
        position: relative;
        height: 22.5rem;
        display: flex;
        flex-direction: column;
    }

    .content .team-container .team-card .teamname-wrap {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #d9d9d9;
        align-items: center;
        padding-bottom: 1rem;
        .team-name {
            font-size: 17px;
            font-weight: bolder;
            text-transform: capitalize;
        }
    }

    .content .team-container .team-card .remove-team {
        margin-left: auto;
        cursor: pointer;
    }

    .content .team-container .team-card .location {
        display: table;
        margin-top: 5px;
        justify-content: center;
        color: #5cbca9;
        font-weight: BOLDER;
        font-size: 9px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 3px;
        padding: 5px;
        flex-shrink: 1;
    }
    .content .team-container .team-card .members {
        margin-top: inherit;
    }

    .content .team-container .team-card .members .member {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .content .team-container .team-card .members .member .member-name {
        padding: 5px;
        color: #434343;
        margin-right: 5px;
        border-radius: 0.4rem;
        width: 100%;
        border: 1px solid #f0f0f0;
        margin: 2px 0;
        width: 80%;
        display: flex;
        justify-content: space-between;
        .user-account-wrap {
            max-width: 10rem;
        }
    }

    .content .team-container .team-card .members .member .remove-member {
        cursor: pointer;
        padding: 5px;
    }

    .content .team-container .team-card .add-members {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        bottom: 7px;
        width: 250px;
    }

    .content .team-container .team-card .input {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        padding: 7px;
        color: #434343;
        font-weight: 500;
        margin-right: 5px;
        border-radius: 3px;
        width: 100%;
        text-align: center;
        font-weight: bold;
        margin: 2px 0;
        float: left;
    }

    .content .team-container .team-card .add-members .btn-add-member {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }

    .content .team-container .team-card .add-members img {
        display: block;
        margin: auto;
        width: 25px;
        height: 25px;
        padding: 5px;
    }
    .hack-category {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 0.5rem;
        flex: 1;
        align-items: end;
        padding-bottom: 1rem;
        p {
            font-size: 11px;
        }
        .category {
            margin: 0px auto;
            font-weight: 600;
            background-color: ${themeColors.primary};
            color: white;
            margin-bottom: 5px;
        }
    }
`;

const AllTeams = () => {
    document.title = 'Teams - Tribathon';
    const [teamList, setTeamList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setloading] = useState(true);
    const [accountsList, setAccountsList] = useState([]);
    const [openform, setopenform] = useState(false);
    const [activeTeam, setActiveTeam] = useState('');

    const getAccountsList = useCallback(async () => {
        try {
            let [accounts] = await Promise.all([DracathonServices.getAccountsList()]);
            setAccountsList(accounts?.data?.data);
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Error in Loading accounts list !', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);

    const loadData = useCallback(async () => {
        try {
            let [teams] = await Promise.all([DracathonServices.getAllTeamForPanel()]);
            if (teams?.data?.data) {
                setTeamList(teams?.data?.data);
            }
            setloading(false);
        } catch (err) {
            setloading(false);
            enqueueSnackbar(err?.response?.data?.error || 'Error in API call', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);
    useEffect(() => {
        loadData();
        getAccountsList();
    }, [loadData]);

    usePageLoader(loading);

    const removeMember = (teamId, memberId) => {
        const newList = [];
        teamList?.forEach((team) => {
            if (team?.id === teamId) {
                team.members = team.members?.filter((i) => i?.id !== memberId);
            }
            newList.push(team);
        });
        setTeamList(newList);

        DracathonServices.removeMemberByPanel(teamId, memberId)
            .then((res) => {
                if (res?.data) {
                    loadData();
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in deleting member', {
                    variant: 'error',
                });
            });
    };

    const putMember = (memberForm) => {
        setopenform(false);
        DracathonServices.addMember(activeTeam, {
            email: memberForm.email,
            name: memberForm.name,
            account: memberForm.account,
        })
            .then((res) => {
                enqueueSnackbar('Member Added', { variant: 'success' });
                loadData();
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message || 'Error in adding member', {
                    variant: 'error',
                });
            });
    };

    return (
        <Main>
            <HeaderBanner title="All Teams" />

            <div className="content">
                <div className="team-container" style={{ maxWidth: '80%' }}>
                    {teamList?.map((team) => (
                        <TeamBox
                            team={team}
                            key={team.id}
                            removeMember={removeMember}
                            addMember={(id) => {
                                setActiveTeam(id);
                                setopenform(true);
                            }}
                        />
                    ))}
                </div>
            </div>
            <AddmemberPopup
                addMember={putMember}
                open={openform}
                accountsList={accountsList}
                handleClose={() => setopenform(false)}
            />
        </Main>
    );
};

export default AllTeams;
