import ApiUrlConstant from 'app/api/ApiUrlConstant';
import axios from 'axios';
import rootAxios, { customAxios } from '../api/RootAxios';

const DracathonServices = {
    registerTeam: (payload) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.registerTeam');
        return rootAxios.post(url, payload);
    },
    addMember: (id, payload) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.addMember', [id]);
        return rootAxios.put(url, payload);
    },
    removeMember: (teamid, id) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.removeMember', [teamid, id]);
        return rootAxios.delete(url);
    },
    getMyTeam: (id) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getMyTeam', [id]);
        return rootAxios.get(url);
    },
    googleLogin: (credentials) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.googleLogin');
        var formData = new FormData();
        formData.append('idtoken', credentials.idtoken);
        credentials.refreshtoken && formData.append('refresh_token', credentials.refreshtoken);
        credentials.granttype && formData.append('grant_type', credentials.granttype);
        const config = {
            headers: {
                Authorization: '*',
                'Access-Control-Allow-Origin': '*',
            },
            baseUrl: '/',
        };

        return rootAxios.post(url, formData, config);
    },
    getPlatformInfo: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getPlatformInfo');
        // return Promise.resolve({
        //     data: {
        //         status: 'OK',
        //         code: '200',
        //         data: {
        //             currentEdition: {
        //                 editionId: 7,
        //                 edition: '2023',
        //                 description: '3rd Edition',
        //                 newEdition: true,
        //                 templatePath:
        //                     'https://litmus7-dracathon.s3.us-west-2.amazonaws.com/dracathon-submissions/2023/Dracathon-v3.0-IDEASubmission-template-R2.pptx',
        //             },
        //             activeEdition: {
        //                 editionId: 8,
        //                 edition: '2024',
        //                 description: '4th Edition',
        //                 newEdition: true,
        //                 templatePath:
        //                     'https://litmus7-dracathon.s3.us-west-2.amazonaws.com/dracathon-submissions/2024/Dracathon-4.0-IDEA-Submission-Template-2024.pptx',
        //             },
        //             availableEditions: [
        //                 {
        //                     editionId: 7,
        //                     edition: '2023',
        //                     description: '3rd Edition',
        //                     newEdition: true,
        //                     templatePath:
        //                         'https://litmus7-dracathon.s3.us-west-2.amazonaws.com/dracathon-submissions/2023/Dracathon-v3.0-IDEASubmission-template-R2.pptx',
        //                 },
        //                 {
        //                     editionId: 8,
        //                     edition: '2024',
        //                     description: '4th Edition',
        //                     newEdition: true,
        //                     templatePath:
        //                         'https://litmus7-dracathon.s3.us-west-2.amazonaws.com/dracathon-submissions/2024/Dracathon-4.0-IDEA-Submission-Template-2024.pptx',
        //                 },
        //             ],
        //             team: {
        //                 id: 60,
        //                 name: 'Team Test',
        //                 motto: 'test motto 123',
        //                 hackCategories: [
        //                     {
        //                         category: 'DRA -1$ Ideas',
        //                         code: 'DRA',
        //                         description: 'DRA -1$ Ideas',
        //                     },
        //                     {
        //                         category: 'Ascend Retail – Retail in the near future',
        //                         code: 'ART',
        //                         description: 'Ascend Retail – Retail in the near future',
        //                     },
        //                     {
        //                         category: 'Disrupt Retail – Retail in 2030',
        //                         code: 'DRT',
        //                         description: 'Disrupt Retail – Retail in 2030',
        //                     },
        //                 ],
        //                 members: [
        //                     {
        //                         id: 195,
        //                         email: 'jithin@litmus7.com',
        //                         username: null,
        //                         name: 'Jithin Xavier',
        //                         avatar: null,
        //                         manager: true,
        //                     },
        //                 ],
        //                 idea: null,
        //                 hack: null,
        //             },
        //             panel: true,
        //             admin: true,
        //             user: {
        //                 id: 17,
        //                 email: 'jithin@litmus7.com',
        //                 username: 'jithin@litmus7.com',
        //                 name: 'Jithin Xavier',
        //                 avatar: 'https://lh3.googleusercontent.com/a/ACg8ocJElj0S8WvbUA5HbrR_bc0zsXz40YuSp-MHr7ZcKsh-jG4=s96-c',
        //                 ipSigned: true, //new data
        //             },
        //             settings: {
        //                 openRegistration: false,
        //                 allowTeamDetailUpdate: false,
        //                 allowTeamMemberUpdate: false,
        //                 openIdeaSubmission: false,
        //                 announceShortlistedIdea: false,
        //                 openHackSubmission: false,
        //                 allowHackUpdate: false,
        //                 openVoting: false,
        //                 confirmWinnerPage: false,
        //                 announceWinner: true,
        //                 displayHacksToAll: true,
        //             },
        //             registered: true,
        //             ideaSubmitted: true,
        //             ideaShortlisted: true,
        //             hackSubmitted: false,
        //             teamManager: true,
        //         },
        //         error: null,
        //     },
        // });
        return rootAxios.get(url);
    },
    logout: (accessToken) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.logout');
        return axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } });
    },
    changeConfig: (name, value) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.changeConfig', [name, value]);
        return rootAxios.put(url);
    },
    changeVersion: (version) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.changeVersion', [version]);
        return rootAxios.put(url);
    },
    getSettingsPage: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getSettingsPage');
        return rootAxios.get(url);
    },
    getAllTeamForPanel: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getAllTeamForPanel');
        return rootAxios.get(url);
    },
    getAllIdeasForPanel: (category, account) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getAllIdeasForPanel', [
            category,
            account,
            100,
            0,
        ]);
        return rootAxios.get(url);
    },
    submitIdea: (id, payload) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.submitIdea', [id]);
        return rootAxios.post(url, payload);
    },
    updateIdea: (teamId, payload, ideaId) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.updateIdea', [teamId, ideaId]);
        return rootAxios.put(url, payload);
    },
    submitHack: (request, idea) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.submitHack', [idea]);
        return rootAxios.post(url, request);
    },
    uploadFile: (id, file) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.uploadFile', [id]);
        return rootAxios.post(url, file, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
    },
    removeMemberByPanel: (teamId, memberId) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.removeMemberByPanel', [teamId, memberId]);
        return rootAxios.delete(url);
    },
    getStats: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getStats');
        return rootAxios.get(url);
    },
    getAllHacksForPanel: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getAllHacksForPanel');
        return rootAxios.get(url);
    },
    getHackTypes: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getHackTypes');
        return rootAxios.get(url);
    },
    getAccountsList: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getAccountsList');
        return rootAxios.get(url);
    },
    getGuidelines: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getGuidelines');
        return rootAxios.get(url);
    },
    getPrizes: () => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getPrizes');
        return rootAxios.get(url);
    },
    changeShortList: (teamId, type, ideaId) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.changeShortList', [teamId, ideaId, type]);
        return rootAxios.put(url);
    },
    getWinners: (type) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.getWinners', [type]);
        return rootAxios.get(url);
    },
    viewHack: (id) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.viewHack', [id]);
        return rootAxios.get(url);
    },
    getComment: (id) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.commentUrl', [id]);
        return rootAxios.get(url);
    },
    postComment: (id, payload) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.commentUrl', [id]);
        return rootAxios.post(url, payload);
    },
    updateTeam: (id, payload) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.updateTeam', [id]);
        return rootAxios.put(url, payload);
    },
    revokeSubmission: (id) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.revokeSubmission', [id]);
        return rootAxios.put(url);
    },
    downLoadfile: (url) => {
        return customAxios({
            responseType: 'blob',
        }).get(url);
    },
    viewPDffile: (url) => {
        return customAxios({
            responseType: 'arraybuffer',
        }).get(url);
    },
    evaluateIdea: (teamId, ideaId, request) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.evaluateIdea', [teamId, ideaId]);
        return rootAxios.put(url, request);
    },
    revokeEvaluation: (teamId, ideaId) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.revokeEvaluation', [teamId, ideaId]);
        return rootAxios.put(url);
    },
    viewIdeaForPanel: (ideaId) => {
        const url = ApiUrlConstant.getApiUrl('dracathon.viewIdeaForPanel', [ideaId]);
        return rootAxios.get(url);
    },
};

export default DracathonServices;
