import { Typography } from '@mui/material';
import { plusImg, uncheckImg } from 'app/util/images/images';
import useAppContext from 'app/util/useAppContext';
import React from 'react';

const TeamBox = ({ team, removeMember = () => {}, addMember = () => {} }) => {
    const { store } = useAppContext();

    const handleAddMember = (e) => {
        e.preventDefault();
        addMember(team?.id);
    };

    const isAdmin = store?.platformInfo?.admin;

    return (
        <div key={team?.id} className="team-card">
            <div className="teamname-wrap">
                <Typography
                    className="team-name"
                    variant="subtitle2"
                    noWrap
                    title={`Team Name: ${team?.name ? team.name : '--'}`}
                >
                    {team?.name ? team.name : '--'}
                </Typography>
                {isAdmin && false && (
                    <div className="remove-team" title="Delete Team">
                        <img src={uncheckImg} alt="uncheck" />
                    </div>
                )}
            </div>
            <div className="members">
                {team?.members?.map((member) => (
                    <div key={member?.id} className="member">
                        <div
                            className="member-name"
                            style={{
                                backgroundColor: member.manager ? 'lavender' : 'none',
                            }}
                        >
                            <div className="user-account-wrap">
                                <Typography
                                    noWrap
                                    variant="subtitle2"
                                    title={`${member.manager ? 'Manager' : 'Member'}: ${
                                        member?.name ? member.name : '--'
                                    }`}
                                >
                                    {member?.name ? member.name : '--'}
                                </Typography>
                                <Typography
                                    noWrap
                                    variant="caption"
                                    title={`Account: ${
                                        member?.account?.name ? member.account.name : '--'
                                    }`}
                                >
                                    {member?.account?.name ? member.account.name : '--'}
                                </Typography>
                            </div>
                            {isAdmin && (
                                <img
                                    src={uncheckImg}
                                    alt="uncheck"
                                    onClick={() => removeMember(team?.id, member?.id)}
                                    style={{
                                        visibility: member.manager ? 'hidden' : 'block',
                                    }}
                                    title="Remove Member"
                                    className="remove-member"
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {team?.members?.length < 5 && isAdmin && (
                <form className="add-members" onSubmit={handleAddMember} title="Add New Member">
                    <button className="btn-add-member" value="Submit" style={{ cursor: 'pointer' }}>
                        <img src={plusImg} alt="plus" />
                    </button>
                </form>
            )}
        </div>
    );
};

export default TeamBox;
