import DracathonServices from 'app/Components/Dracathon.services';
import {
    clearAuthTokens,
    getApiToken,
    getIdToken,
    getRefreshToken,
    setToken,
} from 'app/util/Persistent.service';
import useAppContext from 'app/util/useAppContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiUrlConstant from './ApiUrlConstant';
import rootAxios from './RootAxios';
const LOGOUT_URL = ApiUrlConstant.getApiUrl('dracathon.logout');
let requestIntercepts;
let responseIntercepts;
const LOGIN_EXPIRED = 401;
let isRefreshing = false;
let subscribers = [];

function ApiService(props) {
    const [isServiceConfigured, setServiceConfigured] = useState(false);
    const navigate = useNavigate();
    const { setStore, store } = useAppContext();
    // const dispatch = useDispatch();
    const isNonAuthenticateCall = (config) => {
        return config.nonAuth && config.nonAuth === true;
    };

    const isLoginCall = (data) => {
        return data && data.has && data.has('idtoken');
    };

    const isRefreshCall = (data) => {
        return (
            data && data.has && data.has('grant_type') && data.get('grant_type') === 'refresh_token'
        );
    };
    const isLogoutCall = (url) => {
        return url === LOGOUT_URL;
    };
    const subscribeTokenRefresh = (cb) => {
        subscribers.push(cb);
    };
    const goToLoginPage = () => {
        isRefreshing = false;
        navigate('/login');
        clearAuthTokens();
        subscribers = [];
    };
    const getRefreshObject = () => {
        const idtoken = getIdToken();
        const refreshtoken = getRefreshToken();
        if (!idtoken || !refreshtoken) {
            return null;
        }
        return {
            idtoken,
            refreshtoken,
            granttype: 'refresh_token',
        };
    };
    const updateAuthInfo = (auth, idToken) => {
        setToken(auth?.data?.access_token, auth?.data?.refresh_token, idToken);
    };

    const apiRequestStartInterceptor = (config) => {
        const accessToken = getApiToken();
        // dispatch(addApiStack());
        if (
            !isNonAuthenticateCall(config) &&
            !isLoginCall(config.data) &&
            !isRefreshCall(config.data) &&
            !isLogoutCall(config.url)
        ) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return config;
    };

    const apiRequestEndInterceptor = (error) => {
        //dispatch(subtractApiStack());
        return Promise.reject(error);
    };

    const apiResponseEndInterceptor = (error) => {
        const {
            config,
            response: { status },
        } = error;
        const originalRequest = config;

        const onRrefreshed = (token) => {
            subscribers.forEach((cb) => cb(token));
            subscribers = [];
        };

        if (status === LOGIN_EXPIRED) {
            if (!isRefreshing) {
                isRefreshing = true;
                let refreshRequestObj;
                try {
                    refreshRequestObj = getRefreshObject();
                } catch (e) {
                    goToLoginPage();
                    return Promise.reject(error);
                }
                if (refreshRequestObj) {
                    DracathonServices.googleLogin(refreshRequestObj)
                        .then((response) => {
                            if (response) {
                                isRefreshing = false;
                                updateAuthInfo(response, refreshRequestObj.idtoken);
                                onRrefreshed(response?.data?.access_token);
                            } else {
                                goToLoginPage();
                                setStore('tokenError', true);
                                return Promise.reject(error);
                            }
                        })
                        .catch((err) => {
                            goToLoginPage();
                            setStore('tokenError', true);
                            return Promise.reject(error);
                        });
                } else {
                    goToLoginPage();
                    return Promise.reject(error);
                }
            }

            if (isRefreshCall(config.data)) {
                goToLoginPage();
                return Promise.reject(error);
            }

            const retryOrigReq = new Promise((resolve, reject) => {
                subscribeTokenRefresh((token) => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    resolve(rootAxios(originalRequest));
                });
            });
            return retryOrigReq;
        } else {
            const errObj = error.response && error.response.data ? error.response.data : error;
            return Promise.reject(errObj);
        }
    };

    const apiResponseSuccessInterceptor = (response) => response;

    const setRequestInterceptor = (startInterceptor, endInterceptor) => {
        rootAxios.interceptors.request.use(
            (config) => {
                return startInterceptor(config);
            },
            (error) => {
                return endInterceptor(error);
            }
        );
    };

    const setResponseInterceptor = (startInterceptor, endInterceptor) => {
        rootAxios.interceptors.response.use(
            (response) => {
                return startInterceptor(response);
            },
            (error) => {
                return endInterceptor(error);
            }
        );
    };

    const init = () => {
        rootAxios.interceptors.request.use(
            function (config) {
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        rootAxios.interceptors.response.use(
            (response) => response,
            (error) => Promise.reject(error)
        );

        setRequestInterceptor(apiRequestStartInterceptor, apiRequestEndInterceptor);
        setResponseInterceptor(apiResponseSuccessInterceptor, apiResponseEndInterceptor);
    };

    const uninstallInterceptors = () => {
        rootAxios.interceptors.request.eject(requestIntercepts);
        rootAxios.interceptors.response.eject(responseIntercepts);
    };
    useEffect(() => {
        init();
        setServiceConfigured(true);
        return uninstallInterceptors;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isServiceConfigured ? props.children : null;
}

export default ApiService;
