import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { ZipFolder, docwImg, imagewImg, videowImg } from 'app/util/images/images';
import { createRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import './UploadOrBrowse.scss';

const imageacceptTypes = ['image/png', 'image/jpeg'];
const pptAcceptTypes = ['.ppt', '.pptx'];

const typeMap = {
    ppt: {
        accept: '.ppt, .pptx',
        title: 'Document',
        downloadText: 'Open Uploaded Document',
        image: docwImg,
        acceptTypes: pptAcceptTypes,
    },
    pptIdea: {
        accept: '.ppt, .pptx',
        title: 'Idea Document',
        downloadText: 'Open Idea Document',
        image: docwImg,
        acceptTypes: pptAcceptTypes,
    },
    pptHack: {
        accept: '.ppt, .pptx',
        title: 'Hack Document',
        downloadText: 'Open Hack Document',
        image: docwImg,
        acceptTypes: pptAcceptTypes,
    },
    image: {
        accept: 'image/*',
        title: 'Image',
        downloadText: 'Open Image',
        image: imagewImg,
        acceptTypes: imageacceptTypes,
    },
    video: {
        accept: 'video/*, .mkv',
        title: 'Video',
        downloadText: 'Open Video',
        image: videowImg,
        acceptTypes: ['video/*', '.mkv'],
    },
    iprDownload: {
        accept: '.pdf',
        title: 'IPR Document',
        downloadText: 'Download IPR Document template',
        image: docwImg,
        acceptTypes: ['.pdf'],
    },
    ipr: {
        accept: '.pdf',
        title: 'IPR Document',
        downloadText: 'Download IPR Document',
        image: docwImg,
        acceptTypes: ['.pdf'],
    },
    architecture: {
        accept: '',
        title: 'Architecture',
        downloadText: 'Open Architecture',
        image: docwImg,
        acceptTypes: [],
    },
    explanationVideo: {
        accept: 'video/*, .mkv',
        title: 'Explanation Video',
        downloadText: 'Open Explanation Video',
        image: videowImg,
        acceptTypes: ['video/*', '.mkv'],
    },
    sampleIdeation: {
        accept: '.pdf',
        title: 'Sample Ideation Document',
        downloadText: 'Open Sample Ideation Document',
        image: docwImg,
        acceptTypes: ['.pdf'],
    },
    folder: {
        accept: '.zip',
        title: 'Project Repository',
        downloadText: 'Download Project Repository',
        image: ZipFolder,
        acceptTypes: [
            'zip',
            'application/octet-stream',
            'application/zip',
            'application/x-zip',
            'application/x-zip-compressed',
        ],
    },
};
const UploadOrBrowse = ({
    handleFileChange = () => {},
    removeFile = () => {},
    type = 'image',
    mediaUrl = '',
    readOnly = false,
    isUploadFailed = false,
}) => {
    const [file, setFile] = useState('');
    const ref = useRef();

    const handleClick = () => !readOnly && ref.current.click();

    const handleChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            handleFileChange(e.target.files[0]);
        }
    };
    const clearFile = () => {
        removeFile();
        setFile('');
    };
    const [drag, setDrag] = useState(false);
    let dropRef = createRef();
    let dragCounter = 0;

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter--;
        if (dragCounter === 0) setDrag(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false);
        if (
            !readOnly &&
            !mediaUrl &&
            e.dataTransfer.files &&
            e.dataTransfer.files.length > 0 &&
            typeMap[type]?.acceptTypes.includes(e?.dataTransfer?.files[0]?.type)
        ) {
            setFile(e.dataTransfer.files[0]);

            handleFileChange(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
            dragCounter = 0;
        }
    };

    const handleDocumentPreview = () => {
        let url = `/preview?url=${mediaUrl}`;
        window.open(url, '_blank').focus();
    };

    useEffect(() => {
        let div = dropRef.current;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);
        return function cleanup() {
            div.removeEventListener('dragenter', handleDragIn);
            div.removeEventListener('dragleave', handleDragOut);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
        };
    });

    useEffect(() => {
        isUploadFailed && setFile('');
    }, [isUploadFailed]);

    // useEffect(() => {
    //     if (type === 'folder' && ref.current !== null) {
    //         ref.current.setAttribute('directory', '');
    //         ref.current.setAttribute('webkitdirectory', '');
    //     }
    // }, [type, ref]);

    return (
        <div className="upload-or-browse-main-div">
            <input
                type="file"
                className="file-input"
                accept={typeMap[type].accept}
                onChange={handleChange}
                ref={ref}
                readOnly={readOnly}
                onClick={(e) => (e.target.value = null)}
            />
            <div
                ref={dropRef}
                className={drag ? 'filedrop drag' : file?.name ? 'filedrop ready' : 'filedrop'}
            >
                {mediaUrl ? (
                    <div className="file media">
                        <img alt="icon" src={typeMap[type].image} className="file-icon" />
                        {/* <a href={mediaUrl} target="_blank" className="document-link">
                            {typeMap[type].downloadText}
                        </a> */}
                        <Typography
                            onClick={() => handleDocumentPreview()}
                            className="document-link"
                        >
                            {typeMap[type].downloadText}
                        </Typography>
                        {!readOnly && (
                            <div className="flex1">
                                <IconButton onClick={() => clearFile()} size="small">
                                    <Close color="primary" fontSize="small" />
                                </IconButton>
                            </div>
                        )}
                    </div>
                ) : file?.name && !drag ? (
                    <div className="file media">
                        <img alt="icon" src={typeMap[type].image} className="file-icon" />

                        {/* <Link className="loading" to={`#`}>
                            Uploading file
                        </Link> */}
                        <div class="loader"></div>
                        <div className="flex1">
                            <IconButton onClick={() => clearFile()} size="small">
                                <Close color="primary" fontSize="small" />
                            </IconButton>
                        </div>
                    </div>
                ) : (
                    <div className="text-box">
                        <img alt="icon" src={typeMap[type].image} className="file-icon" />
                        <p className="upload">
                            <span onClick={handleClick}>Browse</span> {typeMap[type].title}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadOrBrowse;
