import { Badge, Box, Button, TextField, Typography, styled } from '@mui/material';
import { usePageLoader } from 'app/api/RootLoader';
import { getIdeaStatusById, themeColors } from 'app/util/Constants';
import {
    docwImg,
    imagewImg,
    likegImg,
    likeImg,
    sampleUserImg,
    videowImg,
} from 'app/util/images/images';
import useAppContext from 'app/util/useAppContext';
import Utils from 'app/util/Utils';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DracathonServices from '../Dracathon.services';
import './ViewHack.scss';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ConfirmPopup from '../Common/ConfirmPopup';
import useFetch from 'app/util/useFetch';

const Main = styled(Box)`
    .template {
        margin: auto;
        font-size: 15px;
        color: #24bca9;
        font-weight: 900;
    }
    .presentation {
        margin: auto;
        font-size: 15px;
        color: #24bca9;
        font-weight: 900;
    }
    height: 100%;
    width: 100%;
    .content .source-container {
        .no-preview-container {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            .no-preview-msg {
                font-size: 0.8rem;
                font-weight: 400;
            }
            .download-btn {
                font-size: 0.8rem;
                font-weight: 500;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }
    .content .source-container .side-tiles .tile {
        height: 60px;
        width: 60px;
        background: #f9f9f9;
        margin: 8px 0;
        box-shadow: 0 0 20px #e4e4e429;
        background-image: url(${videowImg});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 22px;
        cursor: pointer;
        &.selected {
            background-color: ${themeColors.primary};
        }
    }
    .content .source-container .side-tiles .tile.doc {
        background-image: url(${docwImg});
        background-size: 16px 19px;
    }
    .content .source-container .side-tiles .tile.img {
        background-image: url(${imagewImg});
        background-size: 23px 17px;
    }
    .content .title-cntr .likes .like-btn {
        height: 35px;
        width: 30px;
        background-image: url(${likeImg});
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .content .title-cntr .likes .unlike-btn {
        height: 35px;
        width: 30px;
        background-image: url(${likegImg});
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .content {
        .team-details-wrap {
            margin-top: 2rem;
            .team-title {
                .title {
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: black;
                }
            }
            .team-title-name {
                margin-top: 1rem;
            }
            .members-listing-wrap {
                display: flex;
                margin-top: 45px;
                align-items: center;
                flex-wrap: wrap;
                .MuiBadge-badge {
                    padding: 0px;
                    border-radius: 50%;
                    top: 0.1rem;
                    right: 0.3rem;
                }
                .member {
                    height: 200px;
                    width: 200px;
                    background: #fcfcfc;
                    margin: 0 15px 15px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    a {
                        margin-left: 80%;
                        text-decoration: none;
                        color: #db1717;
                        font-weight: 900;
                    }
                    .img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: #dfd9d9;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            background: #dfd9d9;
                        }
                    }
                    .name {
                        font-weight: 500;
                        margin: 10px 0 2px 0;
                    }
                    .email {
                        font-weight: 300;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .member-email {
                            max-width: 11rem;
                        }
                        .chip {
                            background-color: ${themeColors.primary};
                            color: white;
                            margin-top: 5px;
                            /* height: 15px; */
                            min-width: 5rem;
                            max-width: 11rem;
                            font-size: 0.8rem;
                            justify-content: space-evenly;
                            padding: 2px 3px;
                            border-radius: 4px;
                            font-weight: 400;
                            &.team-member {
                                /* display: flex;
                align-items: center; */
                                text-align: center;
                                border: 1px solid ${themeColors.primary};
                                background-color: white;
                                color: ${themeColors.primary};
                                .verified-icon {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
            .idea-content-wrap {
                margin-top: 1rem;
                .idea-content-title {
                }
                .idea-content-value {
                    margin-top: 0.75rem;
                }
                .action-btn-wrap {
                    .action-btn {
                        margin: 0 0.5rem 0.5rem 0;
                        text-transform: capitalize;
                        height: 1.8rem;
                        width: 6.5rem;
                    }
                }
                .curr-status {
                    padding-top: 0.5rem;
                    .status {
                        background-color: white;
                        color: black;
                        width: max-content;
                        padding: 0.2rem 0.5rem;
                        border-radius: 0.4rem;
                    }
                    .SUB {
                        color: #588157;
                        background-color: #5881571a;
                    }
                    .SHO {
                        background-color: #22bca81a;
                        color: #22bca8;
                    }
                    .REJ {
                        color: #d32f2f;
                        background-color: #d32f2f1a;
                    }
                    .PRK {
                        color: #777777;
                        background-color: #7777771a;
                    }

                    .flag-statement-wrap {
                        display: flex;
                        /* justify-content: center;
                        width: 60rem; */
                        padding: 0.5rem 0;
                        .flag-quotes {
                            font-size: 1.4rem;
                            transform: rotate(180deg);
                        }
                        .flag-statement {
                            font-size: 0.8rem;
                            font-style: italic;
                            font-weight: 500;
                            text-align: left;
                            padding: 0.5rem 0;
                        }
                    }
                }
            }
            .idea-team-name {
                font-size: 1.1rem;
                font-weight: 500;
            }
        }
    }
`;

const videos = ['MP4', 'MOV', 'WMV', 'WEBM', 'FLV', 'AVI'];
const images = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'ico',
    'bmp',
];
const audios = ['m4a', 'mp3', 'aac', 'wma', 'wav'];

const ViewHack = () => {
    const params = useParams();
    const hackId = params?.hackId;
    const [mode, setmode] = useState('noData');
    const { enqueueSnackbar } = useSnackbar();
    const [hackData, setHackData] = useState({});
    const [commentList, setcommentList] = useState([]);
    const [loading, setloading] = useState({ comment: true, hack: true });
    const [comment, setcomment] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [reason, setReason] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [isRevoke, setIsRevoke] = useState(false);
    const [expVideoUrl, setExpVideoUrl] = useState(null);
    const [arcDiagramUrl, setArcDiagramUrl] = useState(null);
    const { handleFetch } = useFetch();
    const { store } = useAppContext();
    const isPanelMember = store?.platformInfo?.panel;
    // const isPanelMember = useMemo(() => store?.platformInfo?.panel, [store]);
    // const isAdmin = useMemo(() => store?.platformInfo?.admin, [store]);

    const handleStatusUpdate = (status) => {
        setNewStatus(status);
        const msg = `Idea will be ${getIdeaStatusById(status)}!!!`;
        setConfirmMsg(msg);
        setIsRevoke(false);
        setOpenConfirm(true);
    };

    const handleRevoke = () => {
        setConfirmMsg('Are you sure to revoke the evaluation.');
        setIsRevoke(true);
        setOpenConfirm(true);
    };

    const evaluateIdea = () => {
        const teamId = hackData?.teamDetails?.id;
        const ideaId = hackData?.ideaId;
        const request = {
            evaluationNote: reason,
            evaluationType: newStatus,
        };
        DracathonServices.evaluateIdea(teamId, ideaId, request)
            .then((res) => {
                if (res.data.status === 'OK') {
                    setHackData(res.data.data);
                    enqueueSnackbar(
                        `Idea is ${getIdeaStatusById(res.data?.data?.evaluationStatus)}`,
                        {
                            variant: 'success',
                        }
                    );
                }
                // getStats();
                // getAllIdeasForPanel();
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error !', { variant: 'error' });
            })
            .finally(setReason(''));
    };

    const revokeEvaluation = (reason) => {
        const teamId = hackData?.teamDetails?.id;
        const ideaId = hackData?.ideaId;
        DracathonServices.revokeEvaluation(teamId, ideaId)
            .then((res) => {
                if (res.data.status === 'OK') {
                    setHackData(res.data.data);
                    enqueueSnackbar('Successfully revoked Idea Evaluation', {
                        variant: 'success',
                    });
                }
                // getStats();
                // getAllIdeasForPanel();
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error !', { variant: 'error' });
            });
    };

    const viewHack = useCallback(
        (id) => {
            DracathonServices.viewIdeaForPanel(id)
                .then((res) => {
                    if (res?.data?.data) {
                        setHackData(res.data.data);
                    } else {
                        enqueueSnackbar('Error in getting Idea data !', { variant: 'error' });
                    }
                    setloading((state) => ({ ...state, hack: false }));
                })
                .catch((err) => {
                    setloading((state) => ({ ...state, hack: false }));
                    enqueueSnackbar(err?.response?.data?.error || 'Error in getting Hack data !', {
                        variant: 'error',
                    });
                });
        },
        [enqueueSnackbar]
    );

    const getComments = useCallback(
        (id) => {
            DracathonServices.getComment(id)
                .then((res) => {
                    if (res?.data?.data) {
                        setcommentList(res.data.data);
                    } else {
                        enqueueSnackbar('Error in getting comments data !', { variant: 'error' });
                    }
                    setloading((state) => ({ ...state, comment: false }));
                })
                .catch((err) => {
                    setloading((state) => ({ ...state, comment: false }));
                    enqueueSnackbar(
                        err?.response?.data?.error || 'Error in getting comments data !',
                        { variant: 'error' }
                    );
                });
        },
        [enqueueSnackbar]
    );

    useEffect(() => {
        if (hackId) {
            viewHack(hackId);
            getComments(hackId);
        }
    }, [viewHack, hackId, getComments]);

    usePageLoader(Object.values(loading).includes(true));

    useEffect(() => {
        if (hackData && Object.keys(hackData).length > 0 && hackData?.architectureDiagram) {
            getMedia(hackData.architectureDiagram, 'arcDiagram');
            setmode('image');
        }
        if (hackData && Object.keys(hackData).length > 0 && hackData?.explanationVideo) {
            getMedia(hackData.explanationVideo, 'expVideo');
            setmode('video');
        }
    }, [hackData]);

    const postComment = () => {
        if (Utils.isEmptyOrSpaces(comment)) {
            return;
        }
        DracathonServices.postComment(hackId, { comment })
            .then((res) => {
                if (res?.data?.data) {
                    setcommentList(res?.data?.data);
                }
            })
            .catch((err) => {
                getComments(hackId);
                enqueueSnackbar(err?.response?.data?.error || 'Error in posting comment !', {
                    variant: 'error',
                });
            });
        setcomment('');
    };

    const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const pathname = urlObject?.pathname;
        const segments = pathname.split('/');
        const fileFormat = pathname.split('.').pop();
        const fileName =
            segments && segments.length > 0
                ? segments[segments.length - 1]
                : `downloaded_file.${fileFormat}`;
        return fileName;
    };

    const downloadFile = async (s3Url, content) => {
        try {
            const response = await fetch(content);
            if (!response.ok) {
                enqueueSnackbar('Network response was not ok!', {
                    variant: 'error',
                });
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement('a');
            a.href = url;
            a.download = getFileNameFromUrl(s3Url);
            document.body.appendChild(a);
            a.click();

            // Clean up
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            enqueueSnackbar('Error downloading the file!', {
                variant: 'error',
            });
        }
    };

    const videoAudioRenderUrl = (base64String, type) => {
        if (base64String) {
            // Convert Base64 string to Blob
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: type });

            // Create a URL for the Blob
            const videoUrl = URL.createObjectURL(blob);
            return videoUrl;

            // Cleanup the URL object when the component unmounts
            return () => URL.revokeObjectURL(videoUrl);
        }
    };

    // Convert base64 string to ArrayBuffer
    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    // Create a Blob from the ArrayBuffer
    const pdfRenderUrl = (base64, mimeType) => {
        const arrayBuffer = base64ToArrayBuffer(base64);
        return new Blob([arrayBuffer], {
            type: 'application/pdf',
        });
    };

    const saveMediaUrl = (url, type) => {
        switch (type) {
            case 'expVideo':
                setExpVideoUrl(url);
                return;
            case 'arcDiagram':
                setArcDiagramUrl(url);
                return;
        }
    };

    const getMedia = (url, type) => {
        let s3Url = url.split('.com/')[1];
        const extention = url?.split('.')?.pop();
        fetchFile(s3Url, type, extention);
    };

    const fetchFile = async (url, type, extention) => {
        if (!url) {
            return;
        }
        handleFetch(url, (mediaContent) => {
            if (mediaContent) {
                if (
                    videos.includes(extention?.toUpperCase()) ||
                    audios.includes(extention?.toLowerCase())
                ) {
                    const videoAudioUrl = videoAudioRenderUrl(
                        mediaContent?.content,
                        mediaContent?.contentType
                    );
                    console.log(videoAudioUrl);
                    saveMediaUrl(videoAudioUrl, type);
                } else if (extention === 'pdf') {
                    const pdfBlob = pdfRenderUrl(mediaContent?.content, mediaContent?.contentType);
                    const url = window.URL.createObjectURL(pdfBlob);
                    saveMediaUrl(`${url}#toolbar=0`, type);
                } else {
                    let srcValue = `data:${mediaContent?.contentType};base64,${mediaContent?.content}`;
                    saveMediaUrl(srcValue, type);
                }
            }
        });
    };

    const getExtention = (url) => {
        return url?.split('.')?.pop();
    };

    const renderArchDiagram = () => {
        const extention = getExtention(hackData.architectureDiagram);
        if (extention === 'pdf') {
            return (
                <embed
                    src={arcDiagramUrl}
                    width="100%"
                    height="100%"
                    type="application/pdf"
                    id="image-comp"
                />
            );
        } else if (videos.includes(extention?.toUpperCase())) {
            return <video src={arcDiagramUrl} controls />;
        } else if (audios.includes(extention?.toLowerCase())) {
            return <audio src={arcDiagramUrl} controls />;
        } else if (images.includes(extention?.toLowerCase())) {
            return <img src={arcDiagramUrl} />;
        } else {
            return (
                <div className="no-preview-container">
                    <p className="no-preview-msg">No preview available!</p>
                    <Button
                        onClick={() => downloadFile(hackData?.architectureDiagram, arcDiagramUrl)}
                        variant="text"
                        color="primary"
                        className="download-btn"
                    >
                        Download
                    </Button>
                </div>
            );
        }
    };

    return (
        <Main className="hack-details">
            <HeaderBanner title="Idea Details" />
            <div className="content">
                <div className="source-container">
                    {mode === 'noData' && (
                        <div className="no-preview-container">
                            <Typography variant="subtitle1">
                                No media contents to preview!
                            </Typography>
                        </div>
                    )}
                    {hackData?.explanationVideo && expVideoUrl && mode === 'video' && (
                        <video controls id="video-comp" src={expVideoUrl} />
                    )}

                    {hackData?.architectureDiagram &&
                        arcDiagramUrl &&
                        mode === 'image' &&
                        renderArchDiagram()}
                    {/* {hackData?.hack?.presentationUrl !== null && mode === 'doc' && (
                        <Link
                            id="presentation-comp"
                            className="presentation"
                            to={`/download?url=${hackData?.hack?.presentationUrl}`}
                            target="_blank"
                        >
                            <div>
                                <span>DOWNLOAD HACK PRESENTATION DOCUMENT</span>
                            </div>
                        </Link>
                    )} */}
                    {/* {hackData?.idea?.ideaDocument && mode === 'idea' && (
                        <Link
                            to={`/download?url=${hackData?.hack?.ideaDocument}`}
                            id="template-comp"
                            className="template"
                            target="_blank"
                        >
                            <div>
                                <span>DOWNLOAD IDEATION PRESENTATION DOCUMENT</span>
                            </div>
                        </Link>
                    )} */}
                    <div className="side-tiles">
                        {hackData?.explanationVideo && (
                            <div
                                className={`tile ${mode === 'video' ? 'selected' : ''}`}
                                id="video-sel"
                                title="Explanation Video"
                                onClick={() => setmode('video')}
                            />
                        )}
                        {hackData?.architectureDiagram && (
                            <div
                                className={`tile img ${mode === 'image' ? 'selected' : ''}`}
                                id="image-sel"
                                onClick={() => setmode('image')}
                                title="Architecture Diagram"
                            />
                        )}
                        {/* {hackData?.hack?.presentationUrl !== null && (
                            <div
                                className={`tile doc ${mode === 'doc' ? 'selected' : ''}`}
                                id="presentation-sel"
                                onClick={() => setmode('doc')}
                                title="Hack Document"
                            />
                        )} */}
                        {/* {hackData?.hack?.ideaDocument !== null && (
                            <div
                                title="Ideation Document"
                                className={`tile doc ${mode === 'idea' ? 'selected' : ''}`}
                                id="template-sel"
                                onClick={() => setmode('idea')}
                            />
                        )} */}
                    </div>
                </div>

                {hackData?.hack && hackData.hack !== null && (
                    <div className="team-details-wrap">
                        <div className="team-title">
                            <Typography className="title">Hack Details</Typography>
                        </div>
                    </div>
                )}

                {hackData?.ideaId && hackData.ideaId !== null && (
                    <div className="team-details-wrap">
                        <div className="team-title">
                            <Typography className="title">Idea Details</Typography>
                        </div>
                        <div className="idea-content-wrap">
                            {/* <Typography variant="subtitle2" className="idea-content-title">
                                Idea Name
                            </Typography> */}
                            <Typography
                                title={`Idea Name: ${
                                    hackData?.ideaName ? hackData.ideaName : '--'
                                }`}
                                variant="caption"
                                className="idea-content-value idea-team-name"
                            >
                                {hackData?.ideaName ? hackData.ideaName : '--'}
                            </Typography>
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Evaluation Status
                            </Typography>
                            <div className="curr-status">
                                {hackData?.evaluationStatus && (
                                    <Typography
                                        variant="subtitle2"
                                        className={`status ${
                                            hackData?.evaluationStatus
                                                ? hackData.evaluationStatus
                                                : '--'
                                        }`}
                                    >
                                        {getIdeaStatusById(
                                            hackData?.evaluationStatus
                                                ? hackData.evaluationStatus
                                                : '--'
                                        )}
                                    </Typography>
                                )}

                                {hackData?.evaluationNote && (
                                    <Box className="flag-statement-wrap">
                                        <FormatQuoteIcon
                                            fontSize="medium"
                                            color={
                                                hackData.evaluationStatus === 'SHO'
                                                    ? 'primary'
                                                    : hackData.evaluationStatus === 'REJ'
                                                    ? 'error'
                                                    : hackData.evaluationStatus === 'PRK'
                                                    ? 'secondary'
                                                    : 'success'
                                            }
                                            className="flag-quotes"
                                        />
                                        <Typography className="flag-statement">{`"${hackData.evaluationNote}"`}</Typography>
                                    </Box>
                                )}
                            </div>
                            {isPanelMember && (
                                <div className="action-btn-wrap">
                                    {hackData?.evaluationStatus &&
                                    (hackData.evaluationStatus === 'SHO' ||
                                        hackData.evaluationStatus === 'REJ') ? (
                                        <Button
                                            className="action-btn"
                                            variant="contained"
                                            color="warning"
                                            startIcon={<RestoreIcon />}
                                            title="Reset the Action"
                                            onClick={handleRevoke}
                                        >
                                            Reset
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                className="action-btn"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DoneIcon />}
                                                title="Shortlist the Idea"
                                                onClick={() => handleStatusUpdate('SHO')}
                                            >
                                                Shortlist
                                            </Button>
                                            <Button
                                                className="action-btn"
                                                variant="contained"
                                                color="error"
                                                startIcon={<CloseIcon />}
                                                title="Reject the Idea"
                                                onClick={() => handleStatusUpdate('REJ')}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                    {!hackData?.evaluationStatus && (
                                        <Button
                                            className="action-btn"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<UpdateIcon />}
                                            title="Park the Idea for the time being"
                                            onClick={() => handleStatusUpdate('PRK')}
                                        >
                                            Park
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Hack Category
                            </Typography>
                            <Typography variant="caption" className="idea-content-value">
                                {hackData?.category?.category ? hackData.category.category : '--'}
                            </Typography>
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Account
                            </Typography>
                            <Typography variant="caption" className="idea-content-value">
                                {hackData?.account?.name ? hackData.account.name : '--'}
                            </Typography>
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Problem Description
                            </Typography>
                            <Typography variant="caption" className="idea-content-value">
                                {hackData?.problem ? hackData.problem : '--'}
                            </Typography>
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Suggestion to Improve
                            </Typography>
                            <Typography variant="caption" className="idea-content-value">
                                {hackData?.improvement ? hackData.improvement : '--'}
                            </Typography>
                        </div>
                        <div className="idea-content-wrap">
                            <Typography variant="subtitle2" className="idea-content-title">
                                Proposed Idea/Solution
                            </Typography>
                            <Typography variant="caption" className="idea-content-value">
                                {hackData?.solution ? hackData.solution : '--'}
                            </Typography>
                        </div>
                    </div>
                )}

                {hackData?.teamDetails && hackData.teamDetails !== null && (
                    <div className="team-details-wrap">
                        <div className="team-title">
                            <Typography className="title">Team Details</Typography>
                        </div>
                        <div className="team-title-name">
                            <Typography
                                title={`Team Name: ${
                                    hackData?.teamDetails?.name ? hackData.teamDetails.name : '--'
                                }`}
                                variant="caption"
                                className="idea-content-value idea-team-name"
                            >
                                {hackData?.teamDetails?.name ? hackData.teamDetails.name : '--'}
                            </Typography>
                        </div>
                        <div className="members-listing-wrap">
                            {hackData?.teamDetails?.members &&
                                hackData.teamDetails.members.length > 0 &&
                                hackData.teamDetails.members.map((item, index) => (
                                    <div key={index} className="member">
                                        <div className="img">
                                            {item?.manager ? (
                                                <Badge
                                                    className="manager-badge"
                                                    badgeContent={
                                                        <Typography
                                                            variant="caption"
                                                            title="Team Manager"
                                                        >
                                                            M
                                                        </Typography>
                                                    }
                                                    color="primary"
                                                >
                                                    <img
                                                        src={
                                                            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                                                        }
                                                        alt="memberimg"
                                                        //      th:if="${member.image}"
                                                        //     th:src="@{${member.image}}"
                                                    />
                                                </Badge>
                                            ) : (
                                                <img
                                                    src={
                                                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                                                    }
                                                    alt="memberimg"
                                                    //      th:if="${member.image}"
                                                    //     th:src="@{${member.image}}"
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="name"
                                            // th:text="${member.name}"
                                        >
                                            <Typography
                                                noWrap
                                                variant="subtitle2"
                                                style={{ fontSize: '0.775rem' }}
                                            >
                                                {item?.name || '--'}
                                            </Typography>
                                        </div>
                                        <div
                                            className="email" //th:text="${member.email}"
                                        >
                                            <Typography
                                                noWrap
                                                className="member-email"
                                                title={`Email: ${item?.email || '--'}`}
                                                variant="caption"
                                            >
                                                {item?.email || '--'}
                                            </Typography>

                                            <Typography
                                                noWrap
                                                className="chip team-member"
                                                title={`Account: ${item?.account?.name || '--'}`}
                                            >
                                                {item?.account?.name || '--'}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}

                {false && (
                    <div className="title-cntr" id="title-cntr">
                        <div className="title-team">
                            <div className="title-text">{hackData?.idea?.shortDescription}</div>
                            <div className="team-name" title="Team Name"></div>
                            {hackData?.hack?.gitUrl && (
                                <div
                                    className="git-url"
                                    //    th:if="${hack.gitUrl!=null}"
                                    title="Source Code URL"
                                    //     th:text="${hack.gitUrl}"
                                >
                                    {hackData?.hack?.gitUrl}
                                </div>
                            )}
                        </div>

                        <div className="likes" id="vote-cmp">
                            <div className="vote-btn"></div>
                            <div className="like-count">
                                Votes :{/* [[${hack.voteCount}]]{' '} */}
                            </div>
                        </div>
                    </div>
                )}
                <div className="main-cntr">
                    <div className="left">
                        {/* <div className="desc">{hackData?.hack?.longDescription}</div> */}

                        {isPanelMember &&
                            false && ( //false is to hide this line
                                <div
                                    className="admin" // th:if="${isPanelMember}"
                                >
                                    <div
                                        className="rating-wrap"
                                        title="Panel Rating Session. Only panel members can see and submit ratings"
                                    >
                                        <h3>My Rating</h3>
                                        <div className="rating-comment">
                                            <div className="rating-comment-box">
                                                <textarea placeholder="Comment" id="ratingComment">
                                                    {/* [[${rating.message}]] */}
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="my-rating">
                                            <div className="inputs">
                                                <div className="box box-green box-example-pill">
                                                    <div className="box-body">
                                                        <select
                                                            id="example-pill"
                                                            name="rating"
                                                            autocomplete="off"
                                                        >
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="avrg">
                                                {/* [[${rating.averageRating}]] */}
                                                <small>/10</small>
                                                <label title="Average Rating">Avg. Rating</label>
                                            </div>
                                        </div>
                                        <h3>
                                            All Ratings
                                            {/* ([[${rating.ratingCount}]]) */}
                                        </h3>
                                        <div className="all-ratings">
                                            <div
                                                className="eachel"
                                                //   th:each="panelRating : ${rating.panelRatings}"
                                            >
                                                <div className="descr">
                                                    <small>{/* [[${panelRating.user}]] */}</small>
                                                    <p

                                                    // th:classappend="${loggedUser == panelRating.username}?'loggedUserComment' : ''"
                                                    >
                                                        {/* [[${panelRating.message}]] */}
                                                    </p>
                                                </div>
                                                <div
                                                    className="rate"

                                                    //  th:classappend="${loggedUser == panelRating.username}?'loggedUserRating' : ''"
                                                >
                                                    {/* [[${panelRating.rating}]] */}
                                                    &nbsp;<small>/10</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="mark-label">Mark As</span>
                                    <select
                                        className="multipleSelect"
                                        multiple
                                        name="language"
                                        id="judgement"
                                        style={{ width: '100%' }}
                                    >
                                        <option
                                        //     th:each="prize : ${prizes}"
                                        //    th:if="${prize.available}"
                                        //    th:value="${prize.code}"
                                        //   th:text="${prize.prizeName}"
                                        //   th:selected="${(prize.code=='HW' && hack.isHackWinner) || (prize.code=='HR' && hack.isHackRunnerUp) || (prize.code=='LW' && hack.isLocationWinner) || (prize.code=='LR' && hack.isLocationRunnerUp) || (prize.code=='PC' && hack.isPeopleChoice) || (prize.code=='AP' && hack.isInnovationWinner) }"
                                        ></option>
                                    </select>
                                </div>
                            )}
                        <div className="comments">
                            <h2 id="cmt-hdr">Comments</h2>
                            {commentList?.map((comment, ix) => (
                                <div
                                    key={ix}
                                    className="cmnt-item"
                                    //  th:each="comment : ${comments}"
                                >
                                    <div className="image-cntr">
                                        <div className="image">
                                            <img
                                                alt="comment-img"
                                                src={comment?.createdBy?.avatar || sampleUserImg}
                                            />
                                        </div>
                                    </div>
                                    <div className="cmnt-details">
                                        <div className="name">
                                            {comment?.createdBy?.name}
                                            <span>
                                                {moment(new Date(comment?.createdOn)).format(
                                                    'DD-MMM-YYYY hh:mm a'
                                                )}
                                            </span>
                                        </div>
                                        <div className="value">{comment?.comment}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="cmnt-item">
                                <div className="image-cntr">
                                    <div className="image">
                                        <img
                                            alt="avatar"
                                            src={
                                                store?.platformInfo?.user?.avatar
                                                    ? store.platformInfo.user.avatar
                                                    : '--'
                                            }
                                            //   th:src="@{${currentUser.avatar}}"
                                        />
                                    </div>
                                </div>
                                <div className="cmnt-details">
                                    <textarea
                                        onChange={(e) => setcomment(e.target?.value)}
                                        placeholder="Add new comment"
                                        id="cmt-txt"
                                        value={comment}
                                    />
                                </div>
                            </div>
                            <div className="add-cmnt">
                                <button
                                    onClick={() => setcomment('')}
                                    className="btn btn-black"
                                    id="cmt-btn-cancel"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={postComment}
                                    className="btn btn-green"
                                    id="cmt-btn-post"
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="right">
                        <h2>Team Members</h2>
                        {hackData?.members?.map((member) => (
                            <div
                                key={member?.id}
                                className="box"
                                //  th:each="member : ${hack.teamMembers}"
                            >
                                <div className="image">
                                    <img
                                        alt="member-img"
                                        src={member?.avatar || sampleUserImg}

                                        // th:if="${member.image}"
                                        // th:src="@{${member.image}}"
                                    />
                                </div>
                                <div className="name">{member?.name}</div>
                                <div className="email">{member.email}</div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
            {openConfirm && (
                <ConfirmPopup
                    message={confirmMsg}
                    open={openConfirm}
                    handleClose={() => {
                        setOpenConfirm(false);
                        setConfirmMsg('');
                    }}
                    handleSubmit={() => {
                        isRevoke ? revokeEvaluation() : evaluateIdea();
                        setOpenConfirm(false);
                    }}
                >
                    {!isRevoke && (
                        <div className="confirmation-child">
                            <TextField
                                // inputProps={{ readOnly: !isIdeaEditable }}
                                // helperText={error.improvement}
                                required
                                minRows={3}
                                maxRows={3}
                                multiline
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                label="Evaluation Note"
                                className="reason-area"
                            />
                        </div>
                    )}
                </ConfirmPopup>
            )}
        </Main>
    );
};

export default ViewHack;
