import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { tribathonLogo } from 'app/util/images/images';
import Profiles from './Profile';
import { clearAuthTokens, getApiToken } from 'app/util/Persistent.service';
import DracathonServices from 'app/Components/Dracathon.services';
import useAppContext from 'app/util/useAppContext';
import SelectEdition from 'app/Components/Common/SelectEdition';
import ApiLoader from 'app/api/ApiLoader';

const pages = [
    { label: 'HOME', link: '/home' },
    { label: 'MY HACK', link: '/ideation/detail?tab=details', mode: 'hack' },
    { label: 'WINNER', link: '/winner', mode: 'winner' },
    { label: 'REGISTRATION', link: '/ideation/detail?tab=details', mode: 'registration' },
    { label: 'GUIDELINES', link: '/home/guidelines' },
    { label: 'PRIZE', link: '/home/prize' },
    { label: 'ADMINISTRATION', link: '/admin-main/stats', adminOnly: true },
];

const linkStyle = {
    fontSize: '13px',
    padding: '0.5rem 1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#383838',
    textDecoration: 'none',
};
const Main = styled(AppBar)`
    box-shadow: 0 3px 20px #e1e1e129;
    position: relative;
    .header {
        background-color: white;
    }
    .MuiContainer-root {
        max-width: 100%;
    }
    .linkbutton {
        white-space: nowrap;
        font-size: 13px;
        padding: 5px 10px;
        font-weight: 500;
        margin-right: 10px;
        text-transform: uppercase;
        color: black;
        text-decoration: none;
    }
    .logo {
        max-height: 100px;
        img {
            overflow-clip-margin: content-box;
            overflow: clip;
            width: 165px;
        }
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
    }
    .dropdown {
        width: 75px;
        display: block;
        text-decoration: none;
        color: black;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        border: none;
        outline: none;
        .dropdown-content {
            display: block;
            text-decoration: none;
            color: black;
            font-size: 13px;
            font-weight: 500;
            font-family: 'Montserrat', sans-serif;
            border: none;
        }
    }
`;

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { store } = useAppContext();
    const announceWinner = store?.platformInfo?.settings?.announceWinner;
    const openRegistration = store?.platformInfo?.settings?.openRegistration;
    const myTeam = store?.platformInfo?.myTeams;

    const currentMode = useMemo(
        () =>
            announceWinner
                ? 'winner'
                : myTeam && myTeam.length > 0
                ? 'hack'
                : openRegistration
                ? 'registration'
                : '',
        [announceWinner, openRegistration, myTeam]
    );

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);

    const handleCloseNavMenu = () => setAnchorElNav(null);

    const handleLogout = () => {
        const accessToken = getApiToken();
        if (accessToken) {
            DracathonServices.logout(accessToken);
        }
        clearAuthTokens();
        window.location.replace('/login');
    };

    const privilegedItem = useMemo(
        () => store?.platformInfo?.admin || store?.platformInfo?.panel,
        [store]
    );

    return (
        <Main style={{ background: 'none' }} position="static">
            <Container className="header" maxWidth="xl">
                <Toolbar className="toolbar" disableGutters>
                    <div className="logo">
                        <Link to="/home">
                            <img alt="logo" src={tribathonLogo} />
                        </Link>
                    </div>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuOutlined color="primary" />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            className="routes"
                        >
                            {pages
                                .filter((item) => (item.adminOnly ? privilegedItem : true))
                                .filter((item) => (item?.mode ? item.mode === currentMode : true))
                                .map((page, idx) => (
                                    <MenuItem
                                        to={page.link}
                                        key={idx}
                                        component={Link}
                                        style={linkStyle}
                                        onClick={handleCloseNavMenu}
                                    >
                                        {page.label}
                                    </MenuItem>
                                ))}
                            <MenuItem>
                                <SelectEdition />
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                style={linkStyle}
                                to="#"
                                onClick={handleLogout}
                            >
                                LOGOUT
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages
                            .filter((item) => (item.adminOnly ? privilegedItem : true))
                            .filter((item) => (item?.mode ? item.mode === currentMode : true))
                            .map((page, idx) => (
                                <Link className="linkbutton" key={idx} to={page.link}>
                                    {page.label}
                                </Link>
                            ))}
                        <SelectEdition />
                        <Profiles handleLogout={handleLogout} />
                    </Box>
                </Toolbar>
            </Container>
            <ApiLoader />
        </Main>
    );
};
export default Navbar;
