import { styled } from '@mui/material';
import { crownImg, greentribeImg, ruleTribe } from 'app/util/images/images';
import React from 'react';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';

const Main = styled('div')`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        margin: 0 5%;
        padding-bottom: 100px;
    }

    .content .item {
        display: flex;
        margin-top: 150px;
        justify-content: space-around;
        align-items: center;
    }

    .content .item:first-child {
        margin-top: 100px;
    }

    .content .item .text {
        display: flex;
        flex-direction: column;
        /* min-width: 500px; */
    }

    .content .item .text .title {
        font-family: 'Playfair Display', serif;
        font-size: 40px;
        text-align: left;
    }

    .content .item .text .title span {
        font-weight: 400;
        color: #383838;
    }

    .content .item .text .desc {
        font-size: 16px;
        margin-top: 30px;
        max-width: 650px;
        line-height: 35px;
        text-align: justify;
    }

    .content .item .img {
        height: 240px;
        margin: 0 30px;
    }

    .main-desc-ul {
        display: flex;
        flex-wrap: wrap;
    }

    .main-desc-ul li {
        padding: 5px 10px;
        margin: 0 20px;
    }
`;

const Rules = () => {
    document.title = 'Rules - Tribathon';
    return (
        <Main>
            <HeaderBanner title="Rules" />

            <div className="content">
                <div className="item">
                    <div className="text">
                        <div className="title">Days & Dates</div>
                        <div className="desc">
                            <span>
                                Start date of registration: <b>October 15 Monday</b>
                            </span>
                            <br />
                            <span>
                                Last date of registration: <b>Nov 2 Friday</b>
                            </span>
                            <br />
                            <span>
                                Hack Development Start date : <b>November 2nd Friday</b>
                            </span>
                            <br />
                            <span>
                                Last date of submission : <b>November 9th Friday</b>
                            </span>
                            <br />
                            <span>
                                Geo-Specific Hack Demo day :{' '}
                                <b>Nov 9 Friday (Kochi), Nov 10 Saturday (Bangalore & USA)</b>
                            </span>
                            <br />
                        </div>
                    </div>
                    <div className="img">
                        <img src={ruleTribe} alt="tribe" />
                    </div>
                </div>
                <div className="item">
                    <div className="img">
                        <img src={crownImg} alt="crown" />
                    </div>
                    <div className="text">
                        <div className="title">Team</div>
                        <div className="desc">
                            All Litmus7ites including contractors are eligible to participate.
                            <br />
                            Teams should consist of at least at least 2 participants and should have
                            no more than 4 participants.
                            <br />
                            We encourage formation of diverse teams from multiple projects and
                            locations. However, one individual can be part of only 1 team, which can
                            register from a single location only.
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="text">
                        <div className="title">Registration</div>
                        <div className="desc">
                            There will be a <b>two week</b> period for hack registration.
                            <br />
                            One team member from a team can register on{' '}
                            <b>behalf of the entire team</b> through the website.
                            <br />
                            During registration team member can provide <b>team name</b>.<br />
                            He/she can also add/edit the <b>team members</b>.<br />A team member
                            once added to a team <b>cannot be added</b> to another team.
                            <br />
                        </div>
                    </div>
                    <div className="img">
                        <img alt="tribe" src={greentribeImg} />
                    </div>
                </div>

                <div className="item">
                    <div className="img">
                        <img src={ruleTribe} alt="tribe" />
                    </div>
                    <div className="text">
                        <div className="title">Geo-specific Hackathon</div>
                        <div className="desc">
                            <ul>
                                <li>
                                    A Geo-specific Hack day would be conducted on in all locations
                                    to showcase the hacks to Litmus7ites.
                                </li>
                                <li>
                                    Each team will be provided a space to showcase their hack and
                                    functionality.
                                </li>
                                <li>
                                    The first place winners of the Geo-specifc Hack day will
                                    considered for the global hackathon round.
                                </li>
                                <li>
                                    All deliverables must be uploaded by end of Geo-specifc hack
                                    day.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="text">
                        <div className="title">Hack Submission</div>
                        <div className="desc">
                            By the end of geo-specific hack day, teams must upload the following
                            deliverables in the website:
                            <br />
                            <ul>
                                <li>
                                    A video giving a hands on demo, showcasing the working
                                    functionality of the hack and various flows.
                                    <br />
                                    [Please note: Aesthetics of the video will not be judged and it
                                    is strongly suggested not to spend much time on that. Videos
                                    that are more than 3 minutes long will be eliminated from the
                                    hackathon.]
                                </li>
                                <li>
                                    Working code of the hacks. Submit code to a central repository
                                    and provide URL.
                                </li>
                                <li>
                                    A PPT that details the below points that will help judges, best
                                    understand the hack’s potential.
                                    <ul>
                                        <li>Category to which hack belongs.</li>
                                        <li>
                                            Description of how the hack can help get the desired
                                            output mentioned in that category.
                                        </li>
                                        <li>
                                            Description of the target retailer, and how they will
                                            benefit from this hack.
                                        </li>
                                        <li>
                                            Description of the solution, i.e. details of the hack.
                                        </li>
                                    </ul>
                                </li>
                                <li>A team picture with all team members.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="img">
                        <img alt="crown" src={crownImg} />
                    </div>
                </div>
                <div className="item">
                    <div className="img">
                        <img alt="tribe" src={greentribeImg} />
                    </div>
                    <div className="text">
                        <div className="title">Global Hackathon</div>
                        <div className="desc">
                            <ul>
                                <li>
                                    The first place winners from all geo-specific hackathons will be
                                    selected for the global hackathon round.
                                </li>
                                <li>There will be only 1 winner for global hackathon.</li>
                                <li>
                                    Teams will not have additional time to work on their hacks after
                                    the geo-specifc hack day.
                                </li>
                                <li>
                                    Global hackathon will also involve a popular choice round where
                                    Litmus7ites can vote for a hack of their choice.
                                </li>
                                <li>
                                    Each Litmus7ite can caste one vote each for a hack registered in
                                    a particular geo location.
                                </li>
                                <li>
                                    A Litmus7ite cannot vote for two hacks registered in same geo
                                    location.
                                </li>
                                <li>
                                    Each hack that gets the most votes in a geo-specific location
                                    will get a surprise prize.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="text">
                        <div className="title">Evaluation & Results</div>
                        <div className="desc">
                            <ul>
                                <li>
                                    All the evaluation will be done by a two level judging panel.
                                </li>
                                <li>
                                    Evaluation will be done remotely based on the hack deliverables
                                    uploaded to the website.
                                </li>
                                <li>
                                    No evaluation will be conducted during the Geo-specific hack day
                                    at office locations.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="img">
                        <img alt="tribe" src={ruleTribe} />
                    </div>
                </div>

                {/* <div className="item">
                    <div className="img">
                        <img src={greentribeImg} alt="green-tribe" />
                    </div>
                    <div className="text">
                        <div className="title">Participation and Demo</div>
                        <div className="desc">
                            <b>Participation</b>
                            <br /> All Litmus7ites including contractors are eligible to
                            participate. <br />
                            <b>Registration</b>
                            <br />
                            Registration period will be two weeks. While you may have many ideas,
                            you can only register one idea per team for this event. In addition,
                            participants can only register for one team. The ideas should be new and
                            non-competing . <br />
                            <b>Local Hack Day</b>
                            <br /> Two weeks will be provided for the making the hack followed by a
                            local hack day. On this date, you will have 15 minutes to present your
                            hack. The first 5 minutes is reserved to present your video. The next 5
                            minutes are reserved to walk through a hands-on demo of your hack with
                            the judges. The last 5 minutes are reserved for questions from judges.
                            The number of winners per category for the local demo day will be
                            determined after teams have registered to ensure fairness across
                            geographies with varying numbers of associates participating in the
                            event. The winners of the local demo day will compete at the
                            International demo day. <br />
                            <b>International Demo Day</b>
                            <br /> International demo day will occur one week after local hack day.
                            As all markets will be unable to present live during this event, all
                            submissions will be considered using the video and written descriptions
                            provided for the local demo day. Teams will not have additional time to
                            work on their hacks in between the local demo day and the international
                            demo day. All participants from the local events (winners and
                            non-winners) are encouraged to attend via Zoom, to the extent possible.
                        </div>
                    </div>
                </div> */}
            </div>
        </Main>
    );
};

export default Rules;
