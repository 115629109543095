import { fetchMedia } from 'app/Components/S3BucketServices';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useFetch = () => {
    const { enqueueSnackbar } = useSnackbar();
    const handleFetch = useCallback((mediaUrl, handleSubmit = () => {}) => {
        fetchMedia(mediaUrl)
            .then((response) => {
                handleSubmit(response?.data?.payload);
            })
            .catch((err) => {
                enqueueSnackbar(
                    err?.data?.error ||
                        err?.data?.message ||
                        err?.data?.status_message ||
                        'Error in fetching !',
                    {
                        variant: 'error',
                    }
                );
                handleSubmit(false);
            });
    }, []);
    return { handleFetch };
};

export default useFetch;
