import { ruleTribe, crownImg, greentribeImg } from 'app/util/images/images';
import React from 'react';

const imageMap = {
    0: ruleTribe,
    1: crownImg,
    2: greentribeImg,
};

const PointItem = ({ point }) => (
    <li className="list-item" dangerouslySetInnerHTML={{ __html: point }} />
);

const GuideLineItem = ({ title = '', points = [], index = 0, avatar = '' }) => {
    return (
        <div className="item">
            <>
                {/* <div className="img">
                    <img src={avatar || imageMap[index % 3]} alt="tribe" />
                </div> */}
                <div className="text" style={{ marginLeft: '50px' }}>
                    <div className="title">{title}</div>
                    <div className="desc">
                        <ul>
                            {points?.map((point, key) => (
                                <PointItem point={point} key={key} />
                            ))}
                        </ul>
                    </div>
                </div>
            </>
        </div>
    );
};

export default GuideLineItem;
