import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { infoImg, tribathonLoginImg } from 'app/util/images/images';
// import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import DracathonServices from '../Dracathon.services';
import { setToken } from 'app/util/Persistent.service';
// import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MicrosoftLogin from 'react-microsoft-login';
import MicrosoftIcon from 'app/util/Icons/MicrosoftIcon';
import { useSnackbar } from 'notistack';
import { AZURE_CLIENT_ID, AZURE_TENANT_URL } from 'app/util/Constants';

const LoginBox = styled(Box)`
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .logo {
        height: 250px;
        width: 100%;
        background: url(${tribathonLoginImg});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 100%;
    }

    .microsoft-login {
        transform: scale(0.9);
    }
    .login-btn {
        min-width: 12rem;
        min-height: 2rem;
        display: flex !important;
        justify-content: center !important;
        align-items: center;
        padding: 0.1rem 0.3rem 0.1rem 0.3rem;
        background-color: #ffffff;
        border: 0.1rem solid rgb(140, 140, 140);
        // border-radius: 0.4rem;
        text-transform: unset;
        font-size: 1rem;
        .MuiButton-label {
            display: flex;
            height: 100%;
        }
        .sign-in-text {
            font-size: 0.8rem;
            font-weight: 450;
            color: #5d5d5d;
            padding-left: 0.5rem;
        }
        .icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            // color: $primary-color;
            height: 100%;
            padding: 0.2rem;
            border-radius: 2px;
            .MuiSvgIcon-root {
                width: 0.6em;
                height: 0.6em;
            }
            .icon-face {
                #Blue_x5F_Square {
                    fill: #51aed9;
                }
                #Yellow_x5F_Square {
                    fill: #fec327;
                }
                #Green_x5F_Square {
                    fill: #34b67a;
                }
                #Orange_x5F_Square {
                    fill: #f15723;
                }
            }
        }
    }

    .log-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        color: #178aef;
        font-weight: 600;
        text-shadow: 0px 0px 10px #a9a8a8;
        font-size: 12px;
        align-items: center;
        gap: 0.2rem;
    }

    .log-info .reg-img {
        width: 15px;
    }

    .log-info span {
        margin-left: 5px;
    }
    .error {
        color: #4e4e4e;
        font-weight: bold;
        padding: 10px 30px;
        background: #fff1f1;
        border: 1px solid #ffe1e1;
        border-radius: 10px;
        margin-bottom: 10px;
    }
`;
const Login = () => {
    const [error, seterror] = useState(false);
    const [searchParams] = useSearchParams();
    const [msalInstance, setMsalInstance] = useState({});
    const [showPreLoader, setShowPreLoader] = useState(false);
    const redirectUrl = searchParams.get('redirect_to') || '/home';
    const { enqueueSnackbar } = useSnackbar();

    const handleLogin = (data) => {
        // const email = jwtDecode(data);
        // console.log(email);
        // if (email && email?.split('@')?.pop()?.toLowerCase() !== 'litmus7.com') {
        //     seterror(true);
        //     return;
        // }
        setShowPreLoader(true);
        const credentials = {
            idtoken: data.idToken,
        };
        DracathonServices.googleLogin(credentials)
            .then((res) => {
                if (res?.data?.access_token) {
                    setToken(
                        res?.data?.access_token,
                        res?.data?.refresh_token,
                        credentials?.idtoken
                    );
                    window.location.replace(redirectUrl);
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || 'Error in Login', {
                    variant: 'error',
                });
            })
            .finally(() => {
                setShowPreLoader(false);
            });
    };

    const loginHandler = (err, data, msal) => {
        if (!err && data) {
            setMsalInstance(msal);
            handleLogin(data);
        }
    };

    return (
        <LoginBox>
            <div className="logo" />
            {error && <span className="error">Please use LITMUS7 Email Id to login</span>}
            <MicrosoftLogin
                clientId={AZURE_CLIENT_ID}
                authCallback={loginHandler}
                buttonTheme="light_short"
                redirectUri="/"
                className="microsoft-login"
                tenantUrl={AZURE_TENANT_URL}
                // graphScopes={['profile', "openid", "User.Read", "email"]}
                // forceRedirectStrategy={true}
                // debug
            >
                <Button variant="outlined" className="login-btn" disableElevation>
                    <span className="icon-box">
                        {showPreLoader ? (
                            <CircularProgress size="1.3rem" color="primary" />
                        ) : (
                            <MicrosoftIcon viewBox="0 0 32 32" className="icon-face" />
                        )}
                    </span>
                    <Typography className="sign-in-text">Sign in with Microsoft</Typography>
                </Button>
            </MicrosoftLogin>

            <div className="log-info">
                <img className="reg-img" src={infoImg} alt="info-img" />
                <span style={{ color: '#383838' }}>Please Use Litmus7 Account</span>
            </div>
        </LoginBox>
    );
};

export default Login;
