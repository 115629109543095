import { Backdrop } from '@mui/material';
import React, { useEffect } from 'react';
import useAppContext from 'app/util/useAppContext';
import { useLocation } from 'react-router-dom';
import FallbackLoader from 'app/Components/Common/FallBackLoader/FallBackLoader';

export const useLoader = (loading, msg) => {
    const { setStore } = useAppContext();
    const location = useLocation();

    useEffect(() => {
        setStore('loader', { loading, msg: loading ? msg : '', path: location?.pathname });
    }, [loading, setStore, msg, location]);

    return null;
};

export const usePageLoader = (loading) => {
    const { setStore } = useAppContext();
    const { pathname } = useLocation();

    useEffect(() => {
        setStore('pageLoader', loading ? pathname : '');
    }, [loading, setStore, pathname]);

    return null;
};

const RootLoader = () => {
    const { store, setStore } = useAppContext();
    const { loading, path } = store?.loader;
    const { pathname } = useLocation();

    useEffect(() => {
        if (loading && path !== pathname) {
            setStore('loader', { loading: false, msg: '' });
        }
    }, [pathname, path, loading, setStore]);

    return (
        <>
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: 999, backgroundColor: 'white' }} open={true}>
                    <FallbackLoader />
                </Backdrop>
            ) : null}
        </>
    );
};

export default RootLoader;
