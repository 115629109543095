import { styled } from '@mui/material';
import { usePageLoader } from 'app/api/RootLoader';
import { themeColors } from 'app/util/Constants';
import useAppContext from 'app/util/useAppContext';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DracathonServices from '../Dracathon.services';
import WinnerCarousal from './WinnerCarousal';

const Main = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 110px);

    .logo {
        display: flex;
        flex: column;
    }
    .winners-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .winners-item .winner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .winners-item .head {
        margin: 20px 0;
        border-bottom: 1px solid #f0f0f0;
        &.top {
            max-width: 90vw;
        }
    }
    .winner h1 {
        max-width: fit-content;
        text-align: center;
        margin: 27px 0;
        font-size: 25px;
    }
    .winner h2 {
        font-size: 24px;
        color: #777;
        margin: 20px 0px;
        max-width: 500px;
        text-align: center;
        &.prize {
            color: ${themeColors.primary};
        }
    }
    .winner {
        padding: 10px 20px;
        background: #fcfcfc;
        box-shadow: 0 0 50px #00000012;
        min-width: 700px;
        margin-bottom: 50px;
    }
    .winners-item .winner .top {
        border: 2px solid white;
    }
    h3 {
        margin-top: 10px;
    }
    .winner a {
        padding: 5px 10px;
        margin: 20px;
        text-decoration: none;
        color: white;
        background: #343434;
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
    }

    .page-settings {
        margin: 40px 40px;
        width: 670px;
    }

    .page-settings label.text {
        font-size: 17px;
        padding: 5px 5px;
        font-weight: 500;
        color: #383838c7;
    }
    .config {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        margin-bottom: 20px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #efefef;
        width: 650px;
        height: 100px;
        float: left;
        margin-right: 30px;
    }

    .config .text {
        position: relative;
        margin: 20px 5px;
        bottom: 8px;
        font-size: 20px;
        padding-right: 7px;
        color: #383838;
    }

    .config .text a {
        text-decoration: none;
    }

    .config .toggle {
        font-family: 'Playfair Display', serif;
        color: #20e090;
        display: flex;
    }

    .description {
        color: #989898;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 34px;
        margin: 10px 0px;
    }

    .switch input {
        display: none;
    }

    .custom-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ca2222;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }
    .custom-slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .custom-slider {
        background-color: #2ab934;
    }

    input:focus + .custom-slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .custom-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(55px);
    }

    .custom-slider:after {
        content: 'No';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
    }

    input:checked + .custom-slider:after {
        content: 'Yes';
    }

    .config .submit-btn {
        font-size: 20px;
        background: #383838;
        width: 170px;
        height: 30px;
        color: white;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        margin-top: 4px;
        margin-left: 140px;
        border-radius: 34px;
        text-decoration: none;
        padding: 5px 5px;
    }
    .category {
        font-size: 20px;
        text-transform: capitalize;
        margin: 1rem 0px;
    }
    .top-text {
        text-align: center;
    }
    .custom-slider {
        &.disabled {
            background-color: gray !important;
        }
    }
`;

const ascending = (a, b) => {
    if (a.value > b.value) {
        return -1;
    }
    if (a.value < b.value) {
        return 1;
    }
    return 0;
};

const getComparator = () => (a, b) => ascending(a, b);

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const Results = ({ isAdmin = false }) => {
    document.title = 'Results - Tribathon';
    const { store } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const [resultData, setResultData] = useState([]);
    const [loading, setloading] = useState(true);
    const [checked, setChecked] = useState(false);
    const [disable, setdisable] = useState(false);

    const getWinners = useCallback(
        (isAdmin) => {
            DracathonServices.getWinners(isAdmin ? 'demo' : '')
                .then((res) => {
                    if (res?.data?.data) {
                        setResultData(res.data.data);
                    } else {
                        enqueueSnackbar('Error in getting Winners !', { variant: 'error' });
                    }
                    setloading(false);
                })
                .catch((err) => {
                    if (err?.response?.data?.error) {
                        enqueueSnackbar(
                            err?.response?.data?.error || 'Error in getting Winners !',
                            { variant: 'error' }
                        );
                    }
                    setloading(false);
                });
        },
        [enqueueSnackbar]
    );

    useEffect(() => {
        getWinners(isAdmin);
    }, [getWinners, isAdmin]);

    const getAllsettings = useCallback(() => {
        DracathonServices.getSettingsPage()
            .then((res) => {
                if (res?.data?.data) {
                    setChecked(res?.data?.data?.confirmWinnerPage);
                    setdisable(res?.data?.data?.announceWinner);
                } else {
                    enqueueSnackbar('Error !', { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error !', { variant: 'error' });
            });
    }, [enqueueSnackbar]);
    useEffect(() => {
        if (isAdmin) {
            getAllsettings();
        }
    }, [getAllsettings, isAdmin]);

    usePageLoader(loading);

    const handleCheckChange = () => {
        DracathonServices.changeConfig('confirmWinnerPage', !checked ? 1 : 0)
            .then((res) => {
                if (res.data?.status === 'OK') {
                    enqueueSnackbar('Winner page status changed', { variant: 'success' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error', { variant: 'error' });
            });
        setChecked((state) => !state);
    };

    return (
        <Main>
            <div
                className="top-text"
                //  th:if="${edition!=null}"
            >
                <h2>{store?.platformInfo?.currentEdition?.edition}</h2>
                {!loading && resultData?.length === 0 && (
                    <div
                    // th:if="${hackWinner == null && locationWinners.isEmpty() && locationRunnerUps.isEmpty() && popularChoice.isEmpty()}"
                    >
                        <h1> Results are not yet published </h1>
                    </div>
                )}
            </div>

            {resultData?.map((item, index) => (
                <div key={index} className="winners-item">
                    <h1 className="category">{item?.category?.category}</h1>
                    {stableSort(item?.winners || [], getComparator()).map((winner, id) => (
                        <div
                            // th:if="${hackWinner!=null}"
                            className="winner"
                            key={id}
                        >
                            {winner?.image ? (
                                <img
                                    className="head top"
                                    //th:src="${hackWinner.isNewEdition ? '/images/hack-winner.jpg' : '/images/globalWinners.jpg'}"
                                    src={winner?.image}
                                    alt="winner"
                                />
                            ) : (
                                <h2 className="prize">{winner?.prize}</h2>
                            )}

                            <WinnerCarousal teamList={winner?.team} />
                        </div>
                    ))}
                </div>
            ))}

            {isAdmin && (
                <div
                    className="page-settings" //th:if="${CRP != null}"
                >
                    <div className="config">
                        <span className="toggle">
                            <span className="text">Confirm winner page</span>
                            <label className="switch">
                                <input // th:checked="${CRP}" onclick="myFunction()"
                                    name="announceWinner"
                                    type="checkbox"
                                    id="confirm"
                                    checked={checked}
                                    disabled={disable}
                                    onChange={handleCheckChange}
                                />
                                <div
                                    className={`custom-slider round ${disable ? 'disabled' : ''}`}
                                    title={` ${
                                        disable ? 'Turn off announce winner to click' : ''
                                    } `}
                                ></div>
                            </label>
                            <Link to="/admin/settings" className="submit-btn">
                                Go back to settings
                            </Link>
                        </span>
                    </div>
                </div>
            )}
        </Main>
    );
};

export default Results;
