import { getApiToken } from 'app/util/Persistent.service';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const apiVersion = '';
const LocalToken = getApiToken();

const rootAxios = axios.create({
    baseURL: baseUrl + apiVersion,
    headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + LocalToken,
    },
});
export const multipartAxios = axios.create({
    baseURL: baseUrl + apiVersion,
    headers: {
        Authorization: 'Bearer ' + LocalToken,
        'Access-Control-Allow-Origin': '*',
        'content-type': 'multipart/form-data',
    },
});

export const customAxios = ({ withCredentials = false, auth = false, ...options }) =>
    axios.create({
        withCredentials,
        ...(auth && {
            headers: { Authorization: 'Bearer ' + LocalToken },
        }),
        ...options,
    });

export default rootAxios;
