import { Box, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import GuideLineItem from './GuideLineItem';
import { useSnackbar } from 'notistack';
import DracathonServices from '../Dracathon.services';
import { usePageLoader } from 'app/api/RootLoader';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';

const Main = styled(Box)`
    height: 100%;
    width: 100%;
    .content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 50rem;
        padding-bottom: 100px;
        @media (min-width: 1920px) {
            max-width: 90rem;
        }
    }

    .content .item {
        display: flex;
        margin-top: 15px;
        align-items: center;
    }

    .content .item:first-of-type {
        margin-top: 30px;
    }

    .content .item .text {
        display: flex;
        flex-direction: column;
        /* min-width: 500px; */
    }

    .content .item .text .title {
        font-family: 'Playfair Display', serif;
        font-size: 40px;
        text-align: left;
        max-width: 100%;
        color: #22bcac;
    }

    .content .item .text .title span {
        font-weight: 400;
        color: #383838;
    }

    .content .item .text .desc {
        font-size: 16px;
        /* margin-top: 30px; */
        max-width: 90%;
        line-height: 35px;
        text-align: justify;
    }

    .content .item .img {
        height: 240px;
        margin: 0 30px;
    }

    .main-desc-ul {
        display: flex;
        flex-wrap: wrap;
    }

    .main-desc-ul li {
        padding: 5px 10px;
        margin: 0 20px;
    }
    .list-item {
        white-space: pre-wrap;
    }
`;

const GuideLines = () => {
    document.title = 'GuideLines - Tribathon';
    const { enqueueSnackbar } = useSnackbar();
    const [guidelineList, setguidelineList] = useState([]);
    const [loading, setloading] = useState(true);

    const getGuidelines = useCallback(() => {
        DracathonServices.getGuidelines()
            .then((res) => {
                if (res?.data?.data) {
                    setguidelineList(res.data.data);
                } else {
                    enqueueSnackbar('Error in getting guidelines !', { variant: 'error' });
                }
                setloading(false);
            })
            .catch((err) => {
                setloading(false);
                enqueueSnackbar(err?.response?.data?.error || 'Error in getting guidelines !', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        getGuidelines();
    }, [getGuidelines]);

    usePageLoader(loading);

    return (
        <Main>
            <HeaderBanner title="Guidelines" />
            <div className="content">
                {guidelineList?.map((item, idx) => (
                    <GuideLineItem
                        key={idx}
                        avatar={item?.hintImg}
                        index={idx}
                        title={item.title}
                        points={item.guidelinePoints}
                    />
                ))}
            </div>
        </Main>
    );
};

export default GuideLines;
