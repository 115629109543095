import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    styled,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import Utils from 'app/util/Utils';

const initMemberForm = {
    name: '',
    email: '',
    account: '',
};
const initError = {
    email: '',
    name: '',
    account: '',
};
const CustomContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .MuiFormControl-root {
        margin-top: 0.5rem;
    }
    .MuiFormHelperText-root {
        color: red;
    }
`;

export default function AddmemberPopup({
    addMember = () => {},
    handleClose = () => {},
    open = false,
    accountsList = [],
}) {
    const [memberForm, setmemberForm] = useState(initMemberForm);
    const [err, seterror] = useState(initError);
    const validate = () => {
        let isvalid = true;
        let error = { ...initError };
        if (Utils.isEmptyOrSpaces(memberForm.email)) {
            error = { ...error, email: 'email required' };
            isvalid = false;
        } else if (memberForm.email.split('@').pop()?.toLowerCase() !== 'litmus7.com') {
            error = { ...error, email: 'Litmus7 email is required' };
            isvalid = false;
        }
        if (Utils.isEmptyOrSpaces(memberForm.name)) {
            error = { ...error, name: 'Name required' };
            isvalid = false;
        }
        if (memberForm.managerAccount === '') {
            error = { ...error, name: 'Account of member required' };
            isvalid = false;
        }
        seterror(error);
        return isvalid;
    };
    const submitForm = (e) => {
        e.preventDefault();
        if (validate()) {
            addMember(memberForm);
            setmemberForm(initMemberForm);
        }
    };
    const cancel = () => {
        setmemberForm(initMemberForm);
        handleClose();
    };
    return (
        <Dialog
            open={open}
            onClose={cancel}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
            aria-describedby="alert-dialog-description"
        >
            <form onSubmit={submitForm}>
                <DialogTitle id="alert-dialog-title">Add member</DialogTitle>
                <CustomContent>
                    <TextField
                        helperText={err.name}
                        required
                        value={memberForm.name}
                        onChange={(e) =>
                            setmemberForm((state) => ({
                                ...state,
                                name: e.target.value,
                            }))
                        }
                        label="name"
                    />
                    <TextField
                        helperText={err.email}
                        required
                        type="email"
                        onChange={(e) =>
                            setmemberForm((state) => ({
                                ...state,
                                email: e.target.value,
                            }))
                        }
                        label="email"
                    />
                    <FormControl className="sel-box item" sx={{ width: '100%' }}>
                        <InputLabel>Account</InputLabel>
                        <Select
                            helperText={err.account}
                            required
                            value={memberForm.account}
                            onChange={(e) =>
                                setmemberForm((state) => ({
                                    ...state,
                                    account: e.target.value,
                                }))
                            }
                            input={<OutlinedInput label="Account" />}
                        >
                            {accountsList.map((item, idx) => (
                                <MenuItem key={idx} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CustomContent>
                <DialogActions>
                    <Button color="default" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
