import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { checkImg, docwImg, imagewImg, restoreImg, videowImg } from 'app/util/images/images';
import { useMemo, useState } from 'react';
import { Grid, styled } from '@mui/material';
import useAppContext from 'app/util/useAppContext';
import { useNavigate } from 'react-router-dom';

const Main = styled(Grid)`
    width: 100%;
    margin: 0px auto;
    max-width: 90vw;
    .head {
        background: lightgray;
        th {
            padding: 10px;
            border: 1px solid white;
            white-space: nowrap;
        }
        .members {
            min-width: 100px;
        }
    }
    .body {
        td {
            padding: 10px;
            border: 1px solid white;
        }
    }
    tbody tr:nth-of-type(even) {
        background: #ddd !important;
    }
    .revoke-hack {
        cursor: pointer;
    }

    .revoke-hack img {
        display: block;
        margin: auto;
    }

    .view-hack-btn {
        color: white;
        padding: 5px 10px;
        background: #343434;
        border: none;
        box-shadow: 0 0 20px #cccc;
        cursor: pointer;
        white-space: nowrap;
    }
    .hack-submitted img {
        display: block;
        margin: auto;
    }
    .prizes {
        display: flex;
        flex-wrap: wrap;
        max-width: 200px;
        .prize {
            display: flex;
            border-radius: 5px;
            background: #7b7b7b;
            color: white;
            padding: 3px 3px;
            margin: 2px;
            text-align: center;
        }
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const excludeList = ['members', 'submittedHacks', 'revoke', 'view', 'hackSubmitted', 'prizes'];

const headCells = [
    {
        id: 'name',
        label: 'Team Name',
    },

    {
        id: 'shortDescription',
        label: 'Short Description',
    },
    {
        id: 'longDescription',
        label: 'Long Description',
    },
    {
        id: 'members',
        label: 'Members',
    },
    {
        id: 'submittedHacks',
        label: 'Submitted Hacks',
    },
    {
        id: 'gitUrl',
        panelOnly: true,
        label: 'Git Url',
    },
    {
        id: 'votes',
        label: 'Votes',
        panelOnly: true,
    },
    {
        id: 'prizes',
        label: 'Prizes',
        panelOnly: true,
    },
    {
        id: 'hackSubmitted',
        label: 'Hack Submitted',
    },
    {
        id: 'revoke',
        label: 'ReInvoke Submission',
    },
    {
        id: 'view',
        label: 'View Hack',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isAdmin, isPanelMember } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="head">
            <TableRow>
                {headCells
                    .filter((i) => (i.adminOnly ? isAdmin : i.panelOnly ? isPanelMember : true))
                    .map((headCell, index) => (
                        <TableCell
                            className={headCell.id}
                            key={index}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {!excludeList.includes(headCell?.id) ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                <> {headCell.label}</>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default function HackTable({ handleShortList = () => {}, rows = [] }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { store } = useAppContext();

    const isPanelMember = useMemo(() => store?.platformInfo?.panel, [store]);
    const isAdmin = useMemo(() => store?.platformInfo?.admin, [store]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const getColumndata = (list = []) => list.map((item) => ({ ...item, name: item?.team?.name }));
    return (
        <Main>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                    <EnhancedTableHead
                        isAdmin={isAdmin}
                        isPanelMember={isPanelMember}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody className="body">
                        {stableSort(getColumndata(rows), getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        // style={{
                                        //     backgroundColor:
                                        //         row.shortlisted === null
                                        //             ? '#ffffff'
                                        //             : row?.shortlisted
                                        //             ? '#a6e4a7'
                                        //             : '#e4c4a6',
                                        // }}
                                    >
                                        <TableCell
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                navigate(`/admin/hacks/${row?.team?.id}`)
                                            }
                                            className="name"
                                        >
                                            {row?.name || '--'}
                                        </TableCell>
                                        <TableCell className="description">
                                            {row?.shortDescription || '--'}
                                        </TableCell>
                                        <TableCell className="description">
                                            {row?.longDescription || '--'}
                                        </TableCell>
                                        <TableCell className="members">
                                            {row?.team?.members?.map((member) => (
                                                <div
                                                    key={member?.id}
                                                    //  th:inline="text"
                                                >
                                                    {member.name}
                                                </div>
                                            ))}
                                        </TableCell>
                                        <TableCell className="hacks">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {row?.videoUrl && (
                                                    <div
                                                        title="Video" // th:if="${hack.videoUrl!=null}"
                                                    >
                                                        <img src={videowImg} alt="videow" />
                                                    </div>
                                                )}

                                                {row?.imageUrl && (
                                                    <div
                                                        title="Image" //th:if="${hack.imageUrl!=null}"
                                                    >
                                                        <img src={imagewImg} alt="imagew" />
                                                    </div>
                                                )}
                                                {row?.presentationUrl && (
                                                    <div
                                                        title="Document(PPT)"
                                                        //    th:if="${hack.presentationUrl!=null}"
                                                    >
                                                        <img src={docwImg} alt="docw" />
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        {isPanelMember && (
                                            <>
                                                <TableCell>{row?.gitUrl || '--'}</TableCell>
                                                <TableCell>{row?.voteCount || '--'}</TableCell>
                                                <TableCell>
                                                    <div className="prizes">
                                                        <div
                                                            className="prize"
                                                            //
                                                            //  th:each="prize : ${hack.prizes}"
                                                        >
                                                            {/* [[${prize}]] */}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell>
                                            <div className="hack-submitted">
                                                <img
                                                    className="submit-flag"
                                                    //th:if="${hack.isFinished}"
                                                    alt="check"
                                                    src={checkImg}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                className="revoke-hack"
                                                title="Hack submitted. Click here to reopen hack submission for the team."
                                            >
                                                <img
                                                    //th:if="${hack.isFinished}"
                                                    src={restoreImg}
                                                    alt="restore"
                                                    // th:onclick="${'javascript:revokeSubmission(' + hack.teamId + ')'}"
                                                />
                                            </div>
                                        </TableCell>

                                        <TableCell>
                                            <button
                                                className="view-hack-btn"
                                                onClick={() =>
                                                    navigate(`/admin/hacks/${row?.team?.id}`)
                                                }
                                            >
                                                View Hack
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, ...(rows?.length > 25 ? [rows?.length] : [])]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Main>
    );
}
