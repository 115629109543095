import React, { useCallback, useEffect, useState } from 'react';
// import { useLoader } from 'app/Api/RootLoader';
// import useTitle from 'app/Util/useTitle';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import DracathonServices from 'app/Components/Dracathon.services';
import Utils from 'app/util/Utils';
import { useSnackbar } from 'notistack';
import useFetch from 'app/util/useFetch';
// import useDownload from 'app/util/useDownload';
// import Utils from 'app/Util/Utils';

const FileBox = styled(Box)`
    width: 100%;
    .top {
        display: flex;
        gap: 1rem;
        width: fit-content;
        margin: 0px auto;
        .download {
            margin: 0px auto;
        }
        margin-bottom: 0rem;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .preview-title {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .img-box {
        margin: 10px auto;
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 80vh;
        }
    }
    .download-wrapper {
        text-align: center;
        .download-btn {
            text-transform: capitalize;
            text-decoration: underline;
        }
    }
    .text {
        text-align: center;
    }

    .object {
        height: 85vh;
    }
    .not-found {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 2rem;
    }
    .video-box {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        video {
            background-color: black;
            margin: auto;
            width: 90vw;
            max-height: calc(100vh - 12rem);
            display: block;
            object-fit: contain;
        }
    }
    .pdf-box {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        height: calc(100vh - 8rem);
    }
`;

const videos = ['MP4', 'MOV', 'WMV', 'WEBM', 'FLV', 'AVI'];
const images = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'ico',
    'bmp',
];
const audios = ['m4a', 'mp3', 'aac', 'wma', 'wav'];

const getFileUrl = () => {
    const url = window.location.href;
    const found = url?.indexOf('url=');
    const route = url?.slice(found + 4);
    return route;
};

const PreviewComponent = ({ url = '' }) => {
    const [type, settype] = useState(null);
    const [item_url, setIitem_url] = useState(null);
    const [s3Buffer, setS3Buffer] = useState(null);
    const [ext, setext] = useState('');
    const [pdfBuffer, setPdfBuffer] = useState(null);
    const [loading, setloading] = useState(true);
    const [pdfLoaded, setpdfLoaded] = useState(false);
    const currentUrl = url || getFileUrl();
    const { enqueueSnackbar } = useSnackbar();
    // const { downloadFile } = useDownload();
    const { handleFetch } = useFetch();
    const [itemLoaded, setItemLoaded] = useState(false);

    const bufferPdf = useCallback((pdf_link) => {
        setloading(true);
        DracathonServices.viewPDffile(pdf_link)
            .then((response) => {
                var blob = new Blob([response.data], { type: 'application/pdf' });
                var pdfurl = window.URL.createObjectURL(blob) + '#view=FitW';
                setPdfBuffer(pdfurl);
                setpdfLoaded(true);
                setloading(false);
            })
            .catch((err) => {
                setloading(false);
            });
    }, []);

    useEffect(() => {
        if (!currentUrl || !Utils.isValidUrl(currentUrl)) {
            setIitem_url('invalid');
            setloading(false);
            return;
        }
        fetchFile(currentUrl.split('.com/')[1]);
        const extention = currentUrl?.split('.')?.pop();
        setext(extention);

        if (extention === 'pdf') {
            settype('pdf');
        } else if (images.includes(extention?.toLowerCase())) {
            settype('image');
        } else if (videos.includes(extention?.toUpperCase())) {
            settype('video');
        } else if (audios.includes(extention?.toLowerCase())) {
            settype('audio');
        }
        setloading(false);
    }, [currentUrl]);

    const getFileNameFromUrl = () => {
        const pathname = currentUrl;
        const segments = pathname.split('/');
        const fileFormat = pathname.split('.').pop();
        const fileName =
            segments && segments.length > 0
                ? segments[segments.length - 1]
                : `downloaded_file.${fileFormat}`;
        return fileName;
    };

    const downloadFile = async (s3Url, fileName) => {
        try {
            const response = await fetch(s3Url);
            if (!response.ok) {
                enqueueSnackbar('Network response was not ok!', {
                    variant: 'error',
                });
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement('a');
            a.href = url;
            a.download = getFileNameFromUrl();
            document.body.appendChild(a);
            a.click();

            // Clean up
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            enqueueSnackbar('Error downloading the file!', {
                variant: 'error',
            });
        }
    };

    const fetchFile = async (url) => {
        if (!url) {
            return;
        }
        handleFetch(url, (resp) => {
            setS3Buffer(resp);
        });
    };

    const videoAudioRenderUrl = (base64String, type) => {
        if (base64String) {
            // Convert Base64 string to Blob
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: type });

            // Create a URL for the Blob
            const videoUrl = URL.createObjectURL(blob);
            setIitem_url(videoUrl);

            // Cleanup the URL object when the component unmounts
            return () => URL.revokeObjectURL(videoUrl);
        }
    };

    // Convert base64 string to ArrayBuffer
    function base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    // Create a Blob from the ArrayBuffer
    function pdfRenderUrl(base64, mimeType) {
        const arrayBuffer = base64ToArrayBuffer(base64);
        return new Blob([arrayBuffer], {
            type: 'application/pdf',
        });
    }

    useEffect(() => {
        if (s3Buffer) {
            if (type === 'video' || type === 'audio') {
                videoAudioRenderUrl(s3Buffer?.content, s3Buffer?.contentType);
            } else if (type === 'pdf') {
                const pdfBlob = pdfRenderUrl(s3Buffer?.content, s3Buffer?.contentType);
                const url = window.URL.createObjectURL(pdfBlob);
                setIitem_url(`${url}#toolbar=0`);
            } else {
                let srcValue = `data:${s3Buffer?.contentType};base64,${s3Buffer?.content}`;
                setIitem_url(srcValue);
            }
        }
    }, [s3Buffer]);

    return (
        <FileBox>
            {!pdfBuffer && !loading && item_url !== 'invalid' && (
                <Box className="top" width="100%">
                    <Typography className="title-preview" color="primary">
                        View Document
                    </Typography>
                    {/* <IconButton
                        variant="contained"
                        className="download"
                        size="small"
                        title="Download"
                        onClick={handleDownloadFile}
                    >
                        <DownloadIcon />
                    </IconButton> */}
                </Box>
            )}
            {(() => {
                if (item_url === 'invalid') {
                    return <Typography className="not-found">Invalid URL</Typography>;
                }
                if (type === 'pdf') {
                    return (
                        <Box display={itemLoaded ? 'flex' : 'none'} className="pdf-box">
                            <embed
                                src={item_url}
                                width="100%"
                                height="100%"
                                type="application/pdf"
                            />
                        </Box>
                    );
                }
                if (type === 'video') {
                    return (
                        <Box display={itemLoaded ? 'flex' : 'none'} className="video-box">
                            <video onLoad={() => setItemLoaded(true)} src={item_url} controls />
                        </Box>
                    );
                }
                if (type === 'audio') {
                    return (
                        <Box display={itemLoaded ? 'flex' : 'none'} className="video-box">
                            <audio onLoad={() => setItemLoaded(true)} src={item_url} controls />
                        </Box>
                    );
                }
                if (type === 'image') {
                    return (
                        <Box display={itemLoaded ? 'flex' : 'none'} className="img-box">
                            <img onLoad={() => setItemLoaded(true)} alt={ext} src={item_url} />
                        </Box>
                    );
                }

                return loading ? (
                    <Typography className="text">Loading...</Typography>
                ) : (
                    <div className="download-wrapper">
                        <Typography className="text">Preview not available !</Typography>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => downloadFile(item_url)}
                            className="download-btn"
                        >
                            Download
                        </Button>
                    </div>
                );
            })()}
        </FileBox>
    );
};

export default PreviewComponent;
