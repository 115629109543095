import { Box, styled } from '@mui/material';
import { infoImg, tribathonBanner } from 'app/util/images/images';
import useAppContext from 'app/util/useAppContext';
import React from 'react';
import { Link } from 'react-router-dom';

const HomePageDiv = styled(Box)`
    height: 100%;
    width: 100%;
    .banner-image-wrap {
        height: 100%;
        width: 100%;
        .banner-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .content-wrap {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .actions {
            display: flex;
            justify-content: space-between;
            width: 350px;
        }
        .status {
            margin: 20px;
            font-weight: bold;
            color: rgb(85, 213, 197);
            display: flex;
            align-items: center;
            .reg-img {
                padding-right: 5px;
                width: 15px;
            }
            .phase-status span {
                margin-left: 0.5rem;
            }
        }
        .log-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
            color: #178aef;
            font-weight: 600;
            font-size: 12px;
            align-items: center;
            .reg-img {
                width: 15px;
            }
            span {
                margin-left: 5px;
            }
        }
        .reg-info {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 80px;
            color: #178aef;
            font-weight: 600;
            text-shadow: 0px 0px 10px #a9a8a8;
            font-size: 12px;
            .reg-img {
                width: 15px;
            }
            span {
                margin-left: 5px;
            }
        }
    }
`;

const HomePage = () => {
    document.title = 'Litmus7 Tribathon';
    const { store } = useAppContext();
    //  const hackSubmitted = store?.platformInfo?.hackSubmitted;
    //  const ideaShortlisted = store?.platformInfo?.ideaShortlisted;
    // const registered = store?.platformInfo?.registered;
    const teamsRegistered = store?.platformInfo?.myTeams;
    //   const ideaSubmitted = store?.platformInfo?.ideaSubmitted;
    //  const displayHacksToAll = store?.platformInfo?.settings?.displayHacksToAll;
    //   const announceShortlistedIdea = store?.platformInfo?.settings?.announceShortlistedIdea;
    const openVoting = store?.platformInfo?.settings?.openVoting;
    const announceWinner = store?.platformInfo?.settings?.announceWinner;
    const openRegistration = store?.platformInfo?.settings?.openRegistration;

    return (
        <HomePageDiv>
            <div className="banner-image-wrap">
                <img alt="edition" src={tribathonBanner} className="banner-img" />
            </div>
            <div className="content-wrap">
                <div className="actions">
                    {announceWinner && (
                        <Link
                            to="/winner"
                            // th:if="${DWAF}"
                        >
                            <button className="btn btn-green">Winner</button>
                        </Link>
                    )}
                    {openVoting && (
                        <Link
                            to="/hacks"
                            // th:if="${DWAF}"
                        >
                            <button className="btn btn-green">Vote</button>
                        </Link>
                    )}

                    {teamsRegistered && teamsRegistered.length >= 1 ? (
                        <Link
                            to="/ideation/detail?tab=details"
                            //th:if="${!isTeamMember && DROF && !DWAF && !DVOF}"
                        >
                            <button className="btn btn-green">My Hack</button>
                        </Link>
                    ) : openRegistration ? (
                        <Link
                            to="/ideation/detail?tab=details"
                            //th:if="${!isTeamMember && DROF && !DWAF && !DVOF}"
                        >
                            <button className="btn btn-green">Registration</button>
                        </Link>
                    ) : (
                        <Link
                            to="/home/prize"
                            // th:if="${DWAF}"
                        >
                            <button className="btn btn-green">Prizes</button>
                        </Link>
                    )}
                    {!(openVoting || announceWinner) && (
                        <Link
                            to="/home/guidelines"
                            //th:if="${!DVOF && !DHSOF && !DWAF}"
                        >
                            <button className="btn btn-black">Guidelines</button>
                        </Link>
                    )}
                    {/* 
                    <a
                        //th:if="${!DROF && !DVOF && !DHSOF && !DWAF && !DTDUF && !DTMUF && !DHUF && !IDEA && !IDERES}"
                        href="/home/prize"
                    >
                        <button className="btn btn-green">Prize</button>
                    </a>

                    <a
                        href="/hacks"
                        //th:if="${viewHacks && !DHSOF && !DVOF}"
                    >
                        <button className="btn btn-green">All Hacks</button>
                    </a> */}
                </div>

                {store?.platformInfo?.team && store?.platformInfo?.team?.members?.length < 2 && (
                    <div
                        className="log-info"
                        // th:if="${availableTeam != null && availableTeam.teamMembers.size() < 2}"
                    >
                        <img className="reg-img" alt="info-img" src={infoImg} />
                        <span>
                            <span className="text1">
                                Your team haven't reached the minimum requirement to submit the idea
                            </span>
                        </span>
                    </div>
                )}
                {/* <>
                    {ideaSubmitted && !announceWinner && !displayHacksToAll && (
                        <div
                            className="status"
                            //th:if="${isIdeaSubmitted && !announceWinner && !viewHacks}"
                        >
                            <img alt="info-img" className="reg-img" src={infoImg} />
                            <h2 className="phase-status">
                                Ideation phase status :
                                {ideaSubmitted && !announceShortlistedIdea && !hackSubmitted && (
                                    <span
                                        className="text1"
                                        //th:if="${isIdeaSubmitted && !announceShortlistedIdea && !isHackSubmitted }"
                                    >
                                        Idea is Submitted for Evaluation
                                    </span>
                                )}
                                {announceShortlistedIdea &&
                                    ideaShortlisted !== null &&
                                    ideaShortlisted === true &&
                                    !hackSubmitted && (
                                        <span
                                            //  th:if="${announceShortlistedIdea && availableTeam.isShortlisted != null && availableTeam.isShortlisted == true && !isHackSubmitted }"
                                            className="text2"
                                        >
                                            Your Idea has been approved
                                        </span>
                                    )}
                                {announceShortlistedIdea &&
                                    ideaShortlisted !== null &&
                                    ideaShortlisted === false &&
                                    !hackSubmitted && (
                                        <span
                                            className="text3"
                                            //th:if="${announceShortlistedIdea && availableTeam.isShortlisted != null && availableTeam.isShortlisted == false && !isHackSubmitted }"
                                        >
                                            You idea has not been shortlisted
                                        </span>
                                    )}
                                {ideaSubmitted &&
                                    ideaShortlisted !== null &&
                                    ideaShortlisted === true &&
                                    hackSubmitted && (
                                        <span
                                            className="text1"
                                            // th:if="${isIdeaSubmitted && availableTeam.isShortlisted != null && availableTeam.isShortlisted == true && isHackSubmitted }"
                                        >
                                            Hack is Submitted for Evaluation
                                        </span>
                                    )}
                            </h2>
                        </div>
                    )}
                </> */}
            </div>
        </HomePageDiv>
    );
};

export default HomePage;
