import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useMemo, useState } from 'react';
import { Button, Grid, IconButton, styled } from '@mui/material';
import useAppContext from 'app/util/useAppContext';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { getIdeaStatusById, themeColors } from 'app/util/Constants';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Main = styled(Grid)`
    width: 100%;
    margin: 0px auto;
    max-width: 90vw;
    .head {
        background: lightgray;
        th {
            padding: 10px;
            border: 1px solid white;
            white-space: nowrap;
        }
    }
    .body {
        td {
            padding: 10px;
            border: 1px solid white;
        }
    }

    .short-info {
        font-size: 11px;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        span {
            font-weight: 600;
        }
    }
    .export {
        text-decoration: none;
        color: ${themeColors.primary};
        font-size: 0.8rem;
        button {
            color: ${themeColors.primary};
            text-transform: none;
        }
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const excludeList = ['members', 'shortlist', 'revoke', 'view'];
const headers = [
    { key: 'idea_name', label: 'Idea Name' },
    { key: 'team_name', label: 'Team Name' },
    { key: 'manager', label: 'Manager' },
    { key: 'manager_account', label: "Manager's Account" },
    { key: 'category', label: 'Hack Category' },
    { key: 'account', label: 'Idea to the Account' },
    { key: 'status', label: 'Idea Status' },
];
const headCells = [
    { id: 'idea_name', label: 'Idea Name' },
    { id: 'team_name', label: 'Team Name' },
    { id: 'manager', label: 'Manager' },
    { id: 'manager_account', label: "Manager's Account" },
    { id: 'category', label: 'Hack Category' },
    { id: 'account', label: 'Idea to the Account' },
    { id: 'status', label: 'Idea Status', panelOnly: true },
    { id: 'view', label: 'View' },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isAdmin, isPanelMember } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="head">
            <TableRow>
                {headCells
                    .filter((i) => (i.adminOnly ? isAdmin : i.panelOnly ? isPanelMember : true))
                    .map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {!excludeList.includes(headCell?.id) ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                <> {headCell.label}</>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default function IdeaTable({ rows = [] }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { store } = useAppContext();
    const [data, setData] = useState([]);

    const isPanelMember = useMemo(() => store?.platformInfo?.panel, [store]);
    const isAdmin = useMemo(() => store?.platformInfo?.admin, [store]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        const arr = [];
        if (rows?.length > 0) {
            rows?.forEach((row) => {
                arr.push({
                    idea_name: row.ideaName,
                    team_name: row.teamDetails?.name,
                    category: row.category?.category,
                    account: row.account?.name,
                    manager: row.teamDetails?.members
                        .filter((x) => x.manager)
                        .map((y) => (y?.name ? y.name : '--'))
                        .join(', '),
                    manager_account: row.teamDetails?.members
                        .filter((x) => x.manager)
                        .map((y) => (y?.account?.name ? y.account.name : '--'))
                        .join(', '),
                    status: getIdeaStatusById(
                        row?.evaluationStatus
                            ? row.evaluationStatus
                            : row?.status
                            ? row.status
                            : '--'
                    ),
                });
            });
        }
        setData(arr);
    }, [rows]);

    return (
        <Main>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                    <EnhancedTableHead
                        isAdmin={isAdmin}
                        isPanelMember={isPanelMember}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody className="body">
                        {rows && rows.length > 0 ? (
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.ideaId}
                                            style={{
                                                backgroundColor:
                                                    row.evaluationStatus === null
                                                        ? '#ffffff'
                                                        : row.evaluationStatus === 'SHO'
                                                        ? '#a6e4a7'
                                                        : row.evaluationStatus === 'REJ'
                                                        ? '#e4c4a6'
                                                        : row.evaluationStatus === 'PRK'
                                                        ? '#eaeaea80'
                                                        : '#ffffff',
                                            }}
                                        >
                                            <TableCell
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    navigate(`/admin/hacks/${row?.ideaId}`)
                                                }
                                                className="name"
                                            >
                                                {row?.ideaName || '--'}
                                            </TableCell>
                                            <TableCell className="name">
                                                {row?.teamDetails?.name || '--'}
                                            </TableCell>
                                            <TableCell className="manager">
                                                {row?.teamDetails?.members
                                                    ?.filter((mem) => mem.manager)
                                                    .map((member) => (
                                                        <div key={member?.id}>{member.name}</div>
                                                    ))}
                                            </TableCell>
                                            <TableCell className="managerAccount">
                                                {row?.teamDetails?.members
                                                    ?.filter((mem) => mem.manager)
                                                    .map((member) => (
                                                        <div key={member?.id}>
                                                            {member?.account?.name
                                                                ? member.account.name
                                                                : '--'}
                                                        </div>
                                                    ))}
                                            </TableCell>
                                            <TableCell className="category">
                                                {row?.category?.category || '--'}
                                            </TableCell>
                                            <TableCell className="account">
                                                {row?.account?.name || '--'}
                                            </TableCell>

                                            {isPanelMember && (
                                                <TableCell className="shortlist">
                                                    {getIdeaStatusById(
                                                        row?.evaluationStatus
                                                            ? row.evaluationStatus
                                                            : row?.status
                                                            ? row.status
                                                            : '--'
                                                    )}
                                                </TableCell>
                                            )}

                                            <TableCell align="center" className="view">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        navigate(`/admin/hacks/${row?.ideaId}`)
                                                    }
                                                    title="View Idea Details"
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>

                                            {/* {isPanelMember && (
                                            <TableCell className="doc-view">
                                                <Link
                                                    className="view-hack-btn"
                                                    //href="${idea.templateS3link}"
                                                    to={`/download?url=${row?.ideaDocument}`}
                                                    target="_blank"
                                                >
                                                    View Submitted Doc
                                                </Link>
                                            </TableCell>
                                        )} */}
                                        </TableRow>
                                    );
                                })
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    No Ideas at the moment!
                                </TableCell>
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}>No Ideas.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
                {data?.length > 0 && (
                    <CSVLink
                        title="Export as CSV"
                        filename="Idea_List.csv"
                        data={data}
                        headers={headers}
                        className="export"
                    >
                        <Button size="small" color="primary" variant="outlined">
                            Export to CSV
                        </Button>
                    </CSVLink>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, ...(rows?.length > 25 ? [rows?.length] : [])]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Main>
    );
}
