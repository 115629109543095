import { Box, styled } from '@mui/material';
import { usePageLoader } from 'app/api/RootLoader';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import DracathonServices from '../Dracathon.services';
import PrizeItem from './PrizeItem';

const Main = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 60px;
    align-items: center;

    h2 {
        font-size: 32px;
    }
    .content {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }

    .content .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px;
        padding: 70px 30px;
        width: 200px;
        background: #f9f9f9;
        box-shadow: inset 0 0 50px #e5e5e5;
    }

    .content .item b {
        margin: 10px 0px;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    .content .item b i {
        font-weight: 300;
        font-size: 10px;
    }

    .content .item span {
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        color: #22bca8;
        word-break: break-word;
    }

    .content .first b {
        font-size: 22px;
    }

    .content .first span {
        font-size: 16px;
        font-weight: 500;
    }

    .content .global {
        margin: 40px 30%;
    }

    .content .global b {
        font-size: 24px;
    }

    .content .global span {
        font-size: 18px;
        font-weight: bold;
    }

    p {
        font-size: 16px;
        margin: 30px 0;
        font-weight: bold;
        background: #f9f9f9;
        box-shadow: inset 0 0 50px #e5e5e5;
        padding: 20px 40px;
    }
    .category {
        font-size: 18px;
        font-weight: bold;
        padding: 0px 40px;
        box-shadow: none;
        text-align: center;
        background: none;
    }
`;

const ascending = (a, b) => {
    if (a.value > b.value) {
        return -1;
    }
    if (a.value < b.value) {
        return 1;
    }
    return 0;
};
const descending = (a, b) => {
    if (a.value < b.value) {
        return -1;
    }
    if (a.value > b.value) {
        return 1;
    }
    return 0;
};

const getComparator = () => (a, b) => ascending(a, b);
const getCategoryComparator = () => (a, b) => descending(a, b);

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const Prizes = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [prizesList, setprizesList] = useState({});
    const [loading, setloading] = useState(true);
    const [renderMap, setrenderMap] = useState({});

    const processPrizes = (dataList = []) => {
        let dataMap = {};
        let render = {};
        dataList?.forEach((item) => {
            if (!dataMap[item?.hackCategory?.code]) {
                dataMap = { ...dataMap, [item?.hackCategory?.code]: [item] };
            } else {
                dataMap = {
                    ...dataMap,
                    [item?.hackCategory?.code]: [...dataMap[item?.hackCategory?.code], item],
                };
            }
        });
        Object.keys(dataMap).forEach((item) => {
            const value = dataMap[item][0]?.hackCategory?.sl;
            render = {
                ...render,
                [item]: value,
            };
            setrenderMap(render);
        });
        setprizesList(dataMap);
        setloading(false);
    };

    const getPrizes = useCallback(() => {
        DracathonServices.getPrizes()
            .then((res) => {
                if (res?.data?.data) {
                    processPrizes(res.data.data);
                } else {
                    enqueueSnackbar('Error in getting Prizes !', { variant: 'error' });
                    setloading(false);
                }
            })
            .catch((err) => {
                setloading(false);
                enqueueSnackbar(err?.response?.data?.error || 'Error in getting Prizes !', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        getPrizes();
    }, [getPrizes]);

    usePageLoader(loading);

    return (
        <Main>
            <h2>PRIZE</h2>
            {stableSort(
                Object.keys(renderMap).map((key) => ({
                    value: renderMap[key],
                    key,
                })),
                getCategoryComparator()
            ).map((item, index) => (
                <div key={index} className="category-box">
                    <p className="category">
                        {prizesList?.[item?.key]?.[0]?.hackCategory?.category}
                    </p>
                    <div className="content">
                        {stableSort(prizesList[item?.key], getComparator())?.map((prize, index) => (
                            <PrizeItem
                                className={index === 0 ? 'first animated tada' : 'runner'}
                                key={index}
                                prize={prize?.value}
                                title={prize?.prize}
                                currency={prize?.currency}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </Main>
    );
};

export default Prizes;
