import axios from 'axios';
import ApiUrlConstant from 'app/api/ApiUrlConstant';
import { S3_BUCKET_FOLDER_NAME, S3_BUCKET_NAME, S3_PLATFORM } from 'app/util/Constants';
import { getApiToken } from 'app/util/Persistent.service';

export async function uploadMedia(file, albumName) {
    if (!file) {
        return alert('Please choose a file to upload first.');
    }
    const LocalToken = getApiToken();
    const fileName = file.name;
    const albumPhotosKey = S3_BUCKET_FOLDER_NAME + albumName + '/';
    const photoKey = albumPhotosKey + fileName;
    // configS3();
    try {
        var requestOptions = new FormData();
        requestOptions.append('mediaKey', photoKey);
        requestOptions.append('media', file);
        const url = ApiUrlConstant.getMediaFullUrl('s3.upload', [S3_PLATFORM, S3_BUCKET_NAME]);
        const data = await axios.post(url, requestOptions, {
            headers: { Authorization: `Bearer ${LocalToken}` },
        });
        return data;
    } catch (error) {
        throw error;
    }
}

export async function fetchMedia(mediaUrl) {
    if (!mediaUrl) {
        return alert('Please get a url for the me.');
    }
    const LocalToken = getApiToken();
    try {
        const url = ApiUrlConstant.getMediaFullUrl('s3.download', [
            S3_PLATFORM,
            S3_BUCKET_NAME,
            mediaUrl,
            true,
        ]);
        const data = await axios.get(url, {
            headers: { Authorization: `Bearer ${LocalToken}` },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
