import { Card, styled } from '@mui/material';
import useAppContext from 'app/util/useAppContext';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const CustomCard = styled(Card)`
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.2rem 0.4rem;
    box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
        0px 1px 3px 0px rgb(0 0 0 / 12%);

    .load-text {
        font-size: 8px;
        margin: 0px;
        font-weight: 500;
    }
`;

const messageMap = {
    '/admin/settings': 'Loading Settings...',
    '/ideation/detail': 'Loading Registration...',
    '/admin/teams': 'Loading Teams...',
    '/admin/all-ideas': 'Loading Ideas...',
    '/home/guidelines': 'Loading Guidelines...',
    '/home/prize': 'Loading Prizes...',
    '/winner': 'Loading Winners...',
    '/admin-main/stats': 'Loading Dashboard...',
};

const MIN_DELAY = 500; //milli seconds

const ApiLoader = () => {
    const { store, setStore } = useAppContext();
    const pageLoader = store?.pageLoader;
    const mainLoader = store?.loader?.loading;
    const { pathname } = useLocation();
    const [timeOut, settimeOut] = useState(false);
    const [isPage, setisPage] = useState(false);

    useEffect(() => {
        if (pageLoader !== pathname) {
            setStore('pageLoader', '');
        }
    }, [pageLoader, pathname, setStore]);

    useEffect(() => {
        if (pageLoader) {
            settimeOut(false);
            setisPage(messageMap[pageLoader]);
            setTimeout(() => {
                settimeOut(true);
            }, MIN_DELAY);
        }
    }, [pageLoader]);

    return (
        <>
            {!mainLoader && (pageLoader || (isPage && !timeOut)) ? (
                <CustomCard className="api-loader">
                    <svg width="16px" height="12px" className="svg">
                        <polyline
                            className="polyline"
                            id="back"
                            points="1 6 4 6 6 11 10 1 12 6 15 6"
                        />
                        <polyline
                            className="polyline"
                            id="front"
                            points="1 6 4 6 6 11 10 1 12 6 15 6"
                        />
                    </svg>
                    <p className="load-text">
                        {messageMap[pageLoader] || isPage || 'Fetching data...'}
                    </p>
                </CustomCard>
            ) : null}
        </>
    );
};

export default ApiLoader;
