import { styled } from '@mui/material';
import { tribeImg } from 'app/util/images/images';
import React from 'react';
import { Link } from 'react-router-dom';

const Main = styled('div')`
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        height: 150px;
        width: 300px;
        background: url(${tribeImg});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .error {
        color: #898989;
        font-weight: 700;
        font-size: 30px;
    }
    .login {
        font-family: 'Montserrat', sans-serif;
        margin-top: 30px;
    }
`;
const DracathonError = () => {
    return (
        <Main>
            <div className="logo" />
            <div className="error">OOPS...</div>
            <div className="error">SOMETHING WENT WRONG !!!</div>
            <Link to="/home">
                <button className="btn login btn-green">Go Home</button>
            </Link>
        </Main>
    );
};

export default DracathonError;
