import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    styled,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadOrBrowse from '../Common/UploadOrBrowse';
import { useSnackbar } from 'notistack';
import { uploadMedia } from '../S3BucketServices';
import Utils from 'app/util/Utils';

const HackDiv = styled(Box)`
    text-align: -webkit-center;
    padding-top: 60px;
    padding-bottom: 1rem;
    max-width: 55%;
    margin: 0px auto;
    .hack-accordion {
        margin: 0.5rem 0;
        min-width: 35rem;
        .hack-idea-detail,
        .MuiAccordionSummary-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .hack-idea-account-name {
                display: flex;
                margin-right: 1rem;
                align-items: center;
                .idea-name {
                    font-size: 1rem;
                    font-weight: 500;
                    margin-right: 0.3rem;
                    max-width: 18rem;
                    min-width: auto;
                }
                .account-name {
                    font-size: 1rem;
                    font-weight: 400;
                    margin-right: 1rem;
                    max-width: 10rem;
                }
            }
            .submit-chip {
                height: 1.5rem;
                border-radius: 0.4rem;
                margin-right: 1rem;
                font-size: 0.8rem;
                font-weight: 500;
                .MuiChip-label {
                    padding: 0 0.4rem;
                }
            }
            .pending-stat {
                background-color: #ffcc00;
                color: white;
            }
        }
        .hack-idea-detail-wrap {
            .warning-list {
                display: grid;
            }
            .error-helper-text {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.66;
                text-align: left;
                /* margin-top: -14px; */
                margin-right: 14px;
                margin-bottom: 0;
                margin-left: 14px;
                color: red;
            }
        }
        ::before {
            height: 0;
        }
    }
    .active-hack-true {
        .idea-name {
            color: #22bca8;
            .expand-icon {
                color: #22bca8;
            }
        }
    }
`;

const initHackForm = {
    ideaId: '',
    codeFolder: null,
    demoVideo: null,
};
const initError = {
    ideaId: '',
    codeFolder: '',
    demoVideo: '',
};

const DEMO_VIDEO = 'video';
const CODE_FOLDER = 'folder';

const HackPhase = (props) => {
    const { hacks, submitHack, activeTeam, edition } = props;

    const [activeHack, setActiveHack] = useState('');
    const [hackForms, setHackForms] = useState([]);
    const [error, setError] = useState();
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleSelectHack = (id) => {
        activeHack === id ? setActiveHack('') : setActiveHack(id);
    };

    const removeUploads = (type) => {
        let tempHacks = hackForms.map((hack) => {
            if (hack?.ideaId === activeHack) {
                if (type === DEMO_VIDEO) {
                    return { ...hack, demoVideo: null };
                } else {
                    return { ...hack, codeFolder: null };
                }
            }
            return hack;
        });
        setHackForms(tempHacks);
    };

    const handleUploads = (images, type) => {
        let tempHacks = hackForms.map((hack) => {
            if (hack?.ideaId === activeHack) {
                if (type === DEMO_VIDEO) {
                    return { ...hack, demoVideo: images };
                } else {
                    return { ...hack, codeFolder: images };
                }
            }
            return hack;
        });
        setHackForms(tempHacks);
    };

    const handleUploadMediaInS3Bucket = async (file) => {
        let albumName = `beta/${edition}/${activeTeam}/${activeHack}`;
        let data = await uploadMedia(file, albumName);
        if (!data) {
            enqueueSnackbar('Something went wrong while uploading media', {
                variant: 'error',
            });
            return null;
        } else {
            return data;
        }
    };

    const onFileSelect = (file, type) => {
        let files = [file];
        let fileSize = 0;
        if (files.length < 1) return;
        for (let i = 0; i < files.length; i++) {
            fileSize = 0;
            fileSize = files[i].size / (1024 * 1024);
            if (files[i].type === 'video/mp4') {
                if (fileSize > 400) {
                    enqueueSnackbar('Maximum File size for video is 400MB.', {
                        variant: 'warning',
                    });
                    setIsUploadFailed(true);
                    return;
                }
            } else {
                if (fileSize > 50) {
                    enqueueSnackbar('Maximum File size is 50MB.', {
                        variant: 'warning',
                    });
                    setIsUploadFailed(true);
                    return;
                }
            }
        }
        files.forEach(async (file) => {
            await handleUploadMediaInS3Bucket(file)
                .then((data) => {
                    let location = data?.data?.payload?.Location;
                    handleUploads(location, type);
                })
                .catch((error) => {
                    enqueueSnackbar('Something went wrong while uploading media', {
                        variant: 'error',
                    });
                    setIsUploadFailed(true);
                });
        });
    };

    const validate = (request) => {
        let isvalid = true;
        let err = { ...initError, ideaId: activeHack };
        if (Utils.isEmptyOrSpaces(request?.codeFolder)) {
            err = { ...err, codeFolder: 'project code base required' };
            isvalid = false;
        }
        // if (Utils.isEmptyOrSpaces(request.demoVideo)) {
        //     error = { ...error, demoVideo: 'Hack demo video required' };
        //     isvalid = false;
        // }
        const tempError = error.map((x) => {
            return x?.ideaId === activeHack ? err : x;
        });
        setError(tempError);
        return isvalid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const request = hackForms.find((x) => x?.ideaId === activeHack);
        if (validate(request)) {
            submitHack(request, activeHack);
            setActiveHack('');
        }
    };

    useEffect(() => {
        let forms = [];
        let errorForm = [];
        hacks?.all.length > 0 &&
            hacks.all.map((idea) => {
                let form = {
                    ideaId: idea?.ideaId,
                    codeFolder: idea?.hack?.codeBase,
                    demoVideo: idea?.hack?.demoVideo,
                };
                let error = {
                    ideaId: idea?.ideaId,
                    codeFolder: '',
                    demoVideo: '',
                };
                forms.push(form);
                errorForm.push(error);
            });
        setHackForms(forms);
        setError(errorForm);
    }, [hacks]);

    useEffect(() => {
        hacks?.all?.length > 0 && hacks.all.length === 1 && setActiveHack(hacks.all[0]?.ideaId);
    }, [hacks]);

    return (
        <HackDiv>
            {hacks?.all?.length > 0 &&
                hacks.all.map((hack) => (
                    <Accordion
                        className={`hack-accordion active-hack-${hack?.ideaId === activeHack}`}
                        onChange={() => handleSelectHack(hack?.ideaId)}
                        expanded={hack?.ideaId === activeHack}
                    >
                        <AccordionSummary
                            className="hack-idea-detail"
                            expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        >
                            <Box className="hack-idea-account-name">
                                <Typography
                                    className="idea-name"
                                    title={`Idea: ${hack?.ideaName}`}
                                    noWrap
                                >
                                    {hack?.ideaName}
                                </Typography>
                                <Typography
                                    className="account-name"
                                    title={`Account: ${hack?.account?.name}`}
                                    noWrap
                                >
                                    {` (${hack?.account?.name})`}
                                </Typography>
                            </Box>
                            {hack?.hack?.codeBase ? (
                                <Chip
                                    variant="filled"
                                    color="primary"
                                    className="submit-chip"
                                    label="Submitted"
                                    title={`Status: Submitted`}
                                />
                            ) : (
                                <Chip
                                    variant="filled"
                                    className="submit-chip pending-stat"
                                    label="Pending"
                                    title={`Status: Pending`}
                                />
                            )}
                        </AccordionSummary>
                        {hackForms &&
                            hackForms
                                .filter((form) => form?.ideaId === activeHack)
                                .map((hackForm) => (
                                    <>
                                        <AccordionDetails className="hack-idea-detail-wrap">
                                            <UploadOrBrowse
                                                mediaUrl={hackForm?.codeFolder}
                                                // readOnly={!ideationChallengeOpen || !isManager}
                                                handleFileChange={(file) =>
                                                    onFileSelect(file, CODE_FOLDER)
                                                }
                                                removeFile={() => removeUploads(CODE_FOLDER)}
                                                type={CODE_FOLDER}
                                                isUploadFailed={isUploadFailed}
                                            />
                                            <div className="warning-list">
                                                <span className="error-helper-text">
                                                    {
                                                        error.find((x) => x?.ideaId === activeHack)
                                                            ?.codeFolder
                                                    }
                                                </span>
                                            </div>
                                            <UploadOrBrowse
                                                mediaUrl={hackForm?.demoVideo}
                                                // readOnly={!ideationChallengeOpen || !isManager}
                                                handleFileChange={(file) =>
                                                    onFileSelect(file, DEMO_VIDEO)
                                                }
                                                removeFile={() => removeUploads(DEMO_VIDEO)}
                                                type={DEMO_VIDEO}
                                                isUploadFailed={isUploadFailed}
                                            />

                                            <div className="warning-list">
                                                <span className="error-helper-text">
                                                    {
                                                        error.find((x) => x?.ideaId === activeHack)
                                                            ?.demoVideo
                                                    }
                                                </span>
                                                <span className="error-helper-text">
                                                    *Prefer .zip file for project repository.
                                                </span>
                                                <span className="error-helper-text">
                                                    *Prefer .mp4 format video files.
                                                </span>
                                                <span className="error-helper-text">
                                                    *Maximum file size is 400MB.
                                                </span>
                                            </div>
                                        </AccordionDetails>
                                        <AccordionActions>
                                            <Button
                                                color="default"
                                                onClick={() => setActiveHack('')}
                                            >
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                        </AccordionActions>
                                    </>
                                ))}
                    </Accordion>
                ))}
        </HackDiv>
    );
};
export default HackPhase;
