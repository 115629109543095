import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, styled } from '@mui/material';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import { themeColors } from 'app/util/Constants';

const AppContentWrap = styled(Paper)`
    height: auto;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    html,
    body {
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        color: #383838;
    }

    body {
        margin: 0;
    }

    button {
        /* color: red; */
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        font-family: 'Playfair Display', serif;
        color: #fff;
    }

    .black {
        color: #383838;
        font-weight: 400;
    }

    .btn {
        font-size: 14px;
        color: #383838;
        width: 170px;
        height: 50px;
        color: white;
        border: none;
        cursor: pointer;
    }

    .btn-green {
        background: #22bca8;
    }

    .btn-black {
        background: #383838;
    }

    .content .top-area .title {
        font-size: 30px;
        color: #22bca8;
    }
    .indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
    }
    .indicator .svg,
    .api-loader .svg {
        .polyline {
            fill: none;
            stroke-width: 2px;
            stroke-linecap: round;
            stroke-linejoin: round;
            &#back {
                animation: strokechangeback 8s infinite;
            }
            &#front {
                stroke-dasharray: 12, 36;
                stroke-dashoffset: 48;
                animation: dash 1s linear infinite, strokechangefront 8s infinite;
            }
        }
    }
    @keyframes strokechangeback {
        0% {
            stroke: rgba(${themeColors.primary}, $alpha: 0.3);
        }

        100% {
            stroke: rgba(${themeColors.primary}, $alpha: 0.3);
        }
    }
    @keyframes strokechangefront {
        0% {
            stroke: ${themeColors.primary};
        }

        100% {
            stroke: ${themeColors.primary};
        }
    }
    @keyframes dash {
        62.5% {
            opacity: 0;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
`;

const BoxWrap = styled(Box)`
    flex-grow: 1;
    height: 100%;
    max-height: calc(100vh - 64px);
    overflow: auto;
`;

const hideList = ['/login', '/page-not-found', '/tribathon-error'];

const AppContent = (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scroll(0, 0);
    }, [pathname]);

    //  const { store } = useAppContext();
    return (
        <AppContentWrap elevation={0}>
            {!hideList.includes(pathname) && <Navbar />}
            {/* {!hideList.includes(pathname) && store?.user && <Navbar />} */}
            <BoxWrap>{props?.children}</BoxWrap>
        </AppContentWrap>
    );
};

AppContent.defaultProps = {
    title: '',
    hasSidePane: true,
    user: {},
};

AppContent.propTypes = {
    title: PropTypes.string,
    hasSidePane: PropTypes.bool,
    user: PropTypes.object,
};
export default AppContent;
