import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    styled,
} from '@mui/material';
import DracathonServices from '../Dracathon.services';
import { usePageLoader } from 'app/api/RootLoader';
import { useSnackbar } from 'notistack';
import IdeaTable from './IdeaTable';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';

const Main = styled(Box)`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;
    h2 {
        text-align: center;
    }
    .idea-filter {
        width: 100%;
        margin: 0px auto;
        max-width: 90vw;
    }
    .idea-list-filter {
        .sel-box {
            margin: 0 0.5rem 0.5rem 0;
            width: 18rem;
        }
    }
`;

const DEFAULT_CAT = {
    category: 'ALL',
    code: 'ALL',
    description: 'ALL',
};
const DEFAULT_ACC = { id: 'ALL', name: 'ALL' };
const Idealist = ({ nobanner = false }) => {
    document.title = 'Ideas - Tribathon';
    const [ideaList, setideaList] = useState([]);
    const [loading, setloading] = useState(true);
    const [hackCategory, setHackCategory] = useState(DEFAULT_CAT.code);
    const [hackCategories, setHackCategories] = useState([]);
    const [account, setAccount] = useState(DEFAULT_ACC.id);
    const [accountList, setAccountList] = useState([]);
    const [page, setPage] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const loadData = useCallback(async () => {
        if (!hackCategory || !account) {
            return;
        }
        try {
            let [ideas] = await Promise.all([
                DracathonServices.getAllIdeasForPanel(
                    hackCategory && hackCategory !== 'ALL' ? hackCategory : '',
                    account && account !== 'ALL' ? account : ''
                ),
            ]);
            if (ideas?.data?.data) {
                setideaList(ideas?.data?.data);
            }
            setloading(false);
        } catch (err) {
            setloading(false);
            enqueueSnackbar(err?.response?.data?.error || 'Error in listing ideas', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar, hackCategory, account]);

    const loadHackType = useCallback(async () => {
        try {
            let [hacks] = await Promise.all([DracathonServices.getHackTypes()]);
            setHackCategories([DEFAULT_CAT, ...hacks?.data?.data]);
            setHackCategory(DEFAULT_CAT.code);
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Error in Loading hack types !', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);

    const getAccountsList = useCallback(async () => {
        try {
            let [accounts] = await Promise.all([DracathonServices.getAccountsList()]);
            setAccountList([DEFAULT_ACC, ...accounts?.data?.data]);
            setAccount(DEFAULT_ACC.id);
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Error in Loading accounts list !', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        loadHackType();
    }, [loadHackType]);

    useEffect(() => {
        getAccountsList();
    }, [getAccountsList]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    usePageLoader(loading);

    return (
        <Main>
            {!nobanner && <HeaderBanner title="All Ideas" />}
            <div className="ideation-phase">
                <h2> Idea Submission Details</h2>
                <div className="idea-filter">
                    <div className="idea-list-filter">
                        <FormControl className="sel-box">
                            <InputLabel>Hack Type</InputLabel>
                            <Select
                                value={hackCategory}
                                onChange={(e) => setHackCategory(e.target.value)}
                                input={<OutlinedInput label="Hack Type" />}
                            >
                                {hackCategories &&
                                    hackCategories.length > 0 &&
                                    hackCategories.map((item, idx) => (
                                        <MenuItem key={idx} value={item?.code}>
                                            {item?.description}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl className="sel-box">
                            <InputLabel>Account</InputLabel>
                            <Select
                                value={account}
                                onChange={(e) => setAccount(e.target.value)}
                                input={<OutlinedInput label="Account" />}
                            >
                                {accountList &&
                                    accountList.length > 0 &&
                                    accountList.map((item, idx) => (
                                        <MenuItem key={idx} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <IdeaTable rows={ideaList} />
            </div>
        </Main>
    );
};

export default Idealist;
