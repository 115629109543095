import { styled } from '@mui/material';
import { trophyImg } from 'app/util/images/images';
import React from 'react';

const Main = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 60px;
    align-items: center;

    .logo {
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 100px;
        align-items: center;
    }

    .logo span {
        color: #21bca8;
        font-size: 32px;
        margin-left: 20px;
    }

    .logo img {
        height: 100%;
    }

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        max-width: 800px;
    }

    .item .title {
        font-size: 32px;
        font-family: 'Playfair Display', serif;
        margin: 30px 0 10px 0;
        text-transform: uppercase;
    }

    .item .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .item .content video {
        background: #f5f5f5;
        min-height: 100px;
        border: 5px solid #eee;
        width: 100%;
        box-shadow: 0 0 20px #e0e0e0;
    }

    .item .content span {
        font-size: 18px;
        font-family: 'Playfair Display', serif;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    .item .content p {
        font-size: 14px;
        text-align: justify;
        white-space: pre-wrap;
    }
`;

const Winner = () => {
    document.title = 'Winners - Tribathon';
    return (
        <Main>
            <div className="logo">
                <img src={trophyImg} alt="trophy" />
                <span>WINNERS</span>
            </div>
            <div className="item">
                <div className="title">Hack Winner</div>
                <div className="content">
                    <video
                        controls
                        // th:if="${hackWinner.videoUrl !=null}"
                    >
                        <source
                            src=""
                            //   th:src="@{${hackWinner.videoUrl}}"
                        />
                    </video>
                    <span
                        style={{ cursor: 'pointer' }}
                        //  th:onclick="${'javascript:goToHackDetail(' + hackWinner.teamId + ')'}"
                    >
                        {/* [[${hackWinner.shortDescription}]] */}
                    </span>
                </div>
            </div>

            <div
                className="item" //th:each="locationWinner : ${locationWinners}"
            >
                <div
                    className="title" //th:inline="text"
                >
                    LOCATION WINNER -{/* [[${locationWinner.locationName}]] */}
                </div>
                <div className="content">
                    <video
                        controls // th:if="${locationWinner.videoUrl !=null}"
                    >
                        <source // th:src="@{${locationWinner.videoUrl}}"
                        />
                    </video>
                    <span
                        style={{ cursor: 'pointer' }}

                        // th:onclick="${'javascript:goToHackDetail(' + locationWinner.teamId + ')'}"
                    >
                        {/* [[${locationWinner.shortDescription}]] */}
                    </span>
                </div>
            </div>
        </Main>
    );
};

export default Winner;
