import { Box, styled } from '@mui/material';
import { themeColors } from 'app/util/Constants';
import { useCallback, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DracathonServices from '../Dracathon.services';

const Main = styled(Box)`
    width: 100%;
    text-align: center;
    margin-top: 20vh;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .file {
        font-weight: 600;
    }
    * {
        margin: 0px;
    }
    a,
    span {
        cursor: pointer;
        color: ${themeColors.primary};
        text-decoration: underline;
    }
`;

const DownloadPage = () => {
    const [searchParams] = useSearchParams();
    const url = searchParams.get('url');
    const navigate = useNavigate();

    const clickLink = useCallback((href, name) => {
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, []);

    const downLoadFile = useCallback(
        (url, name) => {
            DracathonServices.downLoadfile(url)
                .then((response) => {
                    const href = URL.createObjectURL(response?.data);
                    clickLink(href, name);
                })
                .catch(() => {
                    clickLink(url, name);
                });
        },
        [clickLink]
    );

    const fileDownload = useCallback(() => {
        if (url) {
            const name = url?.split('/')?.pop();
            downLoadFile(url, name);
        } else {
            navigate('/page-not-found');
        }
    }, [downLoadFile, url, navigate]);

    useEffect(() => {
        fileDownload();
    }, [fileDownload]);

    return (
        <Main>
            <p className="file">{url?.split('/')?.pop()}</p>
            <p>
                Not yet downloaded ? <span onClick={fileDownload}>Download</span>
            </p>
            <Link to="/home">Go Home</Link>
        </Main>
    );
};

export default DownloadPage;
