import React, { useCallback, useEffect, useState } from 'react';

import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import useAppContext from 'app/util/useAppContext';
import DracathonServices from '../Dracathon.services';
import SelectEdition from '../Common/SelectEdition';
import { useSnackbar } from 'notistack';
import IdeaTable from '../Idealist/IdeaTable';
import HackTable from './HackTable';
import { usePageLoader } from 'app/api/RootLoader';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';
import Idealist from '../Idealist/Idealist';

const Main = styled(Box)`
    /* display: flex;
    flex-direction: column; */
    height: 100%;
    width: 100%;
    .links {
        display: flex;
        padding-left: 60px;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        padding: 30px 0;
    }

    .links .link {
        margin: 20px;
        font-weight: bold;
        color: #434343;
    }

    .links .link a {
        text-decoration: none;
        color: #343434;
    }

    .links .link-edition {
        margin: 20px;
        font-weight: bold;
        color: rgb(85, 213, 197);
    }

    .boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .boxes .box {
        display: flex;
        flex-direction: column;
        margin: 20px;
        width: 250px;
        min-height: 300px;
        border: 1px solid #f0f0f0;
        box-shadow: 0 0 30px #e5e5e5;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }

    .boxes .box .count {
        font-size: 80px;
        color: #505050;
        font-weight: bold;
    }

    .boxes .box .label {
        font-size: 16px;
        margin: 10px;
    }

    .boxes .box .splits {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .boxes .box .splits .split {
        display: flex;
        padding: 5px 15px;
        border-radius: 10px;
        justify-content: space-between;
        background: #ccc;
        margin: 5px;
    }

    .boxes .box .splits .split .val {
        margin-left: 20px;
        font-weight: bold;
    }

    h3 {
        text-align: center;
    }
    .hacklist {
        display: flex;
        justify-content: center;
    }

    .hacklist table {
        margin: 40px 0;
        margin-top: 10px;
    }

    .hacklist table tr {
        padding: 10px 30px;
        text-align: left;
        font-size: 10px;
        margin: 30px 0;
    }

    .hacklist table thead tr,
    .hacklist table tbody tr:nth-of-type(even) {
        background: #ddd;
    }
    .hacklist table tr td,
    .hacklist table tr th {
        padding: 10px;
        border-bottom: 1px solid #eee;
    }

    .hacklist table tr td {
        word-wrap: break-word;
        max-width: 300px;
    }

    .hacklist .prizes {
        display: flex;
        flex-wrap: wrap;
        max-width: 200px;
    }

    .hacklist .prizes .prize {
        display: flex;
        border-radius: 5px;
        background: #7b7b7b;
        color: white;
        padding: 3px 3px;
        margin: 2px;
        text-align: center;
    }

    .hacklist .hack-submitted img {
        display: block;
        margin: auto;
    }

    .hacklist .revoke-hack {
        cursor: pointer;
    }

    .hacklist .revoke-hack img {
        display: block;
        margin: auto;
    }

    .hacklist .revoke-idea {
        cursor: pointer;
    }

    .hacklist .revoke-idea img {
        display: block;
        margin: auto;
    }

    .highlight:hover {
        background-color: #fffdf2;
    }

    .view-hack-btn {
        color: white;
        padding: 5px 10px;
        background: #343434;
        border: none;
        box-shadow: 0 0 20px #cccc;
        cursor: pointer;
    }

    .links .dropdown {
        text-decoration: none;
        color: #343434;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }

    .links .dropdown-content {
        text-decoration: none;
        color: #343434;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }
`;

const AdminMain = () => {
    const { store } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const [stats, setStats] = useState({});
    const [hackList, sethackList] = useState([]);
    const isPanelMember = store?.platformInfo?.panel;
    const isAdmin = store?.platformInfo?.admin;
    const platformInfo = store?.platformInfo;
    const hackOpen = platformInfo?.settings?.openHackSubmission;
    const ideationOpen = platformInfo?.settings?.openIdeaSubmission;
    const [loading, setloading] = useState({ stats: true });

    const getAllHacksForPanel = useCallback(() => {
        setloading((state) => ({ ...state, hacks: true }));
        DracathonServices.getAllHacksForPanel()
            .then((res) => {
                if (res?.data?.data) {
                    sethackList(res.data.data);
                }
                setloading((state) => ({ ...state, hacks: false }));
            })
            .catch((err) => {
                setloading((state) => ({ ...state, hacks: false }));
                enqueueSnackbar(err?.response?.data?.error || 'Error in getting Hacks !', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        if (!platformInfo) {
            return;
        }
        // const hackOpen = platformInfo?.settings?.openHackSubmission;
        // if (hackOpen) {
        //     getAllHacksForPanel();
        // }
    }, [platformInfo]);

    const getStats = useCallback(() => {
        DracathonServices.getStats()
            .then((res) => {
                if (res?.data?.data) {
                    setStats(res.data.data);
                }
                setloading((state) => ({ ...state, stats: false }));
            })
            .catch((err) => {
                setloading((state) => ({ ...state, stats: false }));
                enqueueSnackbar(err?.response?.data?.error || 'Error in getting stats !', {
                    variant: 'error',
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        getStats();
    }, [getStats]);

    usePageLoader(Object.values(loading).includes(true));

    return (
        <Main>
            <HeaderBanner title="Administration" />
            <div className="links">
                {(isPanelMember || isAdmin) && (
                    <div className="link-edition">
                        <span>Active Edition : {store?.platformInfo?.currentEdition?.edition}</span>
                    </div>
                )}
                {(isPanelMember || isAdmin) && (
                    <>
                        <div className="link">
                            <Link to="/admin/teams">All Teams</Link>
                        </div>
                        <div className="link">
                            <Link to="/admin/all-hacks">All Hacks</Link>
                        </div>
                        <div className="link">
                            <Link to="/admin/all-ideas">All Ideas</Link>
                        </div>
                    </>
                )}
                {isAdmin && (
                    <div className="link">
                        <Link to="/admin/settings">Settings</Link>
                    </div>
                )}

                <SelectEdition />
            </div>

            <div className="boxes">
                <div className="box">
                    <div className="count"> {stats?.totalTeams}</div>
                    <div className="label">Teams</div>
                    <div className="splits">
                        <div className="split">
                            <div className="name">Members</div>
                            <div className="val">{stats?.totalMembers}</div>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="count">{stats?.totalIdeaSubmissions}</div>
                    <div className="label">Idea Submissions</div>
                    <div className="splits">
                        <div className="split">
                            <div className="name">Short-listed Teams</div>
                            <div className="val">{stats?.totalShortlistedTeams}</div>
                        </div>
                        <div className="split">
                            <div className="name">Rejected Teams</div>
                            <div className="val">{stats.totalRejectedTeams}</div>
                        </div>
                        <div className="split">
                            <div className="name">Pending Teams</div>
                            <div className="val">{stats?.totalPendingTeams}</div>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="count">{stats.totalHackSubmission}</div>
                    <div className="label">Team Submitted Hack</div>
                </div>
                <div className="box">
                    <div
                        className="count"
                        style={{ fontSize: '35px', color: '#5cbca9', textAlign: 'center' }}
                        //
                    >
                        {stats?.phase}
                    </div>
                    <div className="label">Phase</div>
                    {isAdmin && (
                        <div className="splits">
                            <div className="split">
                                <div className="name" style={{ cursor: 'pointer' }}>
                                    <Link style={{ textDecoration: 'none' }} to="/admin/settings">
                                        Change
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {ideationOpen && (
                <div className="ideation-phase">
                    <Idealist nobanner={true} />
                </div>
            )}

            {/* {hackOpen && (
                <div
                    className="hack-phase"
                >
                    <h3> Hack Submission Details</h3>
                    <HackTable rows={hackList} />
                </div>
            )} */}
        </Main>
    );
};

export default AdminMain;
