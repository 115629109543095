import { Box, styled } from '@mui/material';
import { pageBanner } from 'app/util/images/images';
import React from 'react';

const BannerDiv = styled(Box)`
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    .banner-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .page-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const HeaderBanner = (props) => {
    const { title } = props;

    return (
        <BannerDiv className="title">
            <img src={pageBanner} className="banner-img" />
            <span className="page-title"> {title}</span>
        </BannerDiv>
    );
};
export default HeaderBanner;
