const DracathonApiUrlConstants = {
    dracathon: {
        //auth apis
        googleLogin: 'login/azure',
        logout: 'session/logout',
        //configuration-apis
        getSettingsPage: 'api/configuration',
        changeConfig: 'api/configuration/{:name}/{:value}',
        changeVersion: 'api/configuration/editions/{:value}/switch',
        getStats: 'api/configuration/stats',
        getGuidelines: 'api/configuration/guidelines',
        getPrizes: 'api/configuration/prizes',
        //ideation-apis
        submitIdea: 'api/teams/v2/{:teamId}/idea',
        updateIdea: 'api/teams/v2/{:teamId}/idea/{:ideaId}',
        submitHack: 'api/teams/v2/idea/{:idea}/hack',
        //logged-in-user-apis
        getPlatformInfo: 'api/me/platform-info',
        //registration-apis
        registerTeam: 'api/teams/register',
        updateTeam: 'api/teams/{:teamId}/register',
        //Team-api
        getMyTeam: 'api/teams/v2/my-team?teamId={:id}',
        addMember: 'api/teams/{:teamId}/members',
        removeMember: 'api/teams/{:teamId}/members/{:memberId}',
        getAllIdeasForPanel:
            'api/teams/v2/ideas/panel?category={:category}&account={:account}&pageSize={:pageSize}&pageNo={:pageNo}',
        removeMemberByPanel: 'api/teams/{:teamId}/members/{:memberId}/panel',
        getAllTeamForPanel: 'api/teams/panel',
        getAllHacksForPanel: 'api/teams/hacks/panel',
        getHackTypes: 'api/teams/hack-types',
        getAccountsList: 'api/masters/accounts',
        changeShortList: 'api/teams/{:teamId}/idea/{:ideaId}/{:type}',
        getWinners: 'api/teams/winners/{:type}',
        viewHack: 'api/teams/{:teamId}/hack',
        commentUrl: 'api/teams/{:teamId}/comment',
        revokeSubmission: 'api/teams/{:teamid}/idea/revoke',
        //upload file
        uploadFile: 'api/teams/{:teamId}/upload',
        evaluateIdea: 'api/teams/v2/{:teamId}/idea/{:ideaId}/evaluate',
        revokeEvaluation: 'api/teams/v2/{:teamId}/idea/{:ideaId}/revoke-evaluation',
        viewIdeaForPanel: 'api/teams/v2/ideas/panel/{:ideaId}',
    },
    s3: {
        upload: '/media-uploader?platform={:platform}&bucket={:bucket}',
        download:
            '/media-downloader?platform={:platform}&bucket={:bucket}&mediaUrl={:mediaUrl}&isBase64={:isBase64}',
    },
};

export default DracathonApiUrlConstants;
