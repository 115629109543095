import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    styled,
    OutlinedInput,
    Tabs,
    Tab,
    Badge,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Utils from 'app/util/Utils';
import { useSearchParams } from 'react-router-dom';
import DracathonServices from '../Dracathon.services';
import { useSnackbar } from 'notistack';
import useAppContext from 'app/util/useAppContext';
import {
    MEDIA_TYPE_PRESENTATION,
    MEDIA_TYPE_IMAGE,
    MEDIA_TYPE_VIDEO,
    themeColors,
} from 'app/util/Constants';
import { usePageLoader } from 'app/api/RootLoader';
import ConfirmPopup from '../Common/ConfirmPopup';
import AddmemberPopup from '../Common/AddmemberPopup';
import { Close, Done, HourglassTop, Info, PersonAdd } from '@mui/icons-material';
import UploadOrBrowse from '../Common/UploadOrBrowse';
import IdeaListingPage from './IdeaListingPage';
import IdeaSubmissionPopup from './IdeaSubmissionPopup';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';
import HackPhase from './HackPhase';

const Main = styled(Box)`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;

    .content {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    .tabs-header-wrap {
        margin-left: auto;
        .team-btn-filter-wrap {
            display: flex;
            align-items: center;
            .reg-btn {
                width: 11.5rem;
                height: 2.5rem;
                text-transform: capitalize;
                color: #ffffff;
            }
        }
    }

    .content .tabs {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .content .tabs .tab {
        width: 350;
        height: 60;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 900;
        color: #22bca8;
        font-family: 'Playfair Display', serif;
        margin: 0 10px;
        background: #f9f9f9;
        cursor: pointer;
    }

    .content .tabs .tab.active {
        background: #fff;
        box-shadow: 0 0 99px #e4e4e429;
        border-bottom: 2px solid #2cbeaa;
    }

    .content .tabs .tab span {
        font-weight: 400;
        color: #383838;
        margin-left: 5px;
    }

    .content .tab-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 60px;
        box-shadow: 0 0 99px #e4e4e429;
        max-width: 500px;
        margin: 0px auto;
        /*min-height: 500px;*/
    }

    .content .tab-content .input {
        width: 100%;
        height: 20px;
        margin-bottom: 15px;
        border: 1px solid #e8e8e8;
        padding: 20px;
        padding-left: 30px;
        font-size: 14px;
    }

    .content .tab-content .input.short {
        height: 50px;
        &.idea-short-desc {
            width: 90%;
        }
    }

    .content .tab-content .input.long {
        height: 180px;
        max-width: 680px;
    }

    .content .tab-content .team-members {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .content .tab-content .team-members .add-member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .content .tab-content .team-members .add-member .input {
        width: 300px;
        height: 50px;
        margin-right: 15px;
    }

    .content .tab-content .team-members .list-members {
        display: flex;
        margin-top: 45px;
        align-items: center;
        flex-wrap: wrap;
    }

    .content .tab-content .team-members .list-members .member {
        height: 200px;
        width: 200px;
        background: #fcfcfc;
        margin: 0 15px 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }

    .content .tab-content .team-members .list-members .member a {
        margin-left: 80%;
        text-decoration: none;
        color: #db1717;
        font-weight: 900;
    }

    .content .tab-content .team-members .list-members .member .img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #dfd9d9;
    }
    .MuiBadge-badge {
        padding: 0px;
        border-radius: 50%;
        top: 0.1rem;
        right: 0.3rem;
    }
    .content .tab-content .team-members .list-members .member .img img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #dfd9d9;
    }

    .content .tab-content .team-members .list-members .member .name {
        font-weight: 500;
        margin: 10px 0 2px 0;
    }

    .content .tab-content .team-members .list-members .member .email {
        font-weight: 300;
    }

    .content .tab-content .upd-btn {
        margin: 10px 0px;
    }

    .content .tab-content .upload-area {
        display: flex;
    }

    .content .tab-content .upload-area .upload-item {
        height: 50px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-image: url('/images/folder.png');
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .content .tab-content .upload-area .uploaded-item {
        height: 50px;
        width: 300px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fcfcfc;
        border: 1px solid #e8e8e8;
        background-position: right 30px center;
        background-repeat: no-repeat;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    .content .tab-content .upload-area .uploaded-item .media-url .close {
        text-decoration: none;
        color: #787878;
        margin-left: 10px;
    }

    .content .tab-content .upload-area .uploaded-item .remove-media .close {
        text-decoration: none;
        color: #787878;
        margin-left: 20px;
        border: 1px solid #7978788c;
        padding-left: 3px;
        padding-right: 3px;
    }

    .content .tab-content .team-members .add-member {
        padding-top: 35px;
    }

    .content .tab-content .add-team-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 99px #e4e4e429;
        width: 100%;
        .error-span {
            color: red;
            font-size: 13px;
            margin: 0px;
            font-weight: 500;
        }
        .ip-signer-check {
            align-self: start;
            .MuiSvgIcon-root {
                width: 1.2rem;
                height: 1.2rem;
            }
            .MuiTypography-root {
                font-weight: 500;
                font-size: 0.8rem;
            }
        }
        .item {
            margin: 0.5rem 0px;
        }
    }

    .btn-black {
        margin-top: 15px;
        background: #383838;
    }

    .loader-div {
        z-index: 1000;
        background-color: #ccc;
        opacity: 0.6;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: none;
    }

    .register-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    .loader-content {
        font-size: 20px;
        width: 90px;
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 45px);
        text-align: center;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .file-upload-main-div {
        width: 553px;
    }
    .ideation .upload-area label {
        width: 100%;
        max-width: 702px;
    }

    .gitrepo input {
        padding: 0px 14px;
        width: 90%;
    }

    .hackinput {
        width: 553px;
        height: 60px;
        margin-bottom: 15px;
        border: 1px solid #e8e8e8;
        padding: 20px;
        padding-left: 30px;
        font-size: 14px;
    }
    .sel-box {
        margin-top: 0.5rem;
        width: 100%;
        label {
            background-color: white;
        }
    }
    .team-sel-box {
        width: 17rem;
    }
    .active-sel {
        height: 2.5rem;
    }
    .add-new-member {
        height: fit-content;
        color: white;
        display: flex;
        flex-direction: column;
        svg {
            width: 4rem;
            height: 4rem;
        }
        .add-text {
            display: flex;
            color: ${themeColors.primary};
            margin: 0px;
            font-size: 14px;
        }
    }
    .info-text {
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        &.idea-submitted {
            color: black;
            svg {
                color: black;
            }
        }
        &.idea-rejected {
            color: red;
            svg {
                color: red;
            }
        }
        &.idea-approved {
            color: green;
            svg {
                color: green;
            }
        }
    }
    .email {
        display: flex;
        flex-direction: column;
        align-items: center;
        .member-email {
            max-width: 11rem;
        }
        .chip {
            background-color: ${themeColors.primary};
            color: white;
            margin-top: 5px;
            /* height: 15px; */
            min-width: 5rem;
            max-width: 11rem;
            font-size: 0.8rem;
            justify-content: space-evenly;
            padding: 2px 3px;
            border-radius: 4px;
            font-weight: 400;
            &.team-member {
                /* display: flex;
                align-items: center; */
                text-align: center;
                border: 1px solid ${themeColors.primary};
                background-color: white;
                color: ${themeColors.primary};
                .verified-icon {
                    font-size: 1rem;
                }
            }
        }
    }
    .member-remove-btn {
        height: 1.6rem;
        width: 100%;
        .manager-div {
            margin-bottom: 22px;
            svg {
                font-size: 14px;
                color: black;
            }
        }
    }
    .reg-closed {
        font-size: 1rem;
        font-weight: 500;
    }
    .template-download {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .link {
        color: indigo;
        text-decoration: underline;
        cursor: pointer;
    }
    .hack-submit-button-box {
        display: flex;
        justify-content: end;
        width: 100%;
    }
    .grid-item .file-upload-main-div {
        width: 100%;
        label {
            width: 100%;
        }
    }
    .flexbox {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .upload-or-browse-main-div {
        margin: 0.5rem 0px;
    }
    .tab-content label {
        background-color: white;
    }
    .hackupload {
        display: flex;
        & > div {
            width: 50%;
        }
        .upload-or-browse-main-div {
            margin: 0px;
        }
        .git-url input {
            padding: 10px;
        }
    }
    .long-desc {
        margin: 1rem 0px;
    }

    .tab-changer {
        margin-bottom: 1rem;
        height: 0px;
        button {
            text-transform: capitalize;
            height: 0px;
            color: ${themeColors.primary} !important;
        }
        .MuiTabScrollButton-horizontal.Mui-disabled {
            display: none;
        }
    }
    .update-warn {
        font-size: 0.8rem;
        font-weight: 500;
        color: red;
    }
`;
const initForm = {
    teamName: '',
    managerAccount: '',
    id: '',
    ipSigned: false,
};

const initideationForm = {
    shortDescription: '',
    ideaDocument: {},
};

const inithackPhaseForm = {
    longDescription: '',
    gitUrl: '',
    hackImage: {},
    hackPresentation: {},
    hackVideo: {},
    imageUrl: '',
    presentationUrl: '',
    videoUrl: '',
};

const initReady = {
    details: false,
    ideation: false,
    hack: false,
};

const hackTypeMap = {
    hackPresentation: MEDIA_TYPE_PRESENTATION,
    hackVideo: MEDIA_TYPE_VIDEO,
    hackImage: MEDIA_TYPE_IMAGE,
};

const Register = () => {
    const { store } = useAppContext();
    // const platformInfo = store?.platformInfo;
    // const ideationChallengeOpen = platformInfo?.settings?.openIdeaSubmission;
    // const isHackSubmissionOpen = platformInfo?.settings?.openHackSubmission;
    // const isRegistrationOpen = platformInfo?.settings?.openRegistration;
    // const ideationChallengeResultAnnounced = platformInfo?.settings?.announceShortlistedIdea;
    // const allowTeamDetailUpdate = platformInfo?.settings?.allowTeamDetailUpdate;
    // const allowTeamMemberUpdate = platformInfo?.settings?.allowTeamMemberUpdate;
    // const templatePath = platformInfo?.currentEdition?.templatePath;
    // const currentEdition = platformInfo?.currentEdition?.edition;
    // const myEmail = platformInfo?.user?.email;
    // const MyTeamList = platformInfo?.myTeams;

    const [searchParams, setsearchParams] = useSearchParams();
    const [teamForm, setteamForm] = useState(initForm);
    const [ideationForm, setideationForm] = useState(initideationForm);
    const [loading, setloading] = useState(false);
    const [tab, setTab] = useState('details');
    const [memberList, setMemberList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');
    const [isManager, setisManager] = useState(false);
    const [isReady, setIsReady] = useState(initReady);
    const [hackPhaseForm, sethackPhaseForm] = useState(inithackPhaseForm);
    const [openideationconfim, setideationconfirm] = useState(false);
    const [openhackconfim, sethackconfirm] = useState(false);
    const [openform, setopenform] = useState(false);
    const [hackCategories, setHacktype] = useState([]);
    const [accountsList, setAccountsList] = useState([]);
    const [loadUploading, setloadUpload] = useState({});
    const [openAddIdeaPopup, setOpenAddIdeaPopup] = useState(false);
    const [newIdeaRequest, setNewIdeaRequest] = useState({});
    const [ideaDetail, setIdeaDetail] = useState({});
    const [teamsList, setTeamsList] = useState([]);
    const [activeTeam, setActiveTeam] = useState(null);
    const [ideasList, setIdeasList] = useState([]);
    const [isIdeaEditable, setIsIdeaEditable] = useState(true);
    const [isUpdate, setIsUpdate] = useState(true);
    const [isNewReg, setIsNewReg] = useState(false);
    const [retrieveData, setRetrieveData] = useState(false);
    const [selectedHackType, setSelectedHackType] = useState({
        all: [],
        selected: {},
    });
    const [selectedHackTypeFinal, setSelectedHackTypeFinal] = useState({
        all: [],
        selected: {},
    });

    const [platformInfo, setPlatformInfo] = useState({});
    const [ideationChallengeOpen, setIdeationChallengeOpen] = useState(false);
    const [isHackSubmissionOpen, setIsHackSubmissionOpen] = useState(false);
    const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
    const [ideationChallengeResultAnnounced, setIdeationChallengeResultAnnounced] = useState(false);
    const [allowTeamDetailUpdate, setAllowTeamDetailUpdate] = useState(false);
    const [allowTeamMemberUpdate, setAllowTeamMemberUpdate] = useState(false);
    const [templatePath, setTemplatePath] = useState('');
    const [currentEdition, setCurrentEdition] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [MyTeamList, setMyTeamList] = useState([]);
    const [showHackTab, setShowHackTab] = useState(
        isHackSubmissionOpen && ideationChallengeResultAnnounced
    );
    const [activeIdeaStatus, setActiveIdeaStatus] = useState({});

    usePageLoader(loading);

    document.title = `${tab} - Tribathon`;

    const handleAddIdeaClick = (isEditable) => {
        setIsIdeaEditable(isEditable);
        setOpenAddIdeaPopup(true);
    };

    const handleIdeaChange = (key, value) =>
        setideationForm((state) => ({
            ...state,
            [selectedHackType?.selected]: {
                ...state[selectedHackType?.selected],
                [key]: value,
            },
        }));

    const handleHackChange = (key, value) =>
        sethackPhaseForm((state) => ({
            ...state,
            [selectedHackTypeFinal?.selected]: {
                ...state[selectedHackTypeFinal?.selected],
                [key]: value,
            },
        }));

    const createNewTeam = () => {
        setIsNewReg(true);
        setteamForm(initForm);
        setIsReady(initReady);
        setMemberList([]);
    };

    const exitNewReg = () => {
        setError('');
        setIsNewReg(false);
        loadData();
    };

    const handleTabChange = (item) => {
        setsearchParams({ tab: item });
        setTab(item);
    };

    const addMember = (memberForm) => {
        setopenform(false);
        putMember(memberForm);
    };

    const handleSubmit = (request) => {
        setNewIdeaRequest(request);
        setOpenAddIdeaPopup(false);
        setideationconfirm(true);
    };

    const validateRegister = () => {
        const arr = [];
        let valid = true;
        if (Utils.isEmptyOrSpaces(teamForm.teamName)) {
            arr.push('Team name required');
            valid = false;
        }
        if (teamForm.managerAccount === '') {
            arr.push('Account of manager required');
            valid = false;
        }
        if (!teamForm.ipSigned) {
            arr.push('Agree the Intellectual Property Policy');
            valid = false;
        }
        setError(arr.join(', '));
        return valid;
    };

    const tabList = useMemo(() => {
        const arr = ['details'];
        isReady?.details && arr.push('ideation');
        showHackTab && arr.push('hack');
        return arr;
    }, [isReady, showHackTab]);

    const loadHackType = useCallback(async () => {
        try {
            let [hacks] = await Promise.all([DracathonServices.getHackTypes()]);
            setHacktype(hacks?.data?.data);
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Error in Loading hack types !', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);

    const getAccountsList = useCallback(async () => {
        try {
            let [accounts] = await Promise.all([DracathonServices.getAccountsList()]);
            setAccountsList(accounts?.data?.data);
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Error in Loading accounts list !', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar]);

    const loadData = useCallback(async () => {
        if (!myEmail || !activeTeam) {
            return;
        }
        try {
            setloading(true);
            let [team] = await Promise.all([DracathonServices.getMyTeam(activeTeam)]);
            let details = false,
                ideation = false,
                hack = false;
            let shortListedList = [];
            if (team?.data?.data?.name) {
                const {
                    name: teamName = '',
                    managerAccount = '',
                    members = [],
                    id,
                } = team?.data?.data;
                setteamForm((state) => ({
                    ...state,
                    teamName,
                    managerAccount,
                    id,
                }));
                setSelectedHackType({ all: hackCategories, selected: hackCategories[0]?.code });
                setMemberList(members);
                const managerArr = members?.filter((i) => i.manager);
                if (managerArr[0]?.email) {
                    setisManager(managerArr[0]?.email === myEmail);
                }

                details = true;
            }
            if (team?.data?.data?.ideas?.length > 0) {
                ideation = true;
                setIdeasList(team.data.data.ideas);
                const ideaList = team?.data?.data?.ideas;
                let ideaMap = {};
                shortListedList = ideaList.filter((idea) => idea.evaluationStatus === 'SHO');
                ideaList?.forEach((idea) => {
                    ideaMap = {
                        ...ideaMap,
                        [idea?.category?.code]: {
                            ideaDocument: idea?.ideaDocument || '',
                            shortDescription: idea?.shortDescription || '',
                            category: idea?.category?.code,
                            isPut: true,
                            shortlisted: idea?.shortlisted,
                        },
                    };
                    idea?.shortlisted && shortListedList.push(idea?.category);
                });
                setSelectedHackTypeFinal({
                    all: shortListedList,
                    selected: shortListedList[0]?.code,
                });

                setideationForm(ideaMap);
            } else {
                setIdeasList([]);
            }
            if (team?.data?.data?.hacks?.length > 0) {
                const hackList = team?.data?.data?.hacks;
                let hackMap = {};
                hackList?.forEach((hack) => {
                    if (shortListedList?.map((i) => i.code).includes(hack?.category?.code)) {
                        const { imageUrl, longDescription, presentationUrl, videoUrl, gitUrl } =
                            hack;
                        hackMap = {
                            ...hackMap,
                            [hack?.category?.code]: {
                                imageUrl,
                                longDescription,
                                presentationUrl,
                                videoUrl,
                                gitUrl,
                                isPut: true,
                            },
                        };
                    }
                });

                sethackPhaseForm(hackMap);
                hack = true;
            }
            setIsReady((state) => ({ ...state, details, ideation, hack }));
            setloading(false);
        } catch (err) {
            setloading(false);
        }
    }, [myEmail, activeTeam]);

    const putMember = (memberForm) =>
        DracathonServices.addMember(teamForm?.id, {
            email: memberForm.email,
            name: memberForm.name,
            account: memberForm.account,
        })
            .then((res) => {
                enqueueSnackbar('Member Added', { variant: 'success' });
                loadData();
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in adding member', {
                    variant: 'error',
                });
            });

    const removeMember = (item, ix) => {
        if (isReady?.details) {
            DracathonServices.removeMember(teamForm?.id, item?.id)
                .then((res) => {
                    enqueueSnackbar('Member Removed', { variant: 'success' });
                    loadData();
                })
                .catch(() => {
                    enqueueSnackbar('Error in removing member', { variant: 'error' });
                });
        }
        setMemberList(memberList?.filter((_, i) => ix !== i));
    };

    const registerTeam = () => {
        if (!validateRegister()) {
            return;
        }
        const request = {
            managerAccount: teamForm.managerAccount,
            name: teamForm.teamName,
        };

        DracathonServices.registerTeam(request)
            .then((res) => {
                if (res?.data?.status === 'OK') {
                    enqueueSnackbar('Team registered', { variant: 'success' });
                    let teams = [
                        ...teamsList,
                        { id: res?.data?.data.id, name: res?.data?.data?.name },
                    ];
                    setTeamsList(teams);
                    setActiveTeam(res?.data?.data?.id ? res.data.data.id : teamsList[0]?.id);
                    setIsNewReg(false);
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in registering !', {
                    variant: 'error',
                });
                loadData();
            });
    };

    const postIdea = () => {
        setideationconfirm(false);
        setRetrieveData(false);
        DracathonServices.submitIdea(activeTeam, newIdeaRequest)
            .then((res) => {
                if (res?.data?.data) {
                    setOpenAddIdeaPopup(false);
                    loadData();
                    enqueueSnackbar('Idea Submitted', { variant: 'success' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in submitting', {
                    variant: 'error',
                });
            });
    };

    const updateIdea = () => {
        setideationconfirm(false);
        setRetrieveData(false);
        DracathonServices.updateIdea(activeTeam, newIdeaRequest, ideaDetail?.ideaId)
            .then((res) => {
                if (res?.data?.data) {
                    setOpenAddIdeaPopup(false);
                    loadData();
                    enqueueSnackbar('Idea updated', { variant: 'success' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data?.error || 'Error in submitting', {
                    variant: 'error',
                });
            });
    };

    const fileUpload = (file, type, key = 'hackPresentation') => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('mediaType', hackTypeMap[key]);
            if (type === 'ideation') {
                setloadUpload((state) => ({ ...state, ideaDocument: true }));
            } else {
                setloadUpload((state) => ({ ...state, [key]: true }));
            }
            DracathonServices.uploadFile(teamForm.id, formData)
                .then((res) => {
                    if (res?.data?.data) {
                        if (type === 'ideation') {
                            handleIdeaChange('ideaDocument', { ...res?.data?.data });
                            setloadUpload((state) => ({ ...state, ideaDocument: false }));
                        } else if (type === 'hack') {
                            handleHackChange(key, { ...res?.data?.data });

                            setloadUpload((state) => ({ ...state, [key]: false }));
                        } else {
                            setloadUpload({});
                        }
                    }
                })
                .catch((err) => {
                    if (type === 'ideation') {
                        setloadUpload((state) => ({ ...state, ideaDocument: false }));
                    } else {
                        setloadUpload((state) => ({ ...state, [key]: false }));
                    }
                    enqueueSnackbar(
                        err?.response?.data?.error || `Error in uploading ${key || 'document'} !`,
                        {
                            variant: 'error',
                        }
                    );
                });
        } else {
            if (type === 'hack') {
                handleHackChange(key, '');
            } else {
                handleIdeaChange('ideaDocument', '');
            }
        }
    };

    const finalSubmitHack = () => {
        sethackconfirm(false);
    };

    const submitHack = (req, idea) => {
        const request = {
            codeBase: req.codeFolder,
            demoVideo: req.demoVideo,
        };

        DracathonServices.submitHack(request, idea)
            .then((res) => {
                if (res?.data?.data) {
                    loadData();
                }
            })
            .catch((err) => {
                loadData();
                enqueueSnackbar(err?.response?.data?.error || 'Error in submitting', {
                    variant: 'error',
                });
            });
    };

    // const updateTeam = () => {
    //     if (!validateRegister()) {
    //         return;
    //     }
    //     const request = {
    //         managerAccount: teamForm.managerAccount,
    //         name: teamForm.teamName,
    //         // hackCategories: teamForm.hackCategories,
    //     };

    //     DracathonServices.updateTeam(teamForm.id, request)
    //         .then((res) => {
    //             if (res?.data?.status === 'OK') {
    //                 enqueueSnackbar('Team updated', { variant: 'success' });
    //                 loadData();
    //             }
    //         })
    //         .catch((err) => {
    //             enqueueSnackbar(err?.response?.data?.error || 'Error in updating team !', {
    //                 variant: 'error',
    //             });
    //         });
    // };

    useEffect(() => {
        if (store?.platformInfo !== null) {
            setPlatformInfo(store.platformInfo);
            setIdeationChallengeOpen(store.platformInfo.settings?.openIdeaSubmission);
            setIsHackSubmissionOpen(store.platformInfo.settings?.openHackSubmission);
            setIsRegistrationOpen(store.platformInfo.settings?.openRegistration);
            setIdeationChallengeResultAnnounced(
                store.platformInfo.settings?.announceShortlistedIdea
            );
            setAllowTeamDetailUpdate(store.platformInfo.settings?.allowTeamDetailUpdate);
            setAllowTeamMemberUpdate(store.platformInfo.settings?.allowTeamMemberUpdate);
            setTemplatePath(store.platformInfo.currentEdition?.templatePath);
            setCurrentEdition(store.platformInfo.currentEdition?.edition);
            setMyEmail(store.platformInfo.user?.email);
            setMyTeamList(store.platformInfo.myTeams);
            setTeamsList(store.platformInfo.myTeams);
            store.platformInfo.myTeams &&
                store.platformInfo.myTeams.length > 0 &&
                setActiveTeam(store.platformInfo.myTeams[0].id);
            const isHackTabVisible =
                store.platformInfo.settings?.openHackSubmission &&
                store.platformInfo.settings?.announceShortlistedIdea;
            setShowHackTab(isHackTabVisible);
        }
    }, [store.platformInfo]);

    useEffect(() => {
        if (!platformInfo) {
            return;
        }
        const tabItem = searchParams?.get('tab');
        if (tabList.includes(tabItem)) {
            setTab(tabItem);
        } else {
            setsearchParams({ tab: 'details' });
        }
    }, [searchParams, setsearchParams, tabList, platformInfo]);

    useEffect(() => {
        loadHackType();
    }, [loadHackType]);

    useEffect(() => {
        getAccountsList();
    }, [getAccountsList]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Main>
            <HeaderBanner title={isReady?.details ? 'My Hack' : 'Registration'} />
            <div className="loader-div" id="loader-div">
                <div className="register-loader"></div>
                <div className="loader-content">Uploading..</div>
            </div>
            {!loading && platformInfo !== null && (
                <div className="content">
                    {teamsList && teamsList.length > 0 && (
                        <div className="tabs-header-wrap">
                            <div className="team-btn-filter-wrap">
                                {isRegistrationOpen && (
                                    <div>
                                        {!isNewReg ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="reg-btn"
                                                onClick={() => createNewTeam()}
                                            >
                                                Register new team
                                            </Button>
                                        ) : (
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                className="reg-btn"
                                                onClick={() => exitNewReg()}
                                            >
                                                Exit Registration
                                            </Button>
                                        )}
                                    </div>
                                )}
                                {teamsList && teamsList.length >= 2 && !isNewReg && (
                                    <FormControl
                                        className="sel-box team-sel-box item"
                                        sx={{ m: 1, width: '100%' }}
                                    >
                                        <InputLabel>Team</InputLabel>
                                        <Select
                                            value={activeTeam}
                                            onChange={(e) => setActiveTeam(e.target.value)}
                                            input={<OutlinedInput label="Team" />}
                                            className="active-sel"
                                        >
                                            {teamsList.map((item, idx) => (
                                                <MenuItem key={idx} value={item?.id}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="tabs">
                        {(isReady?.details || isRegistrationOpen) && (
                            <div
                                onClick={() => handleTabChange('details')}
                                className={`tab ${tab === 'details' ? 'active' : 'inactive'}`}
                            >
                                Team
                                <span>Details</span>
                            </div>
                        )}
                        {isReady?.details && (!isRegistrationOpen || ideationChallengeOpen) && (
                            <div
                                className={`tab ${tab === 'ideation' ? 'active' : 'inactive'}`}
                                onClick={() => handleTabChange('ideation')}
                            >
                                Ideation
                                <span>Phase</span>
                            </div>
                        )}
                        {isReady?.ideation && showHackTab && (
                            <div
                                onClick={() => handleTabChange('hack')}
                                className={`tab ${tab === 'hack' ? 'active' : 'inactive'}`}
                            >
                                Hack
                                <span>Phase</span>
                            </div>
                        )}
                    </div>
                    <div>
                        {tab === 'details' && (
                            <div id="tab-1" className="tab-content">
                                {isReady?.details || isRegistrationOpen ? (
                                    <>
                                        <form className="add-team-details">
                                            <OutlinedInput
                                                fullWidth
                                                // readOnly={
                                                //     isReady.details
                                                //         ? !allowTeamDetailUpdate || !isManager
                                                //         : false
                                                // }
                                                readOnly={teamForm.id ? true : false}
                                                onChange={(e) =>
                                                    setteamForm((state) => ({
                                                        ...state,
                                                        teamName: e.target.value,
                                                    }))
                                                }
                                                value={teamForm.teamName}
                                                type="text"
                                                name="teamName"
                                                placeholder="Team Name"
                                                required
                                                className="item"
                                            />
                                            {!teamForm.id && (
                                                <FormControl
                                                    className="sel-box item"
                                                    sx={{ m: 1, width: '100%' }}
                                                >
                                                    <InputLabel>Your Account</InputLabel>
                                                    <Select
                                                        value={teamForm.managerAccount}
                                                        onChange={(e) =>
                                                            setteamForm((state) => ({
                                                                ...state,
                                                                managerAccount: e.target.value,
                                                            }))
                                                        }
                                                        input={
                                                            <OutlinedInput label="Your Account" />
                                                        }
                                                    >
                                                        {accountsList &&
                                                            accountsList.length > 0 &&
                                                            accountsList.map((item, idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={item?.id}
                                                                >
                                                                    {item?.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            {!teamForm.id && (
                                                <>
                                                    <Typography
                                                        className="disclaimer-text"
                                                        variant="subtitle2"
                                                    >
                                                        <span style={{ color: '#22bcac' }}>
                                                            Disclaimer:
                                                        </span>{' '}
                                                        All ideas / solutions / IPs generated from
                                                        the Tribathon will solely be owned by Retail
                                                        Singularity.
                                                    </Typography>
                                                    <FormControlLabel
                                                        required
                                                        control={
                                                            <Checkbox
                                                                checked={teamForm.ipSigned}
                                                                color="primary"
                                                                onChange={(e) =>
                                                                    setteamForm((state) => ({
                                                                        ...state,
                                                                        ipSigned: e.target.checked,
                                                                    }))
                                                                }
                                                            />
                                                        }
                                                        label="I read and understood the disclaimer."
                                                        className="ip-signer-check"
                                                    />
                                                </>
                                            )}
                                            <span className="error-span">{error}</span>
                                        </form>
                                        {/* {isReady.details && allowTeamDetailUpdate && isManager && (
                                            <button
                                                className="btn btn-black"
                                                style={{
                                                    alignSelf: 'end',
                                                }}
                                                onClick={updateTeam}
                                            >
                                                Update
                                            </button>
                                        )} */}
                                        {isReady?.details &&
                                            allowTeamDetailUpdate &&
                                            !isManager && (
                                                <p className="update-warn">
                                                    Only Team Manager can update !
                                                </p>
                                            )}

                                        <div className="add-members">
                                            <div className="team-members">
                                                {allowTeamMemberUpdate && (
                                                    <span
                                                        style={{
                                                            color: 'orange',
                                                            fontSize: '16px',
                                                            margin: '30px 0px',
                                                        }}
                                                    >
                                                        Teams must consist of at least 1 participant
                                                        and a maximum of 5 participants.
                                                    </span>
                                                )}
                                                <div className="list-members">
                                                    {memberList?.map((item, idx) => (
                                                        <div key={idx} className="member">
                                                            <div className="member-remove-btn">
                                                                {allowTeamMemberUpdate &&
                                                                    isManager &&
                                                                    !(
                                                                        isReady?.details &&
                                                                        item?.manager
                                                                    ) && (
                                                                        <IconButton
                                                                            size="small"
                                                                            className="manager-div"
                                                                            href="#"
                                                                            title="Remove Member"
                                                                            onClick={() =>
                                                                                removeMember(
                                                                                    item,
                                                                                    idx
                                                                                )
                                                                            }
                                                                        >
                                                                            <Close fontSize="small" />
                                                                        </IconButton>
                                                                    )}
                                                            </div>
                                                            <div className="img">
                                                                {item?.manager ? (
                                                                    <Badge
                                                                        className="manager-badge"
                                                                        badgeContent={
                                                                            <Typography
                                                                                variant="caption"
                                                                                title="Team Manager"
                                                                            >
                                                                                M
                                                                            </Typography>
                                                                        }
                                                                        color="primary"
                                                                    >
                                                                        <img
                                                                            src={
                                                                                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                                                                            }
                                                                            alt="memberimg"
                                                                            //      th:if="${member.image}"
                                                                            //     th:src="@{${member.image}}"
                                                                        />
                                                                    </Badge>
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                                                                        }
                                                                        alt="memberimg"
                                                                        //      th:if="${member.image}"
                                                                        //     th:src="@{${member.image}}"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div
                                                                className="name"
                                                                // th:text="${member.name}"
                                                            >
                                                                <Typography
                                                                    noWrap
                                                                    variant="subtitle2"
                                                                    style={{
                                                                        fontSize: '0.775rem',
                                                                    }}
                                                                >
                                                                    {item?.name || '--'}
                                                                </Typography>
                                                            </div>
                                                            <div
                                                                className="email" //th:text="${member.email}"
                                                            >
                                                                <Typography
                                                                    noWrap
                                                                    className="member-email"
                                                                    title={`Email: ${
                                                                        item?.email || '--'
                                                                    }`}
                                                                    variant="caption"
                                                                >
                                                                    {item?.email || '--'}
                                                                </Typography>

                                                                <Typography
                                                                    noWrap
                                                                    className="chip team-member"
                                                                    title={`Account: ${
                                                                        item?.account?.name || '--'
                                                                    }`}
                                                                >
                                                                    {item?.account?.name || '--'}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {isReady?.details &&
                                                        memberList?.length < 5 &&
                                                        isManager &&
                                                        allowTeamMemberUpdate && (
                                                            <div className="member">
                                                                <IconButton
                                                                    title="Add member"
                                                                    className="add-new-member"
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        setopenform(true)
                                                                    }
                                                                    size="large"
                                                                >
                                                                    <>
                                                                        <PersonAdd
                                                                            color="primary"
                                                                            fontSize="large"
                                                                        />
                                                                        <p className="add-text">
                                                                            Add Member
                                                                        </p>
                                                                    </>
                                                                </IconButton>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'end', width: '500px' }}>
                                            {!isReady?.details ? (
                                                <button
                                                    className="btn btn-black"
                                                    onClick={registerTeam}
                                                >
                                                    Register
                                                </button>
                                            ) : null}
                                        </div>
                                    </>
                                ) : (
                                    <p className="reg-closed">Registration is closed !</p>
                                )}
                            </div>
                        )}
                        {tab === 'ideation' &&
                            isReady?.details &&
                            (!isRegistrationOpen || ideationChallengeOpen) && (
                                <IdeaListingPage
                                    onAddIdeaClick={handleAddIdeaClick}
                                    ideasList={ideasList}
                                    setIdeaDetail={setIdeaDetail}
                                    ideationChallengeOpen={ideationChallengeOpen}
                                    isManager={isManager}
                                    setIsUpdate={setIsUpdate}
                                    setIdeaStatus={setActiveIdeaStatus}
                                />
                            )}

                        {tab === 'hack' && isReady.ideation && showHackTab && (
                            <HackPhase
                                submitHack={submitHack}
                                hacks={selectedHackTypeFinal}
                                activeTeam={activeTeam}
                                edition={currentEdition}
                            />
                        )}
                    </div>
                </div>
            )}
            <ConfirmPopup
                message="Ideas once submitted can be updated till the closure of the idea submission phase"
                open={openideationconfim}
                handleClose={() => {
                    setIdeaDetail(newIdeaRequest);
                    setRetrieveData(true);
                    setideationconfirm(false);
                    setOpenAddIdeaPopup(true);
                }}
                handleSubmit={isUpdate ? updateIdea : postIdea}
            />
            <ConfirmPopup
                message="You are about to submit hack "
                open={openhackconfim}
                handleClose={sethackconfirm}
                handleSubmit={finalSubmitHack}
            />
            {memberList?.length < 5 && (
                <AddmemberPopup
                    addMember={addMember}
                    open={openform}
                    accountsList={accountsList}
                    handleClose={() => setopenform(false)}
                />
            )}
            {openAddIdeaPopup && (
                <IdeaSubmissionPopup
                    open={openAddIdeaPopup}
                    onClose={() => setOpenAddIdeaPopup(false)}
                    accountsList={accountsList}
                    hackCategories={hackCategories}
                    submitIdeation={handleSubmit}
                    ideaDetail={ideaDetail}
                    isIdeaEditable={isIdeaEditable}
                    activeTeam={activeTeam}
                    edition={currentEdition}
                    ideationChallengeOpen={ideationChallengeOpen}
                    isManager={isManager}
                    teamName={teamForm.teamName}
                    retrieveData={retrieveData}
                    ideaStatus={activeIdeaStatus}
                    showIdeaStatus={ideationChallengeResultAnnounced}
                />
            )}
        </Main>
    );
};

export default Register;
