export const setAccessToken = (accessToken) => {
    localStorage.setItem('__ACCESS_TOKEN', accessToken);
};
export const getRefreshToken = () => {
    return localStorage.getItem('__REFRESH_TOKEN');
};
export const setRefreshToken = (refreshToken) => {
    localStorage.setItem('__REFRESH_TOKEN', refreshToken);
};
export const getIdToken = () => {
    return localStorage.getItem('__ID_TOKEN');
};
export const setIdToken = (idToken) => {
    localStorage.setItem('__ID_TOKEN', idToken);
};
export const getApiToken = () => {
    return localStorage.getItem('__ACCESS_TOKEN');
};
export const setToken = (accessToken, refreshToken, idToken) => {
    accessToken && localStorage.setItem('__ACCESS_TOKEN', accessToken);
    refreshToken && localStorage.setItem('__REFRESH_TOKEN', refreshToken);
    idToken && localStorage.setItem('__ID_TOKEN', idToken);
};

export const clearAuthTokens = () => {
    localStorage.setItem('__ACCESS_TOKEN', '');
    localStorage.setItem('__REFRESH_TOKEN', '');
    localStorage.setItem('__ID_TOKEN', '');
};
export const deepEqual = (x, y) => {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y;
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y;
};
