import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { Button, styled, Menu, Avatar, Tooltip } from '@mui/material';
import { themeColors } from 'app/util/Constants';
import useAppContext from 'app/util/useAppContext';

const Main = styled(Box)`
    .MuiAvatar-colorDefault {
        background-color: ${themeColors.primary};
    }
    .avatar {
        width: 32px;
        height: 32px;
    }
`;
const MenuBox = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 0.3rem 0.6rem;
    * {
        margin: 0px;
    }
    p {
        text-align: center;
        text-transform: capitalize;
    }
    .email {
        text-transform: lowercase;
        padding: 0.5rem 0px;
        //color: black;
        //font-weight: 600;
        font-size: 14px;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.01rem;
    }
    .name {
        font-size: 16px;
        font-weight: 600;
    }
`;
const Profile = ({ handleLogout = () => {} }) => {
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const { store } = useAppContext();

    return (
        <Main className="linkbutton" sx={{ flexGrow: 0 }}>
            <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                        className="avatar"
                        alt={store?.platformInfo?.user?.username}
                        src={store?.platformInfo?.user?.avatar}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuBox>
                    <p className="name">{store?.platformInfo?.user?.name || '--'}</p>
                    <p className="email">{store?.platformInfo?.user?.email || '--'}</p>
                    <Box width="100%" display="grid" justifyContent="center">
                        <Button onClick={handleLogout}>Logout</Button>
                    </Box>
                </MenuBox>
            </Menu>
        </Main>
    );
};
export default Profile;
