import { styled } from '@mui/material';
import { checkImg, docwImg, imageImg, restoreImg, videowImg } from 'app/util/images/images';
import React from 'react';
import { Link } from 'react-router-dom';
import HeaderBanner from '../Common/HeaderBanner/HeaderBanner';

const Main = styled('div')`
    /* display: flex;
    flex-direction: column; */

    height: 100%;
    width: 100%;
    .links {
        display: flex;
        padding-left: 60px;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        padding: 30px 0;
    }

    .links .link {
        margin: 20px;
        font-weight: bold;
        color: #434343;
    }

    .links .link a {
        text-decoration: none;
        color: #343434;
    }

    .links .link-edition {
        margin: 20px;
        font-weight: bold;
        color: rgb(85, 213, 197);
    }

    .boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .boxes .box {
        display: flex;
        flex-direction: column;
        margin: 20px;
        width: 250px;
        min-height: 300px;
        border: 1px solid #f0f0f0;
        box-shadow: 0 0 30px #e5e5e5;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }

    .boxes .box .count {
        font-size: 80px;
        color: #505050;
        font-weight: bold;
    }

    .boxes .box .label {
        font-size: 16px;
        margin: 10px;
    }

    .boxes .box .splits {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .boxes .box .splits .split {
        display: flex;
        padding: 5px 15px;
        border-radius: 10px;
        justify-content: space-between;
        background: #ccc;
        margin: 5px;
    }

    .boxes .box .splits .split .val {
        margin-left: 20px;
        font-weight: bold;
    }

    h3 {
        text-align: center;
    }
    .hacklist {
        display: flex;
        justify-content: center;
    }

    .hacklist table {
        margin: 40px 0;
        margin-top: 10px;
    }

    .hacklist table tr {
        padding: 10px 30px;
        text-align: left;
        font-size: 10px;
        margin: 30px 0;
    }

    .hacklist table thead tr,
    .hacklist table tbody tr:nth-child(even) {
        background: #ddd;
    }
    .hacklist table tr td,
    .hacklist table tr th {
        padding: 10px;
        border-bottom: 1px solid #eee;
    }

    .hacklist table tr td {
        word-wrap: break-word;
        max-width: 300px;
    }

    .hacklist .prizes {
        display: flex;
        flex-wrap: wrap;
        max-width: 200px;
    }

    .hacklist .prizes .prize {
        display: flex;
        border-radius: 5px;
        background: #7b7b7b;
        color: white;
        padding: 3px 3px;
        margin: 2px;
        text-align: center;
    }

    .hacklist .hack-submitted img {
        display: block;
        margin: auto;
    }

    .hacklist .revoke-hack {
        cursor: pointer;
    }

    .hacklist .revoke-hack img {
        display: block;
        margin: auto;
    }

    .hacklist .revoke-idea {
        cursor: pointer;
    }

    .hacklist .revoke-idea img {
        display: block;
        margin: auto;
    }

    .highlight:hover {
        background-color: #fffdf2;
    }

    .view-hack-btn {
        color: white;
        padding: 5px 10px;
        background: #343434;
        border: none;
        box-shadow: 0 0 20px #cccc;
        cursor: pointer;
    }

    .links .dropdown {
        text-decoration: none;
        color: #343434;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }

    .links .dropdown-content {
        text-decoration: none;
        color: #343434;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        border: none;
    }

    .phase {
        font-size: 35px;
        color: #5cbca9;
        text-align: center;
    }
`;
function AdminStats() {
    document.title = 'Stats - Tribathon';
    return (
        <Main>
            <HeaderBanner title="Administration" />
            <div class="links">
                <div
                    class="link" //th:if="${isPanelMember || isAdmin}"
                >
                    <Link to="/admin/settings">Settings</Link>
                </div>
                <div
                    class="link" //th:if="${isPanelMember || isAdmin}"
                >
                    <Link to="/admin/hacks">All Hacks</Link>
                </div>

                <select class="dropdown" name="edition" id="edition-filter">
                    <option
                        class="dropdown-content" //th:each="edition : ${editions}"
                        //  th:value="${edition.id}" th:selected="${userEdition!=null && edition.id == userEdition.editionId}"
                        // th:text="${edition.edition}"
                    ></option>
                </select>
            </div>

            <div class="boxes">
                <div class="box">
                    <div
                        class="count" // th:inline="text"
                    >
                        {/* [[${stats.totalTeams}]] */}
                    </div>
                    <div class="label">Teams</div>
                    <div class="splits">
                        <div
                            class="split" //th:each="locTeam : ${stats.teamCountByLocations}"
                        >
                            <div
                                class="name" // th:inline="text"
                            >
                                {/* [[${locTeam.location.value}]] */}
                            </div>
                            <div
                                class="val" //th:inline="text"
                            >
                                {/* [[${locTeam.count}]] */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div
                        class="count" //th:inline="text"
                    >
                        {/* [[${stats.totalMembers}]] */}
                    </div>
                    <div class="label">Members</div>
                    <div class="splits">
                        <div
                            class="split" // th:each="locTeam : ${stats.memberCountByLocations}"
                        >
                            <div
                                class="name" //th:inline="text"
                            >
                                {/* [[${locTeam.location.value}]] */}
                            </div>
                            <div
                                class="val" // th:inline="text"
                            >
                                {/* [[${locTeam.count}]] */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div
                        class="count" //th:inline="text"
                    >
                        {/* [[${stats.totalHackSubmission}]] */}
                    </div>
                    <div class="label">Team Submitted Hack</div>
                    <div class="splits">
                        <div
                            class="split" //th:each="locTeam : ${stats.hackSubmissionCountByLocations}"
                        >
                            <div
                                class="name" //th:inline="text"
                            >
                                {/* [[${locTeam.location.value}]] */}
                            </div>
                            <div
                                class="val" //th:inline="text"
                            >
                                {/* [[${locTeam.count}]] */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div
                        class="count phase" // th:inline="text"
                    >
                        {/* [[${stats.phase}]] */}
                    </div>
                    <div class="label">Phase</div>
                    <div class="splits">
                        <div class="split">
                            <div class="name" style={{ cursor: 'pointer' }}>
                                <Link style={{ textDecoration: 'none' }} to="/admin/settings">
                                    Change
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3> Team Details</h3>
            <div class="hacklist">
                <table>
                    <thead>
                        <tr>
                            <th>Team Name</th>
                            <th>Short Description</th>
                            <th>Location</th>
                            <th>Members</th>
                            <th>Submitted Hacks</th>
                            <th>Git Url</th>
                            <th>Votes</th>
                            <th>Prizes</th>
                            <th>Hack Submitted</th>
                            <th>ReInvoke Submission</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr //th:each="hack : ${hacks}"
                            class="highlight"
                        >
                            <td //th:inline="text" th:onclick="${'javascript:goToHackDetail(' + hack.teamId + ')'}"
                                style={{ cursor: 'pointer' }}
                            >
                                {/* [[${hack.teamName}]] */}
                            </td>
                            <td // th:inline="text"
                            >
                                {/* [[${hack.shortDescription}]] */}
                            </td>
                            <td //th:inline="text"
                            >
                                {/* [[${hack.locationName}]] */}
                            </td>
                            <td>
                                <div // th:inline="text" th:each="member : ${hack.teamMembers}"
                                >
                                    {/* [[${member.name}]] */}
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div
                                        title="Video" //th:if="${hack.videoUrl!=null}"
                                    >
                                        <img src={videowImg} alt="videow" />
                                    </div>
                                    <div
                                        title="Image"
                                        //th:if="${hack.imageUrl!=null}"
                                    >
                                        <img src={imageImg} alt="imagew" />
                                    </div>
                                    <div
                                        title="Document(PPT)"
                                        // th:if="${hack.presentationUrl!=null}"
                                    >
                                        <img src={docwImg} alt="docw" />
                                    </div>
                                </div>
                            </td>
                            <td // th:inline="text"
                            >
                                {/* [[${hack.gitUrl}]] */}
                            </td>
                            <td // th:inline="text"
                            >
                                {/* [[${hack.voteCount}]] */}
                            </td>
                            <td>
                                <div class="prizes">
                                    <div
                                        class="prize" // th:inline="text" th:each="prize : ${hack.prizes}"
                                    >
                                        {/* [[${prize}]] */}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="hack-submitted">
                                    <img
                                        class="submit-flag" //th:if="${hack.isFinished}"
                                        alt="check"
                                        src={checkImg}
                                    />
                                </div>
                            </td>
                            <td>
                                <div
                                    class="revoke-hack"
                                    title="Hack submitted. Click here to reopen hack submission for the team."
                                >
                                    <img // th:if="${hack.isFinished}"
                                        src={restoreImg}
                                        //th:onclick="${'javascript:revokeSubmission(' + hack.teamId + ')'}"

                                        alt="restore"
                                    />
                                </div>
                            </td>
                            <td>
                                <button
                                    class="view-hack-btn"
                                    //  th:onclick="${'javascript:goToHackDetail(' + hack.teamId + ')'}"
                                >
                                    View Hack
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Main>
    );
}

export default AdminStats;
