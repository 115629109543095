import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const CustomSlider = styled(Slider)`
    // height: 100%;

    width: 75vw;
    .slick-list {
        border: 2px solid lightgrey;
        margin-bottom: 1rem;
        height: fit-content;
    }
    .slick-track {
        height: inherit;
    }
    .slick-slide > div {
        height: inherit;
    }
    .flex {
        height: inherit;
        display: flex !important;
        gap: 1rem;
        width: auto !important;
        margin: 0px auto !important;
        align-items: center;
        * {
            margin: 0px;
        }
        .name {
            margin: 1rem 0px;
        }
        .desc {
            font-size: 14px;
            padding: 0px 30px 0px 10px;
            text-align: justify;
        }
    }
    .member {
        display: flex !important;
        flex-direction: row !important;
        margin-top: 5px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        .name {
            padding: 5px;
            color: #434343;
            font-weight: 500;
            margin-right: 5px;
            border-radius: 3px;
            width: 100%;
            text-align: center;
            font-weight: bold;
            border: 1px solid #f0f0f0;
            margin: 2px 0;
            float: left;
        }
        .member-name {
            width: fit-content;
            white-space: nowrap;
        }
    }
    .img,
    .team {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .img {
        flex: 4;
    }
    .team {
        flex: 6;
    }
    .img {
        align-items: center;
        display: flex;
        justify-content: center;
        img {
            width: 90%;
        }
    }
`;

const WinnerCarousal = ({ teamList = [] }) => {
    return (
        <CustomSlider autoplay dots infinite autoplaySpeed={1000}>
            {teamList?.map((team, ix) => (
                <div className="flex" key={ix}>
                    <div
                        className="img"
                        //      th:if="${isPanelMember}"
                    >
                        <img alt="hackImg" src={team?.hack?.imageUrl} />
                    </div>
                    <div className="team">
                        <h2 className="name">{team?.name}</h2>
                        <p className="desc">{team?.hack?.longDescription}</p>
                        <div className="member">
                            {team?.idea?.members?.map((member, ix) => (
                                <p key={ix} className="name member-name">
                                    {member.name}
                                </p>
                            ))}
                        </div>
                        <Link
                            to={`/hacks/${team?.id}`}
                            href="google.com"
                            // th:onclick="${'javascript:goToHackDetail(' + hackWinner.teamId + ')'}"
                        >
                            View Hack
                        </Link>
                    </div>
                </div>
            ))}
        </CustomSlider>
    );
};

export default WinnerCarousal;
